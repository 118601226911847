import React, { Fragment } from "react";
import classnames from "classnames";
import moment from "moment";
import { compose, map, keys } from "ramda";
import { RouteComponentProps } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./styles.css";
import { getBrandDescription } from "../../../domain/extinguisher/brand/selectors";
import { getCapacityDescription } from "../../../domain/extinguisher/capacity/selectors";
import { getTypeDescription } from "../../../domain/extinguisher/type/selectors";
import Loader from "../../../components/shared/loader";
import { ExtinguishersList } from "../../../domain/extinguisher/extinguisher";
import { ExpandPanel } from "../../../components/shared/expand-panel";

import { ExtinguisherBrandsState } from "../../../domain/extinguisher/brand/reducer";
import { ExtinguisherCapacitiesState } from "../../../domain/extinguisher/capacity/reducer";
import { ExtinguisherTypesState } from "../../../domain/extinguisher/type/reducer";

import { printCapacity, printBrand } from "./utils";
import { LongMenu } from "../../../components/shared/menu-max-height";
import { ClientState } from "../../../domain/client/reducer/client";
import { Alert } from "@mui/material";
import { AlertDialogWrapper } from "../../../components/shared/alert-dialog-wrapper";
import { unlinkExtinguisher } from "../../../domain/client/actions/link-unlink";

interface ClientExtinguishersType {
  extinguishers: {
    list: ExtinguishersList;
    loading: boolean;
  };
  extinguisherTypesState: ExtinguisherTypesState;
  extinguisherCapacities: ExtinguisherCapacitiesState;
  extinguisherBrands: ExtinguisherBrandsState;
  clientState: ClientState;
  history: RouteComponentProps["history"];
}

export const ClientExtinguishers: React.FunctionComponent<ClientExtinguishersType> = ({
  extinguishers,
  extinguisherTypesState,
  extinguisherCapacities,
  extinguisherBrands,
  history,
  clientState,
}) => {
  const renderManufactureDateCell = (ext: extinguisher.UIModel) => {
    if (ext.manufactureDate) {
      const { month, year, isExpired } = ext.manufactureDate;
      return (
        <Fragment>
          {/* {isExpired && <FontAwesomeIcon icon="exclamation-triangle" />} */}
          {`${month}/${String(year).substring(2, 4)}`}
        </Fragment>
      );
    } else {
      return <FontAwesomeIcon icon="question" />;
    }
  };
  const renderExpireDateCell = (ext: extinguisher.UIModel) => {
    if (ext.expireDate) {
      const { date, isExpired } = ext.expireDate;
      return (
        <Fragment>
          {/* {isExpired && <FontAwesomeIcon icon="exclamation-circle" />} */}
          {moment(date).format("DD/MM/YY")}
        </Fragment>
      );
    } else {
      return <FontAwesomeIcon icon="question" />;
    }
  };

  const hasExtinguishers = Object.keys(extinguishers.list.all).length > 0;
  const extTypes = compose(map(parseInt), keys)(extinguishers.list.byType);

  return (
    <Fragment>
      <ExpandPanel title={"Extintores"}>
        {extinguishers.loading && <Loader />}
        {!extinguishers.loading &&
          hasExtinguishers &&
          extTypes.map((type, idx) => (
            <Fragment key={`type_${type}-${idx}`}>
              <h5 className="text-center text-uppercase">
                {getTypeDescription(type, extinguisherTypesState)}
                &nbsp; ({Object.keys(extinguishers.list.byType[type]).length})
              </h5>
              <div className="table-responsive mb-4">
                <table className="table table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col" className="align-left" />
                      <th scope="col" className="align-middle">
                        SERIE
                      </th>
                      <th scope="col" className="align-middle">
                        CAP
                      </th>
                      <th scope="col" className="align-middle">
                        VENC
                      </th>
                      <th scope="col" className="align-middle">
                        FAB
                      </th>
                      <th scope="col" className="align-middle">
                        MARCA
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hasExtinguishers &&
                      extinguishers.list.byType[type].map(
                        (extinguisherID, index) => {
                          const extinguisher =
                            extinguishers.list.all[extinguisherID];
                          const manufactureDateClasses = classnames(
                            "manufactureDate",
                            {
                              "table-danger":
                                extinguisher.status === 1 &&
                                extinguisher.manufactureDate &&
                                extinguisher.manufactureDate.isExpired
                            }
                          );
                          const expireDateClasses = classnames("expireDate", {
                            "table-warning":
                              extinguisher.status === 1 &&
                              extinguisher.expireDate.isExpired
                          });
                          const extinguisherRowClasses = classnames({
                            inactive: extinguisher.status === 2
                          });

                          const openEditScreen = () => history.push(`/extinguisher/edit/${extinguisher.serialNumber}`, { clientId: extinguisher.clientId })

                          return (
                            <tr
                              key={`${type}-${index}`}
                              className={extinguisherRowClasses}
                            >
                              <td style={{ padding: 0, borderTop: 0 }}>
                                <LongMenu options={[
                                  { label: 'Editar', onClick: openEditScreen },
                                  {
                                    label: 'Desasociar',
                                    dialogAlertTitle: 'Desasociar Matafuego',
                                    dialogAlertMessage: `Confirma desasociar matafuego serie: ${extinguisher.serialNumber}?`,
                                    renderDialogAlert: ({ label, title, message }) => {
                                      return (<AlertDialogWrapper title={title} message={message} label={label}
                                        actionFunction={() => unlinkExtinguisher({ serialNumber: String(extinguisher.serialNumber) })(clientState.dispatch)}
                                      />)
                                    }

                                  }
                                ]} />
                              </td>
                              <th scope="row">{extinguisher.serialNumber}</th>
                              <td>
                                {printCapacity(
                                  getCapacityDescription(
                                    extinguisher.capacity,
                                    extinguisherCapacities
                                  )
                                )}
                              </td>
                              <td className={expireDateClasses}>
                                {renderExpireDateCell(extinguisher)}
                              </td>
                              <td className={manufactureDateClasses}>
                                {renderManufactureDateCell(extinguisher)}
                              </td>
                              <td>
                                {printBrand(
                                  getBrandDescription(
                                    extinguisher.brand,
                                    extinguisherBrands
                                  )
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    {!hasExtinguishers && (
                      <tr>
                        <td colSpan={6}>
                          <Alert severity="info">No posee matafuegos asociados.</Alert>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Fragment>
          ))}
        {!extinguishers.loading && !hasExtinguishers && (
          <Alert severity="info">No hay extintores asociados!</Alert>
        )}
      </ExpandPanel>
    </Fragment>
  );
};
