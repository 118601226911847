export const printBrand = (brand: string) =>
  brand.length > 5 ? brand.substr(0, 5) + "..." : brand;
export const printCapacity = (capacity: string) => {
  const tokens = capacity.split(" ");
  const number = Number(tokens[0].replace(",", "."));
  return `${new Intl.NumberFormat("es-AR").format(number)}${tokens[1].substring(
    0,
    1
  )}`;
};
