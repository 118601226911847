import { ProductActionType, ProductAction } from "./types";
import { doCall } from "../../../utils/apiCall";

const productsLoadSuccess = (
  data: backend.productNS.LoadAllResponseSuccess
): ProductAction => ({
  type: ProductActionType.LOAD_SUCCESS,
  payload: data
});
const productsLoadFailure = (
  error: backend.common.ErrorResponse
): ProductAction => ({
  type: ProductActionType.LOAD_FAILURE,
  payload: error
});
const productsLoadInit = (): ProductAction => ({
  type: ProductActionType.LOAD_INIT
});

export const loadProducts = () => (dispatch: React.Dispatch<any>) => {
  dispatch(productsLoadInit());
  return doCall({
    url: "/api/product"
  })
    .then((data) => {
      dispatch(
        productsLoadSuccess(data as backend.productNS.LoadAllResponseSuccess)
      );
    })
    .catch((error: backend.common.ErrorResponse) => {
      dispatch(productsLoadFailure(error));
    });
};
