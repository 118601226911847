import { where, filter, curry, anyPass, compose } from "ramda";
import { ClientState } from "./client";
import { FilterClientRequest } from "../actions/index.d";

const getAddress1Object = (
  clientModel: backend.client.GetClientDetailsResponseSuccess
): backend.common.Address =>
  clientModel.address1;

export const filterClients = (
  request: FilterClientRequest,
  clients: ClientState["searchResults"]
) => {
  const containsInsensitive = curry(
    (a, b) =>
      typeof a !== "undefined" &&
      typeof b !== "undefined" &&
      String(b).toLowerCase().indexOf(String(a).toLowerCase()) > -1
  );
  const matchNameField = where({
    name: containsInsensitive(request.text)
  });
  const matchFantasyNameField = where({
    fantasyName: containsInsensitive(request.text)
  });
  const matchContactField = where({
    contact: containsInsensitive(request.text)
  });
  const _matchStreetField = where<object>({
    street: containsInsensitive(request.text)
  });
  const matchStreetField = (aClient: any): boolean =>
    compose(_matchStreetField, getAddress1Object)(aClient);

  const allConditions = anyPass([
    matchNameField,
    matchFantasyNameField,
    matchContactField,
    matchStreetField
  ]);

  return filter(allConditions, clients);
};
