export enum ExtinguisherTypeActionType {
  LOAD_INIT = "@extinguisher-type/LOAD_INIT",
  LOAD_SUCCESS = "@extinguisher-type/LOAD_SUCCESS",
  LOAD_FAILURE = "@extinguisher-type/LOAD_FAILURE"
}

export type ExtinguisherTypeAction =
  | { type: ExtinguisherTypeActionType.LOAD_INIT }
  | {
      type: ExtinguisherTypeActionType.LOAD_SUCCESS;
      payload: backend.extTypeNS.LoadAllResponseSuccess;
    }
  | { type: ExtinguisherTypeActionType.LOAD_FAILURE };
