import { ClientActionType, ClientAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import { getClientDetails } from "./getDetails";
import { editClientActionResponse } from ".";

const editClientSuccess = (): ClientAction => ({
    type: ClientActionType.EDIT_SUCCESS
});
const editClientFailure = (
    error: backend.quote.QuoteResponseError
): ClientAction => ({
    type: ClientActionType.EDIT_FAILURE,
    payload: error
});
const editClientInit = (): ClientAction => ({
    type: ClientActionType.EDIT_INIT
});

export type IEditRequest = backend.client.EditClientRequest & {
    id: string;
}
export const editClient = ({ id, ...rest }: IEditRequest) => (
    dispatch: React.Dispatch<any>
): editClientActionResponse => {
    dispatch(editClientInit());
    return doCall({
        url: `/api/client/${id}`,
        method: "PUT",
        payload: removeAllEmptyFields(rest)
    })
        .then((_) => {
            dispatch(editClientSuccess());
            getClientDetails({ id: Number(id) })(dispatch);
            return Promise.resolve(Number(id));
        })
        .catch((error: backend.quote.QuoteResponseError) => {
            dispatch(editClientFailure(error));
            return Promise.reject(error);
        });
};

const removeAllEmptyFields = (request: object) => Object.fromEntries(Object.entries(request).filter(([_, v]) => String(v).length > 0))