//src/administrator/controller/administrator.schema.ts
import * as Yup from "yup";
import { saveActionRequest } from "../../../domain/administrator/actions/index.d";
import {
  VALIDATION_MSG_EMAIL, VALIDATION_MSG_INTEGER, VALIDATION_MSG_NUMBER, VALIDATION_MSG_POSITIVE, VALIDATION_MSG_REQUIRED, VALIDATION_MSG_WEB,
  FIELD_LENGTH_NAME,
  FIELD_LENGTH_COMMENTS, FIELD_LENGTH_CONTACT,
  FIELD_LENGTH_EMAIL, FIELD_LENGTH_WEB,
  FIELD_LENGTH_PHONE, FIELD_LENGTH_PHONE_AREA,
  FIELD_UNIT_LENGTH, FIELD_FLOOR_LENGTH, FIELD_LENGTH_STREET, FIELD_LENGTH_DOOR, FIELD_LENGTH_CITY, FIELD_LENGTH_NEIGHBORHOOD, FIELD_LENGTH_ZIP, VALIDATION_REGEX_NUMBER, FIELD_LENGTH_STATE
} from "../../../utils/form.utils";

export const createFormSchema = Yup.object().shape({
  name: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_NAME),

  iva_id: Yup.number()
    .typeError(VALIDATION_MSG_NUMBER)
    .required(VALIDATION_MSG_REQUIRED)
    .integer(VALIDATION_MSG_INTEGER)
    .positive(VALIDATION_MSG_POSITIVE),

  document_id: Yup.number()
    .typeError(VALIDATION_MSG_NUMBER)
    .required(VALIDATION_MSG_REQUIRED)
    .integer(VALIDATION_MSG_INTEGER)
    .positive(VALIDATION_MSG_POSITIVE),

  document: Yup.number()
    .typeError(VALIDATION_MSG_NUMBER)
    .positive(VALIDATION_MSG_POSITIVE),

  street1_name: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_STREET),

  door1: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_DOOR),

  city1_name: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_CITY),

  state1_name: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_STATE),

  neighborhood1_name: Yup.string()
    .max(FIELD_LENGTH_NEIGHBORHOOD),

  zip1: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_ZIP),

  unit1: Yup.string()
    .max(FIELD_UNIT_LENGTH),

  floor1: Yup.string()
    .max(FIELD_FLOOR_LENGTH),

  lat1: Yup.number()
    .required(VALIDATION_MSG_REQUIRED),

  lng1: Yup.number()
    .required(VALIDATION_MSG_REQUIRED),

  email1: Yup.string()
    .email(VALIDATION_MSG_EMAIL)
    .max(FIELD_LENGTH_EMAIL),

  email2: Yup.string()
    .email(VALIDATION_MSG_EMAIL)
    .max(FIELD_LENGTH_EMAIL),

  web: Yup.string()
    .url(VALIDATION_MSG_WEB)
    .max(FIELD_LENGTH_WEB),

  contact: Yup.string()
    .max(FIELD_LENGTH_CONTACT),

  comments: Yup.string()
    .max(FIELD_LENGTH_COMMENTS),

  tel1_area: Yup.string()
    .max(FIELD_LENGTH_PHONE_AREA)
    .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),

  tel1_num: Yup.string()
    .max(FIELD_LENGTH_PHONE)
    .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),

  tel2_area: Yup.string()
    .max(FIELD_LENGTH_PHONE)
    .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),

  tel2_num: Yup.string()
    .max(FIELD_LENGTH_PHONE)
    .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),
});

export const formInitialValues: saveActionRequest = {
  name: "",

  document: "" as unknown as number,
  document_id: 1,
  iva_id: 1,

  street1_name: "",
  door1: "",
  city1_name: "",
  state1_name: "",
  neighborhood1_name: "",
  zip1: "",
  unit1: "",
  floor1: "",
  lat1: "" as unknown as number,
  lng1: "" as unknown as number,

  email1: "",
  email2: "",
  web: "",

  contact: "",
  comments: "",

  tel1_area: "",
  tel1_num: "",
  tel2_area: "",
  tel2_num: "",
  statusId: 1,
};