import {
  ExtinguisherTypeAction,
  ExtinguisherTypeActionType
} from "../actions/types";

export interface ExtinguisherTypesState {
  all: Record<number, extinguisherType.UIModel>;
  loading: boolean;
  dispatch?: any;
}

export default (
  state: ExtinguisherTypesState,
  action: ExtinguisherTypeAction
) => {
  switch (action.type) {
    case ExtinguisherTypeActionType.LOAD_SUCCESS: {
      const resultsObject = action.payload.reduce(
        (acc: ExtinguisherTypesState["all"], elem) => {
          acc[elem.id] = elem;
          return acc;
        },
        {}
      );
      return {
        ...state,
        all: resultsObject,
        loading: false
      };
    }

    case ExtinguisherTypeActionType.LOAD_INIT:
      return {
        ...state,
        loading: true
      };
    case ExtinguisherTypeActionType.LOAD_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return { ...state };
  }
};
