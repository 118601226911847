import {
  SearchClientsByAdministratorActionType,
  AdministratorAction
} from "./types";
import { searchClientsByAdministratorSetAdmin } from "../../ui/actions/";
import { doCall } from "../../../utils/apiCall";

interface searchClientsByAdministratorLoadSuccessRequest {
  adminId: number;
  clients: administrator.SearchClientsByAdministratorUIModel[];
}
const searchClientsByAdministratorLoadSuccess = ({
  adminId,
  clients
}: searchClientsByAdministratorLoadSuccessRequest): AdministratorAction => ({
  type: SearchClientsByAdministratorActionType.LOAD_SUCCESS,
  payload: { adminId, clients }
});
const searchClientsByAdministratorLoadFailure = (
  error: backend.common.ErrorResponse
): AdministratorAction => ({
  type: SearchClientsByAdministratorActionType.LOAD_FAILURE,
  payload: error
});
const searchClientsByAdministratorLoadInit = (): AdministratorAction => ({
  type: SearchClientsByAdministratorActionType.LOAD_INIT
});
export const searchClientsByAdministrator = ({
  adminId,
  adminName
}: backend.administratorNS.SearchClientsByAdministratorRequestType) => (
  dispatchAdmin: React.Dispatch<any>,
  dispatchUI: React.Dispatch<any>
): backend.administratorNS.SearchClientsByAdministratorResponseType => {
  dispatchAdmin(searchClientsByAdministratorLoadInit());
  dispatchUI(searchClientsByAdministratorSetAdmin(adminId, adminName));
  return doCall({
    url: `/api/administrator/${adminId}/clients`
  })
    .then((data) => {
      dispatchAdmin(
        searchClientsByAdministratorLoadSuccess({
          adminId,
          clients: data as administrator.SearchClientsByAdministratorUIModel[]
        })
      );
    })
    .catch((error: backend.common.ErrorResponse) => {
      dispatchAdmin(searchClientsByAdministratorLoadFailure(error));
    });
};
