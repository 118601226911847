import { ExtinguisherActionType, ExtinguisherAction } from "./types";
import { doCall } from "../../../utils/apiCall";

const deleteSuccess = (serie: string): ExtinguisherAction => ({
    type: ExtinguisherActionType.DELETE_SUCCESS,
    payload: serie
});
const deleteFailure = (error: backend.common.ErrorResponse): ExtinguisherAction => ({
    type: ExtinguisherActionType.DELETE_FAILURE,
    payload: error
});
const deleteInit = (): ExtinguisherAction => ({
    type: ExtinguisherActionType.DELETE_INIT
});

export const remove = (request: backend.extinguisherNS.DeleteExtinguisherRequest) => (dispatch: React.Dispatch<any>) => {
    dispatch(deleteInit());
    return doCall({
        url: `/api/extinguisher/${request.serie}`,
        method: "DELETE"
    })
        .then((_) => {
            dispatch(deleteSuccess(request.serie));
        })
        .catch((error: backend.common.ErrorResponse) => {
            dispatch(deleteFailure(error));
            return Promise.reject(error)
        });
};