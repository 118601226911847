import React, { useContext } from "react";
import { Formik, Field, Form } from "formik";

import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { Stack, Button, MenuItem, Box } from '@mui/material';
import { LoadingButton } from "@mui/lab";

import { save as saveExtinguisher, edit as editExtinguisher } from "../../../domain/extinguisher/actions/save";
import { createFormSchema, formInitialValues } from './create-form-schema';

import { TimePicker } from "../../../components/shared/time-picker";
import { getErrorText, hasError, FormFieldBoxItem } from "../../../components/shared/form-utils";
import { FIELD_LENGTH_MANUFACTURER_MONTH, FIELD_LENGTH_MANUFACTURER_YEAR, FIELD_LENGTH_SERIE, restrictNumberOnly } from "../../../utils/form.utils";
import { AppContext } from "../../../store";

interface ExtinguisherCreateEditForm {
    brandTypes: extinguisherBrand.UIModel[];
    capacityTypes: extinguisherCapacity.UIModel[];
    typeTypes: extinguisherType.UIModel[];
    dispatchFn: Function;
    onSuccess: Function;
    initialValues?: ExtinguisherCreateEditFormValues;
}

export type ExtinguisherCreateEditFormValues = backend.extinguisherNS.EditExtinguisherRequest & { id?: string };
export const ExtinguisherCreateEditForm = ({
    brandTypes,
    capacityTypes,
    typeTypes,
    onSuccess,
    dispatchFn,
    initialValues
}: ExtinguisherCreateEditForm) => {
    const isEditing = typeof initialValues !== "undefined";
    const actionFn = isEditing ? editExtinguisher : saveExtinguisher;
    const _formInitialValues = isEditing ? initialValues : formInitialValues;

    const { status: statusTypes } = useContext(AppContext);

    return (
        <Formik
            initialValues={_formInitialValues}
            validationSchema={createFormSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                //@ts-ignore
                actionFn(values)(dispatchFn).then(_ => onSuccess())
                    .finally(() => setSubmitting(false));
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
                setFieldValue,
                setFieldTouched,
                dirty
                /* and other goodies */
            }) => {
                const formikProps = (name: string): StandardTextFieldProps => ({
                    variant: "standard",
                    name,
                    //@ts-ignore
                    value: values[name],
                    error: hasError(touched, errors, name),
                    helperText: getErrorText(errors, name),
                    onChange: handleChange(name),
                    onBlur: handleBlur(name),
                    autoComplete: "off"
                });
                return (
                    <Form>
                        <FormFieldBoxItem>
                            <TextField
                                type="tel"
                                disabled={isEditing}
                                inputProps={{ maxLength: FIELD_LENGTH_SERIE }}
                                placeholder="Ingrese numero de serie"
                                label="Numero Serie"
                                {...formikProps("serie")}
                                onKeyPress={restrictNumberOnly}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem>
                            <TextField
                                select
                                label="Tipo"
                                {...formikProps("typeId")}
                            >
                                {typeTypes.map((e) => (
                                    <MenuItem key={`type_${e.id}`} value={e.id}>
                                        {e.desc}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem>
                            <TextField
                                select
                                label="Capacidad"
                                {...formikProps("capacityId")}
                            >
                                {capacityTypes.map((e) => (
                                    <MenuItem key={`capacity_${e.id}`} value={e.id}>
                                        {e.desc}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem>
                            <TextField
                                select
                                label="Marca"
                                {...formikProps("brandId")}
                            >
                                {brandTypes.map((e) => (
                                    <MenuItem key={`brand_${e.id}`} value={e.id}>
                                        {e.desc}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem>
                            <Box sx={{ display: 'grid', gridTemplateColumns: '5fr 2fr 5fr' }}>
                                <TextField
                                    type="tel"
                                    inputProps={{ maxLength: FIELD_LENGTH_MANUFACTURER_MONTH }}
                                    label="Mes Fabricacion"
                                    {...formikProps("manufactureMonth")}
                                    onKeyPress={restrictNumberOnly}
                                />
                                <Box sx={{ textAlign: 'center', verticalAlign: 'middle', margin: 'auto 0' }}>-</Box>

                                <TextField
                                    type="tel"
                                    inputProps={{ maxLength: FIELD_LENGTH_MANUFACTURER_YEAR }}
                                    label="Ano Fabricacion"
                                    {...formikProps("manufactureYear")}
                                    onKeyPress={restrictNumberOnly}
                                />
                            </Box>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem>
                            <Field
                                component={TimePicker}
                                label="Fecha Vencimiento"
                                {...formikProps("expirationDate")}
                            />
                        </FormFieldBoxItem>

                        {isEditing && (<FormFieldBoxItem>
                            <TextField
                                select
                                label="Estado"
                                {...formikProps("statusId")}
                            >
                                {Object.entries(statusTypes).map((e) => (
                                    <MenuItem key={`status_${e[0]}`} value={e[0]}>
                                        {e[1]}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>)}

                        <FormFieldBoxItem>
                            <Stack direction="row" spacing={2}>
                                <LoadingButton variant="contained"
                                    loading={isSubmitting}
                                    type="submit"
                                    disabled={isEditing && !dirty}
                                >
                                    {isEditing ? "Modificar" : "Agregar"}
                                </LoadingButton>
                                <Button variant="contained" color="secondary"
                                    onClick={() => resetForm(_formInitialValues)}
                                    type="button"
                                >Reset</Button>
                            </Stack>
                        </FormFieldBoxItem>
                    </Form>
                );
            }}
        </Formik >
    );
};