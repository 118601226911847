import React from 'react';

const styles = {
  width: '5rem',
  height: '5rem'
};
export default () => (
  <div className="d-flex justify-content-center">
    <div className="spinner-grow" style={styles} role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  </div>
);
