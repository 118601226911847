import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Collapse, ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const [openExtintorList, setOpenExtintorList] = useState(false);
    const [openClientList, setOpenClientList] = useState(false);
    const [openAdmitrationList, setOpenAdmitrationList] = useState(false);
    const [openConfigList, setOpenConfigList] = useState(false);
    const [openConfigExtinguishersList, setOpenConfigExtinguishersList] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const MenuItem = ({ text, Icon, url }: IMenuItem) => (
        <Link to={url} onClick={handleDrawerClose} >
            <ListItemButton sx={{
                pl: 4,
                textDecoration: 'none',
                color: 'black',
                // borderBottom: '1px solid grey',
                '&:hover': {
                    textDecoration: 'none',
                },
            }}>
                {/* <ListItemIcon>
                    <Icon />
                </ListItemIcon> */}
                <ListItemText primary={text} />
            </ListItemButton>
        </Link>)

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <Typography variant="h6" noWrap component="div">
                        Menu
                    </Typography> */}
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItemButton onClick={() => {
                        setOpenExtintorList(!openExtintorList);
                        setOpenAdmitrationList(false);
                        setOpenClientList(false);
                        setOpenConfigList(false);
                    }}>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Extintor"} />
                        {openExtintorList ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openExtintorList} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <MenuItem url={"/extinguisher/search"} text="Buscar" Icon={MailIcon} />
                            <MenuItem url={"/extinguisher/create"} text="Registrar" Icon={MailIcon} />
                            <Divider />
                            <MenuItem url={"/client/extinguishers/audit"} text="Vencimiento Auditor&iacute;as" Icon={MailIcon} />
                            <MenuItem url={"/client/extinguishers/service"} text="Vencimiento Recargas" Icon={MailIcon} />
                            <MenuItem url={"/extinguisher/expiration-date-change"} text="Cambio F Vencimiento" Icon={MailIcon} />
                        </List>
                    </Collapse>

                    <ListItemButton onClick={() => {
                        setOpenClientList(!openClientList)
                        setOpenAdmitrationList(false);
                        setOpenExtintorList(false);
                        setOpenConfigList(false);
                    }}>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Cliente"} />
                        {openClientList ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openClientList} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <MenuItem url={"/client/search"} text="Buscar" Icon={MailIcon} />
                            <MenuItem url={"/client/create"} text="Registrar" Icon={MailIcon} />
                        </List>
                    </Collapse>

                    <ListItemButton onClick={() => {
                        setOpenAdmitrationList(!openAdmitrationList)
                        setOpenClientList(false)
                        setOpenExtintorList(false);
                        setOpenConfigList(false);
                    }}>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Administracion"} />
                        {openAdmitrationList ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openAdmitrationList} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <MenuItem url={"/administrator/create"} text="Registrar" Icon={MailIcon} />
                            <Divider />
                            <MenuItem url={"/administrator"} text="Listado" Icon={MailIcon} />
                            <MenuItem url={"/administrator/clients"} text="Listado de Consorcios" Icon={MailIcon} />
                        </List>
                    </Collapse>

                    <ListItemButton onClick={() => {
                        setOpenConfigList(!openConfigList)
                        setOpenAdmitrationList(false)
                        setOpenClientList(false)
                        setOpenExtintorList(false);
                    }}>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Configuracion"} />
                        {openConfigList ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openConfigList} timeout="auto" unmountOnExit>
                        <List component="div" >
                            <ListItemButton onClick={() => {
                                // setOpenConfigList(!openConfigList)
                                // setOpenAdmitrationList(false)
                                // setOpenClientList(false)
                                // setOpenExtintorList(false);
                            }}>
                                {/* <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon> */}
                                <ListItemText primary={"Extintores"} onClick={() => setOpenConfigExtinguishersList(!openConfigExtinguishersList)} />
                                {openConfigExtinguishersList ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openConfigExtinguishersList} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <MenuItem url={"/extinguisher/brand"} text="Marcas" Icon={MailIcon} />
                                    <MenuItem url={"/extinguisher/type"} text="Tipos" Icon={MailIcon} />
                                    <MenuItem url={"/extinguisher/capacity"} text="Capacidades" Icon={MailIcon} />
                                </List>
                            </Collapse>

                            <MenuItem url={"/iva"} text="IVAs" Icon={MailIcon} />
                            <MenuItem url={"/document"} text="Documentos" Icon={MailIcon} />
                            <MenuItem url={"/product"} text="Productos" Icon={MailIcon} />
                            <MenuItem url={"/industry"} text="Rubros" Icon={MailIcon} />
                            <MenuItem url={"/seller"} text="Vendedores" Icon={MailIcon} />
                        </List>
                    </Collapse>

                </List>
            </Drawer>

            <Main open={open}>
                <DrawerHeader />
            </Main>
        </Box >
    );
}

interface IMenuItem {
    text: string;
    url: string;
    Icon: React.FunctionComponent;
}
