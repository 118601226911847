import React from 'react';
import { Box, BoxProps } from '@mui/material';

export const CardBoxContainer: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = ({ children, className }) => (<Box className={`${className || ''} mb-3`} sx={{
    borderRadius: '2px',
    boxShadow: '0 12px 15px 0 rgba(0, 0, 0, 0.24)'
}}>{children}</Box>)

export const GenericBoxItem = (props: BoxProps) => {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                // bgcolor: 'primary.main',
                // color: 'white',
                p: 1,
                m: 1,
                // borderRadius: 1,
                // textAlign: 'center',
                // fontSize: 19,
                // fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}