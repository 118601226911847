import React from "react";

interface IRenderNotification {
    title: string;
    msg: string;
    warn?: boolean
}

export const Notification: React.FunctionComponent<IRenderNotification> = ({ title = '', msg = '', warn }) => {
    const _type = warn ? 'warning' : 'success';
    return (<div className={`alert alert-${_type}`} role="alert" >
        <strong>{title}</strong>
        <div>{msg}</div>
    </div >)
};