import * as Yup from "yup";
import { FIELD_LENGTH_SERIE, VALIDATION_MSG_NUMBER, VALIDATION_MSG_REQUIRED, VALIDATION_REGEX_NUMBER } from "../../../../utils/form.utils";

export const linkFormSchema = Yup.object().shape({
    serie: Yup.string()
        .required(VALIDATION_MSG_REQUIRED)
        .max(FIELD_LENGTH_SERIE)
        .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),
});

export const formInitialValues = {
    serie: "",
    hasClient: 0,
    statusId: 1
}
