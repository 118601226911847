import React, { useContext, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Box, Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { AppContext } from "../../../store";
import Loader from "../../../components/shared/loader";
import { SearchExtinguisherBySerieForm } from "../../extinguisher/search/by-serie/form";
import { Notification } from "../../../components/shared/notification";
import { GoBackButton } from "../../../components/shared/go-back";
import { linkExtinguishersList } from "../../../domain/client/actions/link-unlink";

import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { ErrorNotification } from "../../../components/shared/error";
import { ExtinguishersListPreview } from "../../extinguisher/components/list-preview";
import { PageTitle } from "../../../components/shared/page-title";
import { GenericBoxItem } from "../../../components/shared/card-box-container";

export default function ({ match, history, location }: RouteComponentProps) {
    const { client: clientState } = useContext(AppContext);
    //@ts-ignore
    const { client } = location.state || {};
    if (!client) {
        history.push('/client/search');
        return null;
    }

    const [extinguishers, setExtinguishers] = useState<Array<extinguisher.UIModel>>([]);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const removeExtinguisher = (id: string) => setExtinguishers(extinguishers.filter(e => e.serialNumber !== id));

    const associateExtinguishers = () => {
        setIsSubmittingForm(true)
        linkExtinguishersList({ clientId: client.id, extinguisherIds: extinguishers.map(({ serialNumber }) => (serialNumber)) })(clientState.dispatch)
            .then(_ => history.push("/success", { operation: 'EXT_LINK' }))
            .finally(() => setIsSubmittingForm(false))
    }
    const warningMessages = {
        'DUPLICATE': { title: 'Duplicado', desc: 'El extintor que intenta agregar ya se encuentra en la lista.' },
        'NOT_FOUND': { title: 'Extintor faltante', desc: 'El extintor buscado no se encuentra. Revise el numero e intente nuevamente.' },
        'ALREADY_LINKED': { title: 'Extintor ya asociado', desc: 'El extintor buscado ya se encuentra asociado a este cliente.' },
    }
    return (
        <>
            <PageTitle title="Listado de Elementos" />
            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>

                <GenericBoxItem>
                    <h3>Asociar Extintores</h3>
                    <h4>[{client.id}] {client.name}</h4>
                </GenericBoxItem>

                {clientState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
                {clientState.error && <GenericBoxItem><ErrorNotification error={clientState.error} /></GenericBoxItem>}

                {!clientState.loading && (
                    <>
                        <GenericBoxItem>
                            <h3>Busqueda de Extintores</h3>
                            { //@ts-ignore
                                warningMessage && <Notification warn title={warningMessages[warningMessage].title} msg={warningMessages[warningMessage].desc} />
                            }
                        </GenericBoxItem>

                        <GenericBoxItem>
                            <SearchExtinguisherBySerieForm onSuccess={(extinguisher: extinguisher.UIModel) => {
                                setWarningMessage(undefined);

                                const isOfSameClient = extinguisher.clientId === client.id;
                                if (isOfSameClient) {
                                    setWarningMessage('ALREADY_LINKED');
                                    return;
                                }

                                const isAlreadyOnList = extinguishers.find(e => e.serialNumber === extinguisher.serialNumber);
                                if (isAlreadyOnList) {
                                    setWarningMessage('DUPLICATE');
                                    return;
                                }

                                extinguishers.push(extinguisher)
                                setExtinguishers([...extinguishers])
                            }}
                                onError={() => setWarningMessage('NOT_FOUND')}
                                onReset={() => setWarningMessage(undefined)} />
                        </GenericBoxItem>

                        <GenericBoxItem>
                            <ExtinguishersListPreview list={extinguishers}
                                onDelete={(id) => removeExtinguisher(id)}
                            />
                        </GenericBoxItem>

                        <GenericBoxItem>
                            <Stack direction="row" spacing={2}>
                                <LoadingButton
                                    variant="contained"
                                    loading={isSubmittingForm}
                                    disabled={extinguishers.length === 0}
                                    type="button"
                                    onClick={() => associateExtinguishers()} >
                                    Asociar
                                </LoadingButton>
                                <Button variant="contained" color="secondary"
                                    onClick={() => { setWarningMessage(undefined); setExtinguishers([]) }}
                                    type="button"
                                >Reset</Button>
                                {/* //TODO: reset serial number field value too */}
                            </Stack>
                        </GenericBoxItem>

                        <GenericBoxItem>
                            <GoBackButton history={history} />
                        </GenericBoxItem>
                    </>)}

            </Box>
            <ScrollToTop />
        </>
    );
};