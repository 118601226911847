import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";

import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { LoadingButton } from "@mui/lab";

import { AppContext } from "../../../store";
import { ExpandPanel } from "../../../components/shared/expand-panel";
import { searchClient } from "../../../domain/client/actions/search";

import { searchFormSchema, clientIdMaxLength } from "./client-search-form-schema";
import { FormFieldBoxItem, getErrorText, hasError } from "../../../components/shared/form-utils";
import { FIELD_LENGTH_FANTASY_NAME, FIELD_LENGTH_NAME, FIELD_LENGTH_PHONE, FIELD_LENGTH_PHONE_AREA, FIELD_LENGTH_STATE, FIELD_LENGTH_STREET, restrictNumberOnly } from "../../../utils/form.utils";
interface IClientSearchFrom {
  afterSearch: Function;
}


export default ({ afterSearch }: IClientSearchFrom) => {
  const { client: clientState } = useContext(AppContext);
  const [isIdProvided, setIsIdProvided] = useState(false);

  const formInitialValues = {
    clientId: "",
    clientName: "",
    clientFantasyName: "",
    clientStreet: "",
    clientPhone: "",
    clientProvince: ""
  };

  return (
    <ExpandPanel title="Criterio de Busqueda">
      <Formik
        initialValues={formInitialValues}
        validationSchema={searchFormSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          searchClient({
            id: values.clientId,
            name: values.clientName,
            fantasyName: values.clientFantasyName,
            street: values.clientStreet,
            phone: values.clientPhone,
            province: values.clientProvince
          })(clientState.dispatch)
            .finally(() => setSubmitting(false));
          afterSearch()
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldTouched,
          setFieldValue
          /* and other goodies */
        }) => {
          const formikProps = (name: string): StandardTextFieldProps => ({
            variant: "standard",
            name,
            //@ts-ignore
            value: values[name],
            error: hasError(touched, errors, name),
            helperText: getErrorText(errors, name),
            onChange: handleChange(name),
            onBlur: handleBlur(name)
          });
          return (
            <Form>
              <span style={{ marginLeft: '1rem', fontSize: '0.8rem' }}>Ingrese un valor en el campo "ID" o en los otros campos debajo</span>

              <FormFieldBoxItem>
                <TextField
                  type="tel"
                  inputProps={{ maxLength: clientIdMaxLength }}
                  placeholder="Ingrese numero de cliente"
                  autoComplete="off"
                  label="ID"
                  {...formikProps('clientId')}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value.length > 0) {
                      setIsIdProvided(true);
                      setFieldValue('clientName', '');
                      setFieldValue('clientFantasyName', '');
                      setFieldValue('clientStreet', '');
                      setFieldValue('clientProvince', '');
                      setFieldValue('clientPhone', '');
                    } else setIsIdProvided(false);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') return handleSubmit();
                    restrictNumberOnly(e);
                  }}
                />
              </FormFieldBoxItem>

              <FormFieldBoxItem>
                <TextField
                  type="search"
                  inputProps={{ maxLength: FIELD_LENGTH_NAME }}
                  placeholder="Ingrese (parte de) Razon Social"
                  autoComplete="off"
                  label="Razon Social"
                  {...formikProps('clientName')}
                  disabled={isIdProvided}
                />
              </FormFieldBoxItem>

              <FormFieldBoxItem>
                <TextField
                  type="search"
                  inputProps={{ maxLength: FIELD_LENGTH_FANTASY_NAME }}
                  placeholder="Ingrese (parte de) Nombre Fantasia"
                  autoComplete="off"
                  label="Nombre Fantasia"
                  {...formikProps('clientFantasyName')}
                  disabled={isIdProvided}
                />
              </FormFieldBoxItem>

              <FormFieldBoxItem>
                <TextField
                  type="search"
                  inputProps={{ maxLength: FIELD_LENGTH_STREET }}
                  placeholder="Ingrese (parte del) nombre de la Calle"
                  autoComplete="off"
                  label="Calle"
                  {...formikProps('clientStreet')}
                  disabled={isIdProvided}
                />
              </FormFieldBoxItem>

              <FormFieldBoxItem>
                <TextField
                  type="search"
                  inputProps={{ maxLength: FIELD_LENGTH_STATE }}
                  placeholder="Ingrese (part del) nombre de la Provincia"
                  autoComplete="off"
                  label="Provincia"
                  {...formikProps('clientProvince')}
                  disabled={isIdProvided}
                />
              </FormFieldBoxItem>

              <FormFieldBoxItem>
                <TextField
                  type="tel"
                  inputProps={{ maxLength: FIELD_LENGTH_PHONE_AREA + FIELD_LENGTH_PHONE }}
                  placeholder="Ingrese (parte del) Telefono"
                  autoComplete="off"
                  label="Telefono"
                  {...formikProps('clientPhone')}
                  disabled={isIdProvided}
                />
              </FormFieldBoxItem>

              <FormFieldBoxItem>
                <Stack direction="row" spacing={2}>
                  <LoadingButton variant="contained"
                    loading={isSubmitting}
                    disabled={Object.keys(errors).length > 0}
                    type="submit"
                  >
                    Buscar
                  </LoadingButton>
                  <Button variant="contained" color="secondary"
                    onClick={() => resetForm(formInitialValues)}
                    type="button"
                  >Reset</Button>
                </Stack>
              </FormFieldBoxItem>
            </Form>
          );
        }}
      </Formik>
    </ExpandPanel>
  );
};
