import React, { useContext } from "react";

import { AppContext } from "../../../store";
import { ExtinguisherGenericContainer } from "../extinguisher-generic-container";

export const ExtinguisherBrandContainer = () => {
  const { extinguisherBrands: extinguisherBrandsState } = useContext(
    AppContext
  );

  return (
    <ExtinguisherGenericContainer
      extinguisherSpecificState={extinguisherBrandsState}
    ></ExtinguisherGenericContainer>
  );
};
