import React, { Fragment, useContext } from "react";
import { RouteComponentProps } from "react-router";
import { Alert, Box } from "@mui/material";

import { LoginForm } from "./components/login.form";
import { PageTitle } from "../../components/shared/page-title";

import { AppContext } from "../../store";
import Loader from "../../components/shared/loader";
import { GenericBoxItem } from "../../components/shared/card-box-container";

export default function ({ history }: RouteComponentProps) {
    const { user: userState } = useContext(AppContext);

    return (
        <Fragment>
            <PageTitle title="Login"></PageTitle>

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>

                {userState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
                {
                    userState.error && (
                        <GenericBoxItem>
                            <Alert severity="error">
                                {userState.error.code === "INVALID_CREDENTIALS"
                                    ? `Usuario y/o Contraseña invalidos`
                                    : userState.error.message}
                            </Alert>
                        </GenericBoxItem>
                    )
                }


                <GenericBoxItem>
                    <LoginForm
                        onSuccess={() => {
                            history.push("/");
                        }}
                    />
                </GenericBoxItem>
            </Box>

        </Fragment>
    );
};