import React, { useContext } from "react";
import { AppContext } from '../../../../store'
import { GenericTable } from "../../../../components/shared/table";
import { getTypeDescription } from "../../../../domain/extinguisher/type/selectors";
import { getCapacityDescription } from "../../../../domain/extinguisher/capacity/selectors";
import { getBrandDescription } from "../../../../domain/extinguisher/brand/selectors";
import { Notification } from "../../../../components/shared/notification";

interface IExtinguishersListPreview {
    list: Array<extinguisher.UIModel>,
    onDelete: (id: string) => void;

}
export const ExtinguishersListPreview = ({ list, onDelete }: IExtinguishersListPreview) => {
    const {
        extinguisherBrands: extinguisherBrandsState,
        extinguisherCapacities: extinguisherCapacitiesState,
        extinguisherTypes: extinguisherTypesState
    } = useContext(AppContext);


    const columns = ["serie", "tipo", "capacidad", "marca"]
    const elements = list.map(row => ([
        { value: row.serialNumber },
        { value: getTypeDescription(row.type, extinguisherTypesState) },
        { value: getCapacityDescription(row.capacity, extinguisherCapacitiesState) },
        { value: getBrandDescription(row.brand, extinguisherBrandsState) }
    ]))

    return (
        <>
            <GenericTable
                elements={elements}
                columns={columns}
                onDelete={onDelete}
                renderNoElements={() => <Notification warn title="Agregar Exintores" msg="No hay extintores agregados aun!" />}
            />
        </>
    )
}