import { DocumentActionType, DocumentAction } from "./types";
import { doCall } from "../../../utils/apiCall";

const documentsLoadSuccess = (data: documento.UIModel[]): DocumentAction => ({
  type: DocumentActionType.LOAD_SUCCESS,
  payload: data
});
const documentsLoadFailure = (
  error: backend.common.ErrorResponse
): DocumentAction => ({
  type: DocumentActionType.LOAD_FAILURE,
  payload: error
});
const documentsLoadInit = (): DocumentAction => ({
  type: DocumentActionType.LOAD_INIT
});

export const loadDocuments = () => (dispatch: React.Dispatch<any>) => {
  dispatch(documentsLoadInit());
  return doCall({
    url: "/api/document"
  })
    .then((data) => {
      dispatch(documentsLoadSuccess(data as documento.UIModel[]));
    })
    .catch((error: backend.common.ErrorResponse) => {
      dispatch(documentsLoadFailure(error));
    });
};
