import * as Yup from "yup";

import {
    VALIDATION_MSG_REQUIRED
} from "../../../utils/form.utils";

export const FIELD_LENGTH_DESC = 125;
export const FIELD_LENGTH_DESC_SHORT = 25;

export const createFormSchema = Yup.object().shape({
    desc: Yup.string()
        .required(VALIDATION_MSG_REQUIRED)
        .max(FIELD_LENGTH_DESC),

    descShort: Yup.string()
        .required(VALIDATION_MSG_REQUIRED)
        .max(FIELD_LENGTH_DESC_SHORT),
});

export const formInitialValues: backend.productNS.EditProductRequest = {
    desc: "",
    descShort: "",
}