import { AdministratorActionType, AdministratorAction } from "./types";
import { doCall } from "../../../utils/apiCall";

const deleteSuccess = (id: string): AdministratorAction => ({
    type: AdministratorActionType.DELETE_SUCCESS,
    payload: id
});
const deleteFailure = (error: backend.common.ErrorResponse): AdministratorAction => ({
    type: AdministratorActionType.DELETE_FAILURE,
    payload: error
});
const deleteInit = (): AdministratorAction => ({
    type: AdministratorActionType.DELETE_INIT
});

export const remove = (request: backend.administratorNS.DeleteAdministratorRequest) => (dispatch: React.Dispatch<any>) => {
    dispatch(deleteInit());
    return doCall({
        url: `/api/administrator/${request.id}`,
        method: "DELETE"
    })
        .then((_: backend.administratorNS.DeleteAdministratorResponse) => {
            dispatch(deleteSuccess(request.id));
        })
        .catch((error: backend.common.ErrorResponse) => {
            dispatch(deleteFailure(error));
            return Promise.reject(error)
        });
};