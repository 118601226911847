import React, { useRef, useEffect } from 'react';
import { StandardTextFieldProps } from '@mui/material';

type AddressAutocompleteProps = StandardTextFieldProps & {
    onPlaceSelect: Function;
    initialValue?: string;
    onChangeEvent: Function;
}

const GOOGLE_AUTOCOMPLETE_TYPES = 'address';
const GOOGLE_AUTOCOMPLETE_COUNTRY = 'ar';

export const AddressAutocomplete = ({ onPlaceSelect, initialValue = "", onChangeEvent, ...rest }: AddressAutocompleteProps) => {
    //@ts-ignore
    let autoComplete;

    const autoCompleteRef = useRef(null);

    //@ts-ignore
    const handlePlaceSelect = (updateSearchInputField) => {
        //@ts-ignore
        const addressObject = autoComplete.getPlace();

        if (!addressObject || !addressObject.formatted_address || !addressObject.geometry) {
            console.error('no full response received from google. Try again...')
            console.error({ addressObject })
            return;
        }

        updateSearchInputField(addressObject.formatted_address);

        const { address_components, geometry } = addressObject;
        const streetNumber = address_components.find((el: any) => el.types.includes('street_number'))?.long_name || "";
        const streetName = address_components.find((el: any) => el.types.includes('route')).long_name
        const neighborhoodName = address_components.find((el: any) => el.types.includes('sublocality_level_1'))?.long_name || "";

        const cityNameFromLocality = address_components.find((el: any) => el.types.includes('locality'))?.long_name || ""
        const cityNameCapitalElement = address_components.find((el: any) => el.types.includes('administrative_area_level_1'))

        let cityName = cityNameCapitalElement.short_name; // CABA
        let stateName = cityNameCapitalElement.long_name; // BUENOS AIRES

        if (cityNameCapitalElement.short_name !== 'CABA') {
            cityName = cityNameFromLocality;
            const stateNameLongName = address_components.find((el: any) => el.types.includes('administrative_area_level_1')).long_name;
            stateName = stateNameLongName === 'Provincia de Buenos Aires' ? 'Buenos Aires' : stateNameLongName
        }
        const countryName = address_components.find((el: any) => el.types.includes('country')).long_name
        const zipCode = address_components.find((el: any) => el.types.includes('postal_code'))?.long_name || ""
        const zipCodeSuffix = address_components.find((el: any) => el.types.includes('postal_code_suffix'))?.long_name

        const lat = geometry.location.lat();
        const lng = geometry.location.lng();

        console.warn({ streetNumber, streetName, cityName, stateName, countryName, neighborhoodName, zipCode: zipCodeSuffix ? `${zipCode}${zipCodeSuffix}` : zipCode, lat, lng })

        onPlaceSelect && onPlaceSelect({ streetNumber, streetName, cityName, stateName, countryName, neighborhoodName, zipCode: zipCodeSuffix ? `${zipCode}${zipCodeSuffix}` : zipCode, lat, lng })
    }

    useEffect(() => {
        //@ts-ignore
        if (window.google && autoCompleteRef !== null) {
            //@ts-ignore
            autoComplete = new window.google.maps.places.Autocomplete(
                //@ts-ignore
                autoCompleteRef.current,
                { types: [GOOGLE_AUTOCOMPLETE_TYPES], componentRestrictions: { country: GOOGLE_AUTOCOMPLETE_COUNTRY } }
            );
            //@ts-ignore
            autoComplete.setFields(["address_components", "geometry.location", "formatted_address"]);

            //@ts-ignore
            autoComplete.addListener("place_changed", () =>
                handlePlaceSelect(onChangeEvent)
            );
        }
    }, []);

    return (
        <input
            className="MuiInput-input MuiInputBase-input css-1x51dt5-MuiInputBase-input-MuiInput-input"
            ref={autoCompleteRef}
            onChange={event => onChangeEvent(event.target.value)}
            placeholder="Ingrese la direccion completa..."
            value={initialValue}
            style={{ width: '100%' }}
        />
    )
}