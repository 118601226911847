import { IndustryActionType, IndustryAction } from "./types";
import { doCall } from "../../../utils/apiCall";

const industriesLoadSuccess = (
  data: backend.industryNS.LoadAllResponseSuccess
): IndustryAction => ({
  type: IndustryActionType.LOAD_SUCCESS,
  payload: data
});
const industriesLoadFailure = (
  error: backend.common.ErrorResponse
): IndustryAction => ({
  type: IndustryActionType.LOAD_FAILURE,
  payload: error
});
const industriesLoadInit = (): IndustryAction => ({
  type: IndustryActionType.LOAD_INIT
});

export const loadIndustries = () => (dispatch: React.Dispatch<any>) => {
  dispatch(industriesLoadInit());
  return doCall({
    url: "/api/industry"
  })
    .then((data) => {
      dispatch(
        industriesLoadSuccess(data as backend.industryNS.LoadAllResponseSuccess)
      );
    })
    .catch((error: backend.common.ErrorResponse) => {
      dispatch(industriesLoadFailure(error));
    });
};
