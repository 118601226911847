import { ClientActionType, ClientAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import { downloadQuoteActionRequest, downloadQuoteActionResponse } from ".";
import { prepareAndSendDownloadFile } from "./downloadQuote.util";

const downloadQuoteSuccess = (): ClientAction => ({
  type: ClientActionType.DOWNLOAD_QUOTE_SUCCESS
});
const downloadQuoteFailure = (
  error: backend.quote.QuoteResponseError
): ClientAction => ({
  type: ClientActionType.DOWNLOAD_QUOTE_FAILURE,
  payload: error
});
const downloadQuoteInit = (): ClientAction => ({
  type: ClientActionType.DOWNLOAD_QUOTE_INIT
});

export const downloadQuote = ({
  clientId,
  filename
}: downloadQuoteActionRequest) => (
  dispatch: React.Dispatch<any>
): downloadQuoteActionResponse => {
  dispatch(downloadQuoteInit());
  return doCall({
    url: `/api/client/${clientId}/quote/download`,
    method: "POST",
    payload: { filename },
    responseType: "blob"
  })
    .then((data) => {
      dispatch(downloadQuoteSuccess());
      prepareAndSendDownloadFile(data, filename);
    })
    .catch((error: backend.quote.QuoteResponseError) => {
      dispatch(downloadQuoteFailure(error));
      return Promise.reject();
    });
};
