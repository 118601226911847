import React, { useContext, Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@mui/material";

import { AppContext } from "../../../store";
import Loader from "../../../components/shared/loader";

import { PageTitle } from "../../../components/shared/page-title";
import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { GoBackButton } from "../../../components/shared/go-back";
import { CreateForm } from "../create/create-form";
import { ErrorNotification } from "../../../components/shared/error";
import { GenericBoxItem } from "../../../components/shared/card-box-container";

interface MatchParams {
    id: string;
}
export default function ({ match, history }: RouteComponentProps<MatchParams>) {
    const {
        product: productState
    } = useContext(AppContext);

    return (
        <Fragment>
            <PageTitle title={"Agregar Producto"}></PageTitle>

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>
                <GenericBoxItem>
                    <h3>Agregar Producto</h3>
                </GenericBoxItem>

                {productState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
                {productState.error && <GenericBoxItem><ErrorNotification error={productState.error} /></GenericBoxItem>}

                <GenericBoxItem>
                    <CreateForm
                        dispatchFn={productState.dispatch}
                        onSuccess={() => history.push(`/success`, {
                            ...history.location,
                            operation: 'PRO_CREATE'
                        })}
                    />
                </GenericBoxItem>

                <GenericBoxItem>
                    <GoBackButton history={history} />
                </GenericBoxItem>

            </Box>
            <ScrollToTop />
        </Fragment>
    );
};