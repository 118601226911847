import { ClientActionType, ClientAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import {
  GetClientExtinguishersActionResponse,
  GetClientExtinguishersActionRequest
} from "./index.d";

const getExtinguishersSuccess = (
  data: backend.extinguisherNS.GetExtinguishersByClientResponseSuccess
): ClientAction => ({
  type: ClientActionType.GET_EXTINGUISHERS_SUCCESS,
  payload: data
});
const getExtinguishersFailure = (
  error: backend.common.ErrorResponse
): ClientAction => ({
  type: ClientActionType.GET_EXTINGUISHERS_FAILURE,
  payload: error
});
const getExtinguishersInit = (): ClientAction => ({
  type: ClientActionType.GET_EXTINGUISHERS_INIT
});

export const getClientExtinguishers = ({
  id
}: GetClientExtinguishersActionRequest) => (
  dispatch: React.Dispatch<any>
): GetClientExtinguishersActionResponse => {
    dispatch(getExtinguishersInit());
    return doCall({
      url: `/api/client/${id}/extinguishers`
    })
      .then((data: backend.extinguisherNS.GetExtinguishersByClientResponseSuccess) => {
        const values: extinguisher.UIModel[] = Object.values(data.all);
        dispatch(getExtinguishersSuccess(data));
        return Promise.resolve(values);
      })
      .catch((error: backend.common.ErrorResponse) => {
        dispatch(getExtinguishersFailure(error));
        return Promise.reject(error)
      });
  };
