export enum ExtinguisherBrandActionType {
  LOAD_INIT = "@extinguisher-brand/LOAD_INIT",
  LOAD_SUCCESS = "@extinguisher-brand/LOAD_SUCCESS",
  LOAD_FAILURE = "@extinguisher-brand/LOAD_FAILURE"
}

export type ExtinguisherBrandAction =
  | { type: ExtinguisherBrandActionType.LOAD_INIT }
  | {
      type: ExtinguisherBrandActionType.LOAD_SUCCESS;
      payload: backend.extBrandNS.LoadAllResponseSuccess;
    }
  | { type: ExtinguisherBrandActionType.LOAD_FAILURE };
