import React, { useContext } from "react";

import { AppContext } from "../../../store";
import { ExtinguisherGenericContainer } from "../extinguisher-generic-container";

export const ExtinguisherTypeContainer = () => {
  const { extinguisherTypes: extinguisherTypesState } = useContext(AppContext);

  return (
    <ExtinguisherGenericContainer
      extinguisherSpecificState={extinguisherTypesState}
    ></ExtinguisherGenericContainer>
  );
};
