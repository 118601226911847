import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import Loader from '../loader';

export interface IAlertDialog {
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    onConfirm: Function;
    message: string;
    title: string;
    label: string;
    isLoading?: boolean;
    isSuccess?: boolean;
    hasError?: boolean;
};

export const AlertDialog: React.FunctionComponent<IAlertDialog> = ({ hasError, onConfirm, confirmButtonLabel = 'OK', cancelButtonLabel = 'Cancelar', message, title, label, isLoading, isSuccess }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (isSuccess) handleClose()
    }, [isSuccess])

    return (
        <div>
            <span onClick={handleClickOpen}>{label}</span>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogTitle>{title}</DialogTitle>

                <DialogContent>

                    {hasError && (<DialogContentText><Alert severity="error">Se ha producido un error</Alert></DialogContentText>)}
                    {isLoading && <Loader />}

                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {cancelButtonLabel}
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={() => onConfirm()} color="primary" autoFocus>
                        {confirmButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
