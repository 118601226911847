import React, { FunctionComponent } from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';

import { ClientState } from '../../../domain/client/reducer/client';
import { formatAmount } from '../../../utils/format';

import { downloadQuote } from "../../../domain/client/actions/downloadQuote";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    'cursor': 'pointer'
}));

interface IQuoteListTable {
    quotes: ClientState["quotes"];
    state: ClientState;
    clientId: number;
}
export const QuoteListTable: FunctionComponent<IQuoteListTable> = ({ quotes, state: clientState, clientId }) => {
    if (quotes.length === 0) return null;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell>Fecha</StyledTableCell>
                        <StyledTableCell>Numero</StyledTableCell>
                        <StyledTableCell>Importe</StyledTableCell>
                        <StyledTableCell>Acciones</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {quotes.map((quote, idx) => {
                        return (
                            <StyledTableRow key={`row-${idx}`}>
                                <StyledTableCell component="th" scope="row">
                                    {idx + 1}
                                </StyledTableCell>
                                <StyledTableCell>{quote.date}</StyledTableCell>
                                <StyledTableCell>{quote.number}</StyledTableCell>
                                <StyledTableCell>
                                    {formatAmount(quote.total)}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <DownloadIcon titleAccess="Descargar" onClick={() =>
                                        downloadQuote({
                                            clientId: clientId,
                                            filename: quote.filename as string
                                        })(clientState.dispatch)
                                    }></DownloadIcon>
                                </StyledTableCell>
                            </StyledTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
