// src/client/controller/client.schema.ts

import * as Yup from "yup";
import {
  VALIDATION_MSG_EMAIL, VALIDATION_MSG_INTEGER, VALIDATION_MSG_NUMBER, VALIDATION_MSG_POSITIVE, VALIDATION_MSG_REQUIRED, VALIDATION_MSG_WEB,
  FIELD_LENGTH_NAME,
  FIELD_LENGTH_FANTASY_NAME,
  FIELD_LENGTH_COMMENTS, FIELD_LENGTH_CONTACT,
  FIELD_LENGTH_EMAIL, FIELD_LENGTH_WEB,
  FIELD_LENGTH_PHONE, FIELD_LENGTH_PHONE_AREA,
  FIELD_UNIT_LENGTH, FIELD_FLOOR_LENGTH, FIELD_LENGTH_HOURS, VALIDATION_REGEX_NUMBER, FIELD_LENGTH_STREET, FIELD_LENGTH_DOOR, FIELD_LENGTH_CITY, FIELD_LENGTH_STATE, FIELD_LENGTH_NEIGHBORHOOD, FIELD_LENGTH_ZIP, FIELD_LENGTH_TEL3
} from "../../../utils/form.utils";

export const createClientFormSchema = Yup.object().shape({
  name: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_NAME),

  fantasy: Yup.string()
    .max(FIELD_LENGTH_FANTASY_NAME),

  iva_id: Yup.number()
    .typeError(VALIDATION_MSG_NUMBER)
    .required(VALIDATION_MSG_REQUIRED)
    .integer(VALIDATION_MSG_INTEGER)
    .positive(VALIDATION_MSG_POSITIVE),

  document_id: Yup.number()
    .typeError(VALIDATION_MSG_NUMBER)
    .required(VALIDATION_MSG_REQUIRED)
    .integer(VALIDATION_MSG_INTEGER)
    .positive(VALIDATION_MSG_POSITIVE),

  document: Yup.number()
    .typeError(VALIDATION_MSG_NUMBER)
    .positive(VALIDATION_MSG_POSITIVE),

  seller_id: Yup.number()
    .typeError(VALIDATION_MSG_NUMBER)
    .required(VALIDATION_MSG_REQUIRED)
    .integer(VALIDATION_MSG_INTEGER)
    .positive(VALIDATION_MSG_POSITIVE),

  industry_id: Yup.number()
    .typeError(VALIDATION_MSG_NUMBER)
    .required(VALIDATION_MSG_REQUIRED)
    .integer(VALIDATION_MSG_INTEGER)
    .positive(VALIDATION_MSG_POSITIVE),

  street1_name: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_STREET),

  door1: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_DOOR),

  city1_name: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_CITY),

  state1_name: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_STATE),

  neighborhood1_name: Yup.string()
    .max(FIELD_LENGTH_NEIGHBORHOOD),

  zip1: Yup.string()
    .required(VALIDATION_MSG_REQUIRED)
    .max(FIELD_LENGTH_ZIP),

  unit1: Yup.string()
    .max(FIELD_UNIT_LENGTH),

  floor1: Yup.string()
    .max(FIELD_FLOOR_LENGTH),

  lat1: Yup.number()
    .required(VALIDATION_MSG_REQUIRED),

  lng1: Yup.number()
    .required(VALIDATION_MSG_REQUIRED),

  email: Yup.string()
    .email(VALIDATION_MSG_EMAIL)
    .max(FIELD_LENGTH_EMAIL),

  web: Yup.string()
    .url(VALIDATION_MSG_WEB)
    .max(FIELD_LENGTH_WEB),

  administrator_id: Yup.number()
    .typeError(VALIDATION_MSG_NUMBER)
    .integer(VALIDATION_MSG_INTEGER)
    .positive(VALIDATION_MSG_POSITIVE),

  audit: Yup.bool(),

  comments: Yup.string()
    .max(FIELD_LENGTH_COMMENTS),

  contact: Yup.string()
    .max(FIELD_LENGTH_CONTACT),

  hours: Yup.string()
    .max(FIELD_LENGTH_HOURS),

  //TODO: combina validation for tel1 and tel2
  // if any field from tel1, other field from tel1 should be there
  // if any field from tel2, other field from tel2 should be there
  tel1_area: Yup.string()
    .max(FIELD_LENGTH_PHONE_AREA)
    .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),

  tel1_num: Yup.string()
    .max(FIELD_LENGTH_PHONE)
    .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),

  tel2_area: Yup.string()
    .max(FIELD_LENGTH_PHONE_AREA)
    .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),

  tel2_num: Yup.string()
    .max(FIELD_LENGTH_PHONE)
    .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),

  tel3: Yup.string()
    .max(FIELD_LENGTH_TEL3)
    .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),

  hydrants_quantity: Yup.number()
    .typeError(VALIDATION_MSG_NUMBER)
    .integer(VALIDATION_MSG_INTEGER)
    .positive(VALIDATION_MSG_POSITIVE)
    .max(999, "Cantidad debe ser menor a 999"),

  hydrants_expiration_date: Yup.date().typeError()

});

export const formInitialValues: backend.client.EditClientRequest = {
  name: "",
  audit: "false",
  fantasy: "",
  document: "",
  document_id: 1,
  iva_id: 1,
  seller_id: 1,
  industry_id: 1,
  web: "",
  email: "",
  hours: "",
  contact: "",
  comments: "",
  tel1_area: "",
  tel1_num: "",
  tel2_area: "",
  tel2_num: "",
  tel3: "",
  city1_name: "",
  state1_name: "",
  street1_name: "",
  neighborhood1_name: "",
  door1: "",
  unit1: "",
  floor1: "",
  zip1: "",
  lat1: "" as unknown as number,
  lng1: "" as unknown as number,
  statusId: 1,
  hydrants_quantity: "" as unknown as number,
  hydrants_expiration_date: "" as unknown as string
};
