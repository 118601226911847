import { ProductActionType, ProductAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import { loadProducts } from ".";

const editProductSuccess = (): ProductAction => ({
    type: ProductActionType.EDIT_SUCCESS
});
const editProductFailure = (
    error: backend.common.ErrorResponse
): ProductAction => ({
    type: ProductActionType.EDIT_FAILURE,
    payload: error
});
const editProductInit = (): ProductAction => ({
    type: ProductActionType.EDIT_INIT
});

export type IEditProductRequest = backend.productNS.EditProductRequest & {
    id: string;
}
export const edit = ({ id, desc, descShort }: IEditProductRequest) => (dispatch: React.Dispatch<any>) => {
    dispatch(editProductInit());
    return doCall({
        url: `/api/product/${id}`,
        method: "PUT",
        payload: {
            desc,
            descShort
        }
    })
        .then(() => {
            dispatch(editProductSuccess());
            loadProducts()(dispatch)
            return Promise.resolve()
        })
        .catch((error: backend.common.ErrorResponse) => {
            dispatch(editProductFailure(error));
            return Promise.reject(error)
        });
};
