export const formatDocument = (doc: string | undefined) => {
  if (doc?.length === 11) {
    return `${doc.substr(0, 2)}-${doc.substr(2, 8)}-${doc.substr(-1)}`;
  } else {
    return doc;
  }
};

export const isInactive = (client: backend.client.GetClientDetailsResponseSuccess): boolean =>
  client && client.status === 2;
