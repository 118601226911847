import React, { useContext, useState, useEffect, Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@mui/material";

import { AppContext } from "../../../store";
import { ClientQuoteRouteParams } from "./types";
import { downloadQuote } from "../../../domain/client/actions/downloadQuote";
import Loader from "../../../components/shared/loader";

import { ClientQuoteForm } from "./clientQuoteForm";
import { PageTitle } from "../../../components/shared/page-title";
import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { SendQuoteForm } from "./sendQuoteForm";
import { GoBackButton } from "../../../components/shared/go-back";
import { ErrorNotification } from "../../../components/shared/error";
import { GenericBoxItem } from "../../../components/shared/card-box-container";
import { getClientDetails } from "../../../domain/client/actions/getDetails";

export default function ({
  match,
  history,
  location
}: RouteComponentProps<ClientQuoteRouteParams>) {
  const [client, setClient] = useState<undefined | backend.client.GetClientDetailsResponseSuccess>(undefined);
  const {
    client: clientState,
    product: productState
  } = useContext(AppContext);

  useEffect(() => {
    //@ts-ignore
    const { client } = location.state || {};
    const isFound = typeof client !== "undefined";
    if (!isFound) { // client recently added
      console.warn('client not found. Attempting to look for it...');
      getClientDetails({ id: Number(match.params?.clientId) })(clientState.dispatch)
        .then(response => {
          if (typeof response !== "undefined") {
            setClient(response);
          } else {
            console.error('client not found. Redirecting to home...');
            history.push(`/`);
            return;
          }
        })
    } else {
      setClient(client);
    }
  }, [match.params?.clientId, location.state, history])

  const [clientId] = useState(parseInt(match.params.clientId));
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);

  if (!client) return null;

  return (
    <Fragment>

      <PageTitle title={`[${client.id}] ${client.name}: Presupuestar`}></PageTitle>

      <Box sx={{ display: 'grid' }}>
        <GenericBoxItem>
          <h3>Presupuestar</h3>
          <h4>[{client.id}] {client.name}</h4>
        </GenericBoxItem>

        {clientState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
        {clientState.error && <GenericBoxItem><ErrorNotification error={clientState.error} /></GenericBoxItem>}

        <GenericBoxItem sx={{ margin: 0 }}>
          {isSuccess ? (
            <Fragment>
              <div className="alert alert-success" role="alert">
                El prespuesto se ha generado con &eacute;xito!
                <br />
                Haga click en el boton "Descargar Prespuesto" para verlo.
              </div>
              <button
                type="button"
                className="btn btn-primary btn-lg btn-block"
                onClick={() =>
                  downloadQuote({
                    clientId: clientId,
                    filename: clientState.quote?.filename as string
                  })(clientState.dispatch)
                }
              >
                Descargar Presupuesto
              </button>
              {/* <button
                type="button"
                className="btn btn-primary btn-lg btn-block"
                onClick={() => {
                  setDisplayForm(!displayForm);
                }}
              >
                Email PDF
              </button> */}
              {displayForm && (
                <SendQuoteForm
                  clientEmail={client.email}
                  clientId={clientId}
                  filename={clientState.quote?.filename as string}
                />
              )}
            </Fragment>
          ) : (
            <ClientQuoteForm
              client={client}
              products={productState.all}
              isError={isError}
              onSubmit={() => {
                setIsSuccess(true);
              }}
              onError={() => setIsError(true)}
            />
          )}

        </GenericBoxItem>

        <GenericBoxItem>
          <GoBackButton history={history} />
        </GenericBoxItem>
      </Box>

      <ScrollToTop />
    </Fragment>
  );
};