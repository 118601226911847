export const VALIDATION_MSG_REQUIRED = 'Debe ingresar un valor!';

export const VALIDATION_REGEX_NUMBER = /^\d+$/;
export const VALIDATION_MSG_NUMBER = 'Debe ingresar solo digitos (0-9)!';
export const VALIDATION_MSG_POSITIVE = 'Debe ingresar un numero mayor que cero!';
export const VALIDATION_MSG_INTEGER = 'Debe ingresar un numero sin decimales!';

export const VALIDATION_MSG_DATE = 'Debe ingresar una fecha valida!'

export const VALIDATION_MSG_EMAIL = 'Debe ingresar un email valido!'
export const VALIDATION_MSG_WEB = 'Debe ingresar una direccion web valida!'


export const FIELD_LENGTH_SERIE = 20;
export const FIELD_LENGTH_MANUFACTURER_MONTH = 2;
export const FIELD_LENGTH_MANUFACTURER_YEAR = 4;


export const FIELD_LENGTH_NAME = 200;
export const FIELD_LENGTH_FANTASY_NAME = 200;

export const FIELD_LENGTH_DOCUMENT = 11;

export const FIELD_LENGTH_PHONE_AREA = 3;
export const FIELD_LENGTH_PHONE = 11;

export const FIELD_LENGTH_CONTACT = 200;
export const FIELD_LENGTH_HOURS = 200;
export const FIELD_LENGTH_EMAIL = 150;
export const FIELD_LENGTH_WEB = 200;
export const FIELD_LENGTH_COMMENTS = 65000;
export const FIELD_LENGTH_TEL3 = 50;


export const FIELD_LENGTH_STREET = 200;
export const FIELD_LENGTH_DOOR = 10;
export const FIELD_LENGTH_NEIGHBORHOOD = 200;
export const FIELD_LENGTH_CITY = 200;
export const FIELD_LENGTH_STATE = 200;
export const FIELD_LENGTH_ZIP = 10;
export const FIELD_UNIT_LENGTH = 10;
export const FIELD_FLOOR_LENGTH = 10;

export const FIELD_HYDRANTS_QTY_LENGTH = 3;

export const restrictNumberOnly = (event: any) => {
    if (isNaN(event.key)) event.preventDefault();
};