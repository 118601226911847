import { ClientActionType, ClientAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import { saveClientActionRequest, saveClientActionResponse } from "./index.d";

const saveClientSuccess = (): ClientAction => ({
  type: ClientActionType.CREATE_SUCCESS
});
const saveClientFailure = (
  error: backend.quote.QuoteResponseError
): ClientAction => ({
  type: ClientActionType.CREATE_FAILURE,
  payload: error
});
const saveClientInit = (): ClientAction => ({
  type: ClientActionType.CREATE_INIT
});

export const saveClient = (request: saveClientActionRequest) => (
  dispatch: React.Dispatch<any>
): saveClientActionResponse => {
  dispatch(saveClientInit());
  return doCall({
    url: `/api/client`,
    method: "PUT",
    payload: removeAllEmptyFields(request)
  })
    .then((response) => {
      dispatch(saveClientSuccess());
      return (response as backend.client.CreateClientResponse).id;
    })
    .catch((error: backend.quote.QuoteResponseError) => {
      dispatch(saveClientFailure(error));
      return Promise.reject(error);
    });
};

const removeAllEmptyFields = (request: object) => Object.fromEntries(Object.entries(request).filter(([_, v]) => String(v).length > 0))