import { AdministratorActionType, AdministratorAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import { editActionRequest, editActionResponse } from "./index.d";
import { loadAdministrators } from ".";

const editSuccess = (): AdministratorAction => ({
    type: AdministratorActionType.EDIT_SUCCESS
});
const editFailure = (
    error: backend.quote.QuoteResponseError
): AdministratorAction => ({
    type: AdministratorActionType.EDIT_FAILURE,
    payload: error
});
const editInit = (): AdministratorAction => ({
    type: AdministratorActionType.EDIT_INIT
});

export const edit = (request: editActionRequest) => (
    dispatch: React.Dispatch<any>
): editActionResponse => {
    dispatch(editInit());
    const { id, ...rest } = request;
    return doCall({
        url: `/api/administrator/${id}`,
        method: "PUT",
        payload: removeAllEmptyFields(rest)
    })
        .then((_) => {
            dispatch(editSuccess());
            loadAdministrators()(dispatch);
        })
        //@ts-ignore
        .catch((error: backend.quote) => {
            dispatch(editFailure(error));
            return Promise.reject(error);
        });
};

const removeAllEmptyFields = (request: object) => Object.fromEntries(Object.entries(request).filter(([_, v]) => String(v).length > 0))