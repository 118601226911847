export enum ExtinguisherServiceNeededActionType {
  SEARCH_INIT = "@extinguisher-serviceNeeded/SEARCH_INIT",
  SEARCH_SUCCESS = "@extinguisher-serviceNeeded/SEARCH_SUCCESS",
  SEARCH_FAILURE = "@extinguisher-serviceNeeded/SEARCH_FAILURE"
}

export type ExtinguisherServiceNeededAction =
  | {
      type: ExtinguisherServiceNeededActionType.SEARCH_INIT;
      payload: {
        month: string;
        year: string;
      };
    }
  | {
      type: ExtinguisherServiceNeededActionType.SEARCH_SUCCESS;
      payload: backend.client.SearchExtinguishersServiceNeededResponseSuccess;
    }
  | {
      type: ExtinguisherServiceNeededActionType.SEARCH_FAILURE;
      payload: backend.common.ErrorResponse;
    };

export interface SearchExtinguisherServiceNeededRequest {
  month: string;
  year: string;
}

export interface GetClientsGeoLocation {
  addresses: string[];
}

export interface GetExtinguishersToAuditRequest {
  month: string;
}
export enum GetClientsGeoLocationActionType {
  SEARCH_INIT = "@client-geoLocation/SEARCH_INIT",
  SEARCH_SUCCESS = "@client-geoLocation/SEARCH_SUCCESS",
  SEARCH_FAILURE = "@client-geoLocation/SEARCH_FAILURE"
}

export enum GetExtinguishersToAuditActionType {
  SEARCH_INIT = "@extinguisher-audit/SEARCH_INIT",
  SEARCH_SUCCESS = "@extinguisher-audit/SEARCH_SUCCESS",
  SEARCH_FAILURE = "@extinguisher-audit/SEARCH_FAILURE"
}

export type GetClientsGeoLocationAction =
  | { type: GetClientsGeoLocationActionType.SEARCH_INIT }
  | {
      type: GetClientsGeoLocationActionType.SEARCH_SUCCESS;
      payload: backend.client.GetClientsGeoLocationResponseSuccess;
    }
  | { type: GetClientsGeoLocationActionType.SEARCH_FAILURE };

export type GetExtinguishersToAuditAction =
  | { type: GetExtinguishersToAuditActionType.SEARCH_INIT }
  | {
      type: GetExtinguishersToAuditActionType.SEARCH_SUCCESS;
      payload: extinguisher.ClientToAuditUIModel[];
    }
  | { type: GetExtinguishersToAuditActionType.SEARCH_FAILURE };
