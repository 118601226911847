import { DocumentAction, DocumentActionType } from "../actions/types";

export interface DocumentState {
  all: Array<documento.UIModel>;
  loading: boolean;
  dispatch?: any;

  error?: backend.common.ErrorResponse;
}
export default (
  state: DocumentState,
  action: DocumentAction
): DocumentState => {
  switch (action.type) {
    case DocumentActionType.LOAD_SUCCESS: {
      return {
        ...state,
        all: action.payload.sort((a, b) => a.desc.toUpperCase() < b.desc.toUpperCase() ? -1 : 1),
        loading: false,
        error: undefined
      };
    }

    case DocumentActionType.LOAD_INIT:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case DocumentActionType.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
};
