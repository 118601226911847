import { ExtinguisherActionType, ExtinguisherAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import { ISearchFormInitialValues } from "../../../screens/extinguisher/search/search-form-schema";

export const searchSuccess = (data: Array<extinguisher.UIModel>): ExtinguisherAction => ({
    type: ExtinguisherActionType.SEARCH_SUCCESS,
    payload: data
});
const searchFailure = (error: backend.common.ErrorResponse): ExtinguisherAction => ({
    type: ExtinguisherActionType.SEARCH_FAILURE,
    payload: error
});
const searchInit = (): ExtinguisherAction => ({
    type: ExtinguisherActionType.SEARCH_INIT
});

export const search = (request: ISearchFormInitialValues) => (dispatch: React.Dispatch<any>) => {
    dispatch(searchInit());
    return doCall({
        url: "/api/extinguisher",
        method: "GET",
        queryParams: transform(request)
    })
        .then((response) => {
            dispatch(searchSuccess(response));
            return Promise.resolve(response);
        })
        .catch((error: backend.common.ErrorResponse) => {
            dispatch(searchFailure(error));
            return Promise.reject(error)
        });
};


const transform = (request: ISearchFormInitialValues): backend.extinguisherNS.SearchExtinguisherRequest => {
    const { expirationDateStart, expirationDateEnd, hasClient, ...rest } = request;
    const valuesToSubmit = removeAllFieldsWithZero(rest);
    return {
        ...valuesToSubmit,
        ...(hasClient !== 0 && { hasClient: hasClient === 1 }),
        ...(typeof expirationDateStart !== "undefined" && { expirationDateStart: expirationDateStart.toISOString().substr(0, 10) }),
        ...(typeof expirationDateEnd !== "undefined" && { expirationDateEnd: expirationDateEnd.toISOString().substr(0, 10) }),
    };
}

type requestWithoutDates = Omit<ISearchFormInitialValues, "expirationDateStart" | "expirationDateEnd" | "hasClient">;
//@ts-ignore
const removeAllFieldsWithZero = (request: requestWithoutDates): requestWithoutDates => Object.fromEntries(Object.entries(request).filter(([_, v]) => String(v).length > 0 && String(v) !== "0"))