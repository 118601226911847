/** @jsx jsx */
import { useContext, useState } from "react";
import { css, jsx } from "@emotion/react";

import { Formik, Form, Field } from "formik";

import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { AppContext } from "../../../store";

import { saveClient } from "../../../domain/client/actions/saveClient";
import { editClient } from "../../../domain/client/actions/edit";
import { AddressAutocomplete } from "../../../components/shared/google-autocomplete";
import { createClientFormSchema, formInitialValues } from './create-form-schema';
import { FormFieldBoxItem, getErrorText, hasError } from "../../../components/shared/form-utils";
import { FIELD_FLOOR_LENGTH, FIELD_HYDRANTS_QTY_LENGTH, FIELD_LENGTH_CITY, FIELD_LENGTH_COMMENTS, FIELD_LENGTH_CONTACT, FIELD_LENGTH_DOCUMENT, FIELD_LENGTH_DOOR, FIELD_LENGTH_EMAIL, FIELD_LENGTH_FANTASY_NAME, FIELD_LENGTH_HOURS, FIELD_LENGTH_NAME, FIELD_LENGTH_NEIGHBORHOOD, FIELD_LENGTH_PHONE, FIELD_LENGTH_PHONE_AREA, FIELD_LENGTH_STATE, FIELD_LENGTH_STREET, FIELD_LENGTH_TEL3, FIELD_LENGTH_WEB, FIELD_LENGTH_ZIP, FIELD_UNIT_LENGTH, restrictNumberOnly } from "../../../utils/form.utils";
import { LoadingButton } from "@mui/lab";
import { TimePicker } from "../../../components/shared/time-picker";
interface ClientCreateEditForm {
  documentTypes: documento.UIModel[];
  ivaTypes: iva.UIModel[];
  sellerTypes: seller.UIModel[];
  industryTypes: industry.UIModel[];
  onSaveSuccess: (arg: number) => void;
  initialValues?: ClientCreateEditFormValues;
}

export type ClientCreateEditFormValues = backend.client.EditClientRequest & { id?: string };
export const ClientCreateEditForm = ({
  documentTypes,
  ivaTypes,
  sellerTypes,
  industryTypes,
  onSaveSuccess,
  initialValues
}: ClientCreateEditForm) => {
  const {
    client: clientState,
    status: statusTypes } = useContext(AppContext);
  const [autocompleteInitialValue, setAutocompleteInitialValue] = useState("");

  const isEditing = typeof initialValues !== "undefined";
  const actionFn = isEditing ? editClient : saveClient;
  const _formInitialValues = isEditing ? initialValues : formInitialValues;

  
  return (
    <Formik
      initialValues={_formInitialValues}
      validationSchema={createClientFormSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        //@ts-ignore
        actionFn(values)(clientState.dispatch)
          .then((clientId) => onSaveSuccess(clientId as number))
          .finally(() => setSubmitting(false));
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
        setFieldValue,
        setFieldTouched,
        dirty
        /* and other goodies */
      }) => {
        const formikProps = (name: string): StandardTextFieldProps => ({
          variant: "standard",
          name,
          //@ts-ignore
          value: values[name],
          error: hasError(touched, errors, name),
          helperText: getErrorText(errors, name),
          onChange: handleChange(name),
          onBlur: handleBlur(name)
        });
        return (
          <Form css={css`
          display: grid;
          grid-template-areas:
              'name name fantasy'
              'audit hydrantQty hydrantDate'
              'iva doc-id doc'
              'autocomplete autocomplete autocomplete'
              'street1 street1 door1'
              'floor1 unit1 zip1'
              'neighborhood1 neighborhood1 neighborhood1'
              'city1 city1 city1'
              'state1 state1 state1'
              'industry industry seller'
              'email email web'
              'contact contact contact'
              'hours hours hours'
              'tel1area tel1num tel1num'
              'tel2area tel2num tel2num'
              'tel3 tel3 tel3'
              'comments comments comments'
              'status status status'
              'buttons buttons buttons'
          `}>
            <FormFieldBoxItem style={{ gridArea: 'name' }}>
              <TextField
                type="text"
                inputProps={{ maxLength: FIELD_LENGTH_NAME }}
                placeholder="Ingrese Razon Social"
                label="Razon Social"
                {...formikProps('name')}
                autoFocus />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'fantasy' }}>
              <TextField
                type="text"
                inputProps={{ maxLength: FIELD_LENGTH_FANTASY_NAME }}
                placeholder="Ingrese Nombre Fantasia"
                label="Nombre Fantasia"
                {...formikProps('fantasy')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'audit' }}>
              <TextField
                select
                label="Auditoria"
                {...formikProps('audit')} >
                <MenuItem value={"false"}>
                  NO
                </MenuItem>
                <MenuItem value={"true"}>
                  SI
                </MenuItem>
              </TextField>
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'hydrantQty' }}>
              <TextField
                type="tel"
                inputProps={{ maxLength: FIELD_HYDRANTS_QTY_LENGTH }}
                placeholder="Ingrese cantidad"
                label="Hidrantes Cantidad"
                {...formikProps('hydrants_quantity')}
                onKeyPress={restrictNumberOnly} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'hydrantDate' }}>
              <Field
                component={TimePicker}
                label="Hidrantes Fecha Vencimiento"
                {...formikProps("hydrants_expiration_date")}
              />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'iva' }}>
              <TextField
                select
                label="IVA"
                {...formikProps('iva_id')}  >
                {ivaTypes.map((e) => (
                  <MenuItem key={`iva_${e.id}`} value={e.id}>
                    {e.desc}
                  </MenuItem>
                ))}
              </TextField>
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'doc-id' }}>
              <TextField
                select
                label="Tipo Documento"
                {...formikProps('document_id')} >
                {documentTypes.map((e) => (
                  <MenuItem key={`doc_${e.id}`} value={e.id}>
                    {e.desc}
                  </MenuItem>
                ))}
              </TextField>
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'doc' }}>
              <TextField
                type="tel"
                inputProps={{ maxLength: FIELD_LENGTH_DOCUMENT }}
                label="Documento"
                {...formikProps('document')}
                onKeyPress={restrictNumberOnly} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'autocomplete' }}>
              <div style={{
                margin: '1rem 1rem 1rem 0',
                border: '1px solid black',
                height: '4rem',
                padding: '1rem'
              }}>
                <AddressAutocomplete
                  initialValue={autocompleteInitialValue}
                  onChangeEvent={(value: string) => setAutocompleteInitialValue(value)}
                  onPlaceSelect={(values: any) => {
                    console.info('AddressAutocomplete values:', { values })
                    setFieldValue('state1_name', values.stateName)
                    setFieldValue('city1_name', values.cityName)
                    setFieldValue('street1_name', values.streetName)
                    setFieldValue('neighborhood1_name', values.neighborhoodName)
                    setFieldValue('door1', values.streetNumber)
                    setFieldValue('zip1', values.zipCode)
                    setFieldValue('lat1', values.lat)
                    setFieldValue('lng1', values.lng)
                  }} />
              </div>
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'street1' }}>
              <TextField
                type="text"
                disabled
                inputProps={{ maxLength: FIELD_LENGTH_STREET }}
                label="Calle"
                {...formikProps('street1_name')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'door1' }}>
              <TextField
                type="text"
                disabled
                inputProps={{ maxLength: FIELD_LENGTH_DOOR }}
                label="Puerta"
                {...formikProps('door1')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'floor1' }}>
              <TextField
                type="text"
                inputProps={{ maxLength: FIELD_FLOOR_LENGTH }}
                label="Piso"
                {...formikProps('floor1')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'unit1' }}>
              <TextField
                type="text"
                inputProps={{ maxLength: FIELD_UNIT_LENGTH }}
                label="Dpto"
                {...formikProps('unit1')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'neighborhood1' }}>
              <TextField
                type="text"
                inputProps={{ maxLength: FIELD_LENGTH_NEIGHBORHOOD }}
                label="Barrio"
                {...formikProps("neighborhood1_name")} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'city1' }}>
              <TextField
                type="text"
                disabled
                inputProps={{ maxLength: FIELD_LENGTH_CITY }}
                label="Ciudad"
                {...formikProps('city1_name')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'state1' }}>
              <TextField
                type="text"
                disabled
                inputProps={{ maxLength: FIELD_LENGTH_STATE }}
                label="Provincia"
                {...formikProps('state1_name')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'zip1' }}>
              <TextField
                type="text"
                inputProps={{ maxLength: FIELD_LENGTH_ZIP }}
                label="Codigo Postal"
                {...formikProps('zip1')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ display: 'none' }}>
              <TextField
                type="number"
                disabled
                label="Lat"
                {...formikProps('lat1')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ display: 'none' }}>
              <TextField
                type="number"
                disabled
                label="Long"
                {...formikProps('lng1')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'industry' }}>
              <TextField
                select
                label="Rubro"
                {...formikProps('industry_id')} >
                {industryTypes.map((e) => (
                  <MenuItem key={`ind_${e.id}`} value={e.id}>
                    {e.desc}
                  </MenuItem>
                ))}
              </TextField>
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'seller' }}>
              <TextField
                select
                label="Vendedor"
                {...formikProps('seller_id')} >
                {sellerTypes.map((e) => (
                  <MenuItem key={`sel_${e.id}`} value={e.id}>
                    {e.desc}
                  </MenuItem>
                ))}
              </TextField>
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'email' }}>
              <TextField
                type="email"
                inputProps={{ maxLength: FIELD_LENGTH_EMAIL }}
                placeholder="Ingrese email"
                label="Email"
                {...formikProps('email')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'web' }}>
              <TextField
                type="url"
                inputProps={{ maxLength: FIELD_LENGTH_WEB }}
                placeholder="http://www..."
                label="Pagina Web"
                {...formikProps('web')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'contact' }}>
              <TextField
                type="text"
                inputProps={{ maxLength: FIELD_LENGTH_CONTACT }}
                placeholder="Ingrese contacto"
                label="Contacto"
                {...formikProps('contact')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'hours' }}>
              <TextField
                type="text"
                inputProps={{ maxLength: FIELD_LENGTH_HOURS }}
                placeholder="Ingrese horarios"
                label="Horarios"
                {...formikProps('hours')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'tel1area' }}>
              <TextField
                type="tel"
                inputProps={{ maxLength: FIELD_LENGTH_PHONE_AREA }}
                placeholder="Ingrese codigo de area. Ejemplo: 11"
                label="Tel1 area"
                {...formikProps('tel1_area')}
                onKeyPress={restrictNumberOnly} />
            </FormFieldBoxItem>
            <FormFieldBoxItem style={{ gridArea: 'tel1num' }}>
              <TextField
                type="tel"
                inputProps={{ maxLength: FIELD_LENGTH_PHONE }}
                placeholder="Ingrese numero"
                label="Tel1 numero"
                {...formikProps('tel1_num')}
                onKeyPress={restrictNumberOnly} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'tel2area' }}>
              <TextField
                type="tel"
                inputProps={{ maxLength: FIELD_LENGTH_PHONE_AREA }}
                placeholder="Ingrese codigo de area. Ejemplo: 223"
                label="Tel2 area"
                {...formikProps('tel2_area')}
                onKeyPress={restrictNumberOnly} />
            </FormFieldBoxItem>
            <FormFieldBoxItem style={{ gridArea: 'tel2num' }}>
              <TextField
                type="tel"
                inputProps={{ maxLength: FIELD_LENGTH_PHONE }}
                placeholder="Ingrese numero"
                label="Tel2 numero"
                {...formikProps('tel2_num')}
                onKeyPress={restrictNumberOnly} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'tel3' }}>
              <TextField
                type="tel"
                inputProps={{ maxLength: FIELD_LENGTH_TEL3 }}
                placeholder="Ingrese numero de telefono"
                label="Telefono 3"
                {...formikProps('tel3')}
                onKeyPress={restrictNumberOnly} />
            </FormFieldBoxItem>

            <FormFieldBoxItem style={{ gridArea: 'comments' }}>
              <TextField
                type="text"
                multiline
                rows={5}
                inputProps={{ maxLength: FIELD_LENGTH_COMMENTS }}
                placeholder="Ingrese comentarios"
                label="Comentarios"
                {...formikProps('comments')} />
            </FormFieldBoxItem>

            {
              isEditing && (<FormFieldBoxItem style={{ gridArea: 'status' }}>
                <TextField
                  select
                  label="Estado"
                  {...formikProps("statusId")}
                >
                  {Object.entries(statusTypes).map((e) => (
                    <MenuItem key={`status_${e[0]}`} value={e[0]}>
                      {e[1]}
                    </MenuItem>
                  ))}
                </TextField>
              </FormFieldBoxItem>)
            }

            <FormFieldBoxItem style={{ gridArea: 'buttons' }}>
              <Stack direction="row" spacing={2}>
                <LoadingButton variant="contained"
                  loading={isSubmitting}
                  type="submit"
                  disabled={isEditing && !dirty}
                >
                  {isEditing ? "Modificar" : "Agregar"}
                </LoadingButton>
                <Button variant="contained" color="secondary"
                  onClick={() => {
                    resetForm(_formInitialValues);
                    setAutocompleteInitialValue("");
                  }}
                  type="button"
                >Reset</Button>
              </Stack>
            </FormFieldBoxItem>
          </Form>
        );
      }}
    </Formik >
  );
};