import React, { useState } from "react";

import { Field, Form, Formik } from "formik";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { TimePicker } from "../../../components/shared/time-picker";

import { Notification } from "../../../components/shared/notification";

import { formInitialValues, formValidationSchema } from "./form-schema";
import { FormFieldBoxItem, getErrorText, hasError } from "../../../components/shared/form-utils";
import { StandardTextFieldProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface IExtinguisherMassiveChangeExpirationDateForm {
    onSubmit: Function;
    onReset: Function;
    extinguishers: Array<extinguisher.UIModel>
}

export const ExtinguisherMassiveChangeExpirationDateForm: React.FunctionComponent<IExtinguisherMassiveChangeExpirationDateForm> = ({ onSubmit, onReset, extinguishers }) => {
    const [showExtinguishersMissing, setShowExtinguishersMissing] = useState<undefined | boolean>(undefined);

    return (
        <Formik initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                if (extinguishers.length === 0) {
                    setSubmitting(false);
                    setShowExtinguishersMissing(true);
                    return;
                }
                setShowExtinguishersMissing(false);
                setSubmitting(true);

                onSubmit(values.expirationDate)
                    .finally(() => setSubmitting(false))
            }
            }
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
                setFieldValue,
                setFieldTouched,
                /* and other goodies */
            }) => {
                console.log("gon global form error", { errors }, { values });
                const formikProps = (name: string): StandardTextFieldProps => ({
                    variant: "standard",
                    name,
                    //@ts-ignore
                    value: values[name],
                    error: hasError(touched, errors, name),
                    helperText: getErrorText(errors, name),
                    onChange: handleChange(name),
                    onBlur: handleBlur(name),
                    autoComplete: "off"
                });
                return (
                    <Form name="expiration">
                        {showExtinguishersMissing && <Notification warn msg='Seleccione mas de un extintor para cambiar la fecha de vencimiento!' title='Sin extintores' />}

                        <FormFieldBoxItem>
                            <Field
                                component={TimePicker}
                                label="Fecha Vencimiento"
                                {...formikProps("expirationDate")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem>
                            <Stack direction="row" spacing={2}>
                                <LoadingButton variant="contained"
                                    loading={isSubmitting}
                                    disabled={extinguishers.length === 0}
                                    type="submit"
                                >
                                    Cambiar Fecha
                                </LoadingButton>
                                <Button variant="contained" color="secondary"
                                    onClick={() => {
                                        resetForm();
                                        setShowExtinguishersMissing(false);
                                        onReset();
                                    }}
                                    type="button"
                                >Reset</Button>
                            </Stack>
                        </FormFieldBoxItem>
                    </Form>
                )
            }}
        </Formik>
    )
}