export enum ExtinguisherCapacityActionType {
  LOAD_INIT = "@extinguisher-capacity/LOAD_INIT",
  LOAD_SUCCESS = "@extinguisher-capacity/LOAD_SUCCESS",
  LOAD_FAILURE = "@extinguisher-capacity/LOAD_FAILURE"
}

export type ExtinguisherCapacityAction =
  | { type: ExtinguisherCapacityActionType.LOAD_INIT }
  | {
      type: ExtinguisherCapacityActionType.LOAD_SUCCESS;
      payload: backend.extCapacityNS.LoadAllResponseSuccess;
    }
  | { type: ExtinguisherCapacityActionType.LOAD_FAILURE };
