import React, { useContext, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@mui/material";

import { AppContext } from "../../../store";
import Loader from "../../../components/shared/loader";
import { SearchExtinguisherBySerieForm } from "../search/by-serie/form";
import { Notification } from "../../../components/shared/notification";
import { GoBackButton } from "../../../components/shared/go-back";
import { changeExpirationDate } from "../../../domain/extinguisher/actions/edit-massive";

import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { ErrorNotification } from "../../../components/shared/error";
import { ExtinguishersListPreview } from "../components/list-preview";
import { ExtinguisherMassiveChangeExpirationDateForm } from "./form";
import { PageTitle } from "../../../components/shared/page-title";
import { isManufactureDateExpired } from "../../../utils/extinguisher.utils";
import { GenericBoxItem } from "../../../components/shared/card-box-container";


export default function ({ match, history, location }: RouteComponentProps) {
    const { extinguisher: extinguisherState } = useContext(AppContext);

    const [extinguishers, setExtinguishers] = useState<Array<extinguisher.UIModel>>([]);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);

    const removeExtinguisher = (extinguisherSerialNumber: string) => setExtinguishers(extinguishers.filter(e => e.serialNumber !== extinguisherSerialNumber));

    const warningMessages: Record<string, { title: string, desc: string }> = {
        'DUPLICATE': { title: 'Duplicado', desc: 'El extintor que intenta agregar ya se encuentra en la lista.' },
        'NOT_FOUND': { title: 'Extintor faltante', desc: 'El extintor buscado no se encuentra. Intente nuevamente.' },
        'EXPIRED_MANUFACTURE_DATE': { title: 'Fecha de Vencimiento Alcanzada', desc: 'El extintor ha superado su vida util.' }
    }
    const getWarningMessage = (code: string) => warningMessages[code]?.desc || 'Error Desconocido';
    const getWarningTitle = (code: string) => warningMessages[code]?.title || 'Error Desconocido';

    return (
        <>
            <PageTitle title={"Cambiar Fecha de Vencimiento"}></PageTitle>

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>

                <GenericBoxItem>
                    <h3>Cambiar Fecha de Vencimiento</h3>
                </GenericBoxItem>

                {extinguisherState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
                {extinguisherState.error && <GenericBoxItem><ErrorNotification error={extinguisherState.error} /></GenericBoxItem>}

                {!extinguisherState.loading && (
                    <>
                        <GenericBoxItem>
                            <ExtinguisherMassiveChangeExpirationDateForm
                                extinguishers={extinguishers}
                                onReset={() => { setExtinguishers([]); setWarningMessage(undefined); }}
                                onSubmit={(expirationDate: Date) => {
                                    setWarningMessage(undefined);
                                    return changeExpirationDate({
                                        expirationDate,
                                        extinguisherIds: extinguishers.map(({ serialNumber }) => (serialNumber))
                                    })(extinguisherState.dispatch)
                                        .then((_: any) => history.push('/success', { operation: 'EXT_UPDATE_EXP_DATE' }))
                                }} />

                            {warningMessage && <Notification warn title={getWarningTitle(warningMessage)} msg={getWarningMessage(warningMessage)} />}

                            <SearchExtinguisherBySerieForm onSuccess={(extinguisher: extinguisher.UIModel) => {
                                setWarningMessage(undefined);

                                const isFound = extinguishers.find(e => e.serialNumber === extinguisher.serialNumber);

                                if (!isFound) {
                                    if (isManufactureDateExpired(extinguisher)) {
                                        setWarningMessage('EXPIRED_MANUFACTURE_DATE');
                                        return;
                                    }
                                    setExtinguishers([extinguisher, ...extinguishers])
                                } else {
                                    setWarningMessage('DUPLICATE')
                                }
                            }}
                                onError={() => setWarningMessage('NOT_FOUND')}
                                onReset={() => setWarningMessage(undefined)} />

                            <ExtinguishersListPreview list={extinguishers}
                                onDelete={(id) => { removeExtinguisher(id) }}
                            />

                        </GenericBoxItem>

                        <GenericBoxItem>
                            <GoBackButton history={history} />
                        </GenericBoxItem>
                    </>
                )}
            </Box>
            <ScrollToTop />
        </>
    )
}