import React, { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box } from "@mui/material";

import { AppContext } from "../../../store";
import Loader from "../../../components/shared/loader";
import { searchClientsByAdministrator } from "../../../domain/administrator/actions/searchClientsByAdministrator";
import {
  getAdministratorClients,
  getAdministratorName
} from "../../../domain/administrator/selectors";
import { AdministratorClientItem } from "./components/administrator-client-item";
import { ErrorNotification } from "../../../components/shared/error";
import { PageTitle } from "../../../components/shared/page-title";
import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { GoBackButton } from "../../../components/shared/go-back";
import { Notification } from "../../../components/shared/notification";
import { GenericBoxItem } from "../../../components/shared/card-box-container";

interface AdministratorClientsSearchResultsProps {
  administratorId: string;
  administratorDesc?: string;
}
const renderResults = (results: Array<administrator.AdministratorClient>) =>
  results.length > 0 ? (
    <p>
      {results.map((client, id) => (
        <AdministratorClientItem key={`cli-${id}`} client={client} />
      ))}
    </p>
  ) : (
    <Notification warn title="No hay resultado" msg="La administracion solicitada no posee clientes asociados" />
  );

export const AdministratorClientsSearchResults = ({
  match,
  history
}: RouteComponentProps<AdministratorClientsSearchResultsProps>) => {
  const { administrator: administratorState, ui: uiState } = useContext(
    AppContext
  );

  useEffect(() => {
    const adminId = Number(match.params.administratorId);
    searchClientsByAdministrator({
      adminId,
      adminName: getAdministratorName(administratorState, adminId)
    })(administratorState.dispatch, uiState.dispatch);
  }, [match.params.administratorId, uiState.dispatch]);

  if (administratorState.loading) return <Loader />;

  const adminName = uiState.administrator.searchClients.adminName
    ? uiState.administrator.searchClients.adminName
    : "";

  return (
    <>
      <PageTitle title={"Listado de Consorcios"}></PageTitle>

      <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>
        <GenericBoxItem>
          <h3>Listado de Consorcios</h3>
          <h4>Administracion: {adminName}</h4>
        </GenericBoxItem>


        {administratorState.error && (
          <GenericBoxItem>
            <ErrorNotification error={administratorState.error} />
          </GenericBoxItem>
        )}

        {!administratorState.error && (
          <GenericBoxItem>
            {renderResults(
              getAdministratorClients(
                administratorState,
                Number(match.params.administratorId)
              )
            )}

          </GenericBoxItem>
        )}

        <GenericBoxItem>
          <GoBackButton history={history} />
        </GenericBoxItem>
      </Box>
      <ScrollToTop />
    </>
  );
};