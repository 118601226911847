import { ExtinguisherActionType, ExtinguisherAction } from "./types";
import { doCall } from "../../../utils/apiCall";

const editMassiveSuccess = (): ExtinguisherAction => ({
    type: ExtinguisherActionType.EDIT_MASSIVE_SUCCESS,
});
const editMassiveFailure = (error: backend.common.ErrorResponse): ExtinguisherAction => ({
    type: ExtinguisherActionType.EDIT_MASSIVE_FAILURE,
    payload: error
});
const editMassiveInit = (): ExtinguisherAction => ({
    type: ExtinguisherActionType.EDIT_MASSIVE_INIT
});

export const changeExpirationDate = (request: backend.extinguisherNS.ChangeExpiratioDateRequest) => (dispatch: React.Dispatch<any>) => {
    dispatch(editMassiveInit());
    return doCall({
        url: `/api/extinguisher`,
        method: "PATCH",
        payload: {
            ...request,
            operation: 'exp-date',
        }
    })
        .then((_) => {
            dispatch(editMassiveSuccess());
            return Promise.resolve()
        })
        .catch((error: backend.common.ErrorResponse) => {
            dispatch(editMassiveFailure(error));
            return Promise.reject(error)
        });
};

export const linkCustomer = (request: backend.extinguisherNS.PatchExtinguisherRequest) => (dispatch: React.Dispatch<any>) => {
    dispatch(editMassiveInit());
    return doCall({
        url: `/api/extinguisher/${request.serialNumber}`,
        method: "PATCH",
        payload: removeAllEmptyFields({
            clientId: request.clientId,
        }) // remove serialNumber
    })
        .then((_) => {
            dispatch(editMassiveSuccess());
        })
        .catch((error: backend.common.ErrorResponse) => {
            dispatch(editMassiveFailure(error));
            return Promise.reject(error)
        });
};

const removeAllEmptyFields = (request: object) => Object.fromEntries(Object.entries(request).filter(([_, v]) => String(v).length > 0))