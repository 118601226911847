import { SellerAction, SellerActionType } from "../actions/types";

export interface SellerState {
  all: Array<seller.UIModel>;
  loading: boolean;

  error?: backend.common.ErrorResponse;
  dispatch?: any;
}
export default (state: SellerState, action: SellerAction): SellerState => {
  switch (action.type) {
    case SellerActionType.LOAD_SUCCESS: {
      return {
        ...state,
        all: action.payload.sort((a, b) => a.desc.toUpperCase() < b.desc.toUpperCase() ? -1 : 1),
        loading: false,
        error: undefined
      };
    }

    case SellerActionType.LOAD_INIT:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case SellerActionType.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
};
