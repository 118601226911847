import React from "react";
import { GlobalState } from "./index.d";

export const initialState: GlobalState = {
  client: {
    searchResults: {},
    filterResults: [],
    loading: false,
    extinguishers: {
      list: {
        all: {},
        byType: {}
      },
      loading: false
    },
    quotes: []
  },
  extinguisher: {
    loading: false,
    searchResults: []
  },
  seller: {
    all: [],
    loading: false
  },
  industry: {
    all: [],
    loading: false
  },
  iva: {
    all: [],
    loading: false
  },
  document: {
    all: [],
    loading: false
  },
  product: {
    all: [],
    loading: false
  },
  administrator: {
    all: [],
    loading: false
  },
  status: {
    1: "ACTIVO",
    2: "INACTIVO"
  },
  extinguisherBrands: {
    all: {},
    loading: false
  },
  extinguisherCapacities: {
    all: {},
    loading: false
  },
  extinguisherTypes: {
    all: {},
    loading: false
  },
  extinguisherServices: {
    all: {
      clients: []
    },
    loading: false
  },
  extinguisherAudits: {
    loading: false,
    all: []
  },
  ui: {
    administrator: {
      searchClients: {}
    }
  },
  user: {
    loading: false
  }
};

export const AppContext = React.createContext<GlobalState>(initialState);
