import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { AppContext } from "../../../../store";

import { ClientAddress } from "../../../client-search/components/client-address";
import { ClientPhoneNumbers } from "../../../client-search/components/client-phone-numbers";

type AdministratorClientItemProps = {
  client: administrator.SearchClientsByAdministratorUIModel;
};
export const AdministratorClientItem: React.FunctionComponent<AdministratorClientItemProps> = ({
  client
}) => {
  const { id, name, address, tel1_area, tel1_num, tel2_area, tel2_num, status, fantasyName } = client;

  const cardClass = classnames("card mb-3", {
    "border-danger": status === 2
  });
  const stateClass = classnames({
    "text-danger": status === 2
  });
  const { status: clientStatus } = useContext(AppContext);
  const phones = {
    tel1_area, tel1_num, tel2_area, tel2_num
  }
  return (
    <Fragment>
      <div className={cardClass}>
        <h5 className="card-header text-uppercase">
          [{id}] {name}
          {fantasyName && <div className="fantasy">({fantasyName})</div>}
        </h5>
        <div className="card-body">
          <div className="card-text">
            <ClientPhoneNumbers {...phones} />
          </div>
          <div className="card-text">
            <ClientAddress address={address} />
          </div>
          <div className="card-text">
            <div className="row">
              <div className="card-text col-6"></div>
              <div className={`card-text col-6 text-right ${stateClass}`}>
                {clientStatus[status]}
              </div>
            </div>
          </div>

          <Link to={`/client/details/${id}`} className="btn btn-primary">
            Ver Detalles
          </Link>
        </div>
      </div>
    </Fragment>
  );
};
