import { format, parseISO } from "date-fns";

// TODO: use memo
export const formatAmount = (amount: number): string => {
  //TODO: nodev13 fix , and . order
  return new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    currencyDisplay: "narrowSymbol"
  }).format(amount);
};

// TODO: use memo
export const formatDate = (date: string): string => {
  return format(parseISO(date), "dd/MM/yyyy");
};
