import React from "react";
import { CardBoxContainer } from "../../../components/shared/card-box-container";
interface ExtinguisherGenericItemType {
  item:
  | extinguisherBrand.UIModel
  | extinguisherType.UIModel
  | extinguisherCapacity.UIModel;
}
export const ExtinguisherGenericItem: React.FunctionComponent<ExtinguisherGenericItemType> = ({
  item
}) => {
  return (
    <CardBoxContainer>
      <h5 className="card-header text-uppercase">
        {item.desc}
      </h5>
    </CardBoxContainer>
  );
};
