import React, { useContext, Fragment, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@mui/material";

import { AppContext } from "../../../store";
import Loader from "../../../components/shared/loader";

import { PageTitle } from "../../../components/shared/page-title";
import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { GoBackButton } from "../../../components/shared/go-back";
import { ExtinguisherCreateEditForm, ExtinguisherCreateEditFormValues } from "../create/create-form";
import { ErrorNotification } from "../../../components/shared/error";
import { useEffect } from "react";
import { getExtinguisherBySerialNumber } from "../../../domain/extinguisher/reducers";
import { GenericBoxItem } from "../../../components/shared/card-box-container";

interface MatchParams {
    id: string;
}
export const ExtinguisherEdit = ({ match, history }: RouteComponentProps<MatchParams>) => {
    const {
        extinguisherBrands: extinguisherBrandsState,
        extinguisherCapacities: extinguisherCapacitiesState,
        extinguisherTypes: extinguisherTypesState,
        extinguisher: extinguisherState
    } = useContext(AppContext);

    const [model, setModel] = useState<undefined | backend.extinguisherNS.EditExtinguisherRequest>(undefined);

    useEffect(() => {
        const serialNumberRequested = match.params?.id;
        const extinguisherRequested = getExtinguisherBySerialNumber(extinguisherState, serialNumberRequested);
        const isFound = typeof extinguisherRequested !== "undefined";
        if (!isFound) {
            history.push('/extinguisher/search');
            return;
        }

        const { serialNumber, brand, capacity, type, manufactureDate, clientId, expireDate, status } = extinguisherRequested as extinguisher.UIModel;
        const transformedModel: ExtinguisherCreateEditFormValues = {
            serie: serialNumber,
            brandId: brand,
            capacityId: capacity,
            typeId: type,
            manufactureMonth: manufactureDate.month,
            manufactureYear: manufactureDate.year,
            clientId: clientId,
            expirationDate: expireDate.date,
            statusId: status
        };
        setModel(transformedModel)
    }, [match.params, history, extinguisherState])

    type extinguisherT = extinguisherBrand.UIModel | extinguisherCapacity.UIModel | extinguisherType.UIModel;
    const sortByDesc = (a: extinguisherT, b: extinguisherT) => a.desc.toUpperCase() < b.desc.toUpperCase() ? -1 : 1;
    const sortedBrands = Object.values(extinguisherBrandsState.all).sort(sortByDesc);
    const sortedCapacities = Object.values(extinguisherCapacitiesState.all).sort(sortByDesc);
    const sortedTypes = Object.values(extinguisherTypesState.all).sort(sortByDesc);

    return (
        <Fragment>
            <PageTitle title={"Modificar Extintor"}></PageTitle>

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>
                <GenericBoxItem>
                    <h3> Modificar Extintor</h3>
                </GenericBoxItem>

                {extinguisherState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
                {extinguisherState.error && <GenericBoxItem><ErrorNotification error={extinguisherState.error} /></GenericBoxItem>}

                <GenericBoxItem>
                    <ExtinguisherCreateEditForm
                        brandTypes={sortedBrands}
                        capacityTypes={sortedCapacities}
                        typeTypes={sortedTypes}
                        dispatchFn={extinguisherState.dispatch}
                        onSuccess={() => history.push(`/success`, {
                            ...history.location,
                            operation: 'EXT_UPDATE'
                        })}
                        initialValues={model}
                    />
                </GenericBoxItem>

                <GenericBoxItem>
                    <GoBackButton history={history} />
                </GenericBoxItem>

            </Box>
            <ScrollToTop />
        </Fragment>
    );
};