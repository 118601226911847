import React, { useContext, useState } from "react";
import { SearchForm } from "./search-form"
import { RouteComponentProps } from "react-router-dom";
import { Box } from "@mui/material";

import { AppContext } from "../../../store";
import { ExtinguisherSearchResult } from './search-result';
import Loader from "../../../components/shared/loader";
// import {
//     filterClients,
//     resetClientsFilter
// } from "../../domain/client/actions/filter";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorNotification } from "../../../components/shared/error";
import { PageTitle } from "../../../components/shared/page-title";
import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { Notification } from "../../../components/shared/notification";
import { GenericBoxItem } from "../../../components/shared/card-box-container";

export const ExtinguisherSearch = ({ match, history }: RouteComponentProps) => {
    const {
        extinguisherBrands: extinguisherBrandsState,
        extinguisherCapacities: extinguisherCapacitiesState,
        extinguisherTypes: extinguisherTypesState,
        extinguisher: extinguisherState,
        status: statusState
    } = useContext(AppContext);
    // const [clientFilterValue, setClientFilterValue] = useState("");
    const [shouldRender, setShouldRender] = useState(false);

    // const handleFilterChange = (e: any) => {
    //     setClientFilterValue(e.target.value);
    //     filterClients({ text: e.target.value })(extinguisherState.dispatch);
    // };
    // const resetFilters = () => {
    //     setClientFilterValue("");
    //     resetClientsFilter()(extinguisherState.dispatch);
    // };

    const renderSearchResult = () => {
        const areResults = extinguisherState.searchResults.length > 0;
        if (!areResults) {
            return (
                <Notification warn
                    title="No se encontraron resultados"
                    msg="No hay resultados que concuerden con el criterio de busqueda. Modifique el criterio de busqueda e intente nuevamente" />
            )
        }

        return (
            <>
                {/* <div className="form-group"> */}
                {/* <label htmlFor="extinguisher_filter">
                        Filtros (mostrando {extinguisherState.filterResults.length} de{" "}
                        {Object.keys(extinguisherState.searchResults).length})
                    </label> */}
                {/* <input
                        placeholder="Filtrar matafuegos..."
                        className="form-control"
                        type="text"
                        name="extinguisher_filter"
                        value={clientFilterValue}
                        onChange={handleFilterChange}
                    /> */}
                {/* <span
                        style={filterIconStyles}
                        onClick={() => { resetFilters(); setClientFilterValue("") }}
                    >
                        <FontAwesomeIcon icon="times" />
                    </span> */}
                {/* <div className="col-12">
                        <button
                            onClick={resetFilters}
                            type="button"
                            className="btn btn-link"
                        >
                            Borrar Filtros
                        </button>
                    </div> */}
                {/* </div> */}
                {/* {extinguisherState.filterResults.length === 0 && (
                    <div className="alert alert-warning" role="alert">
                        No hay resultados que concuerden con los filtros
                    </div>
                )} */}


                {

                    extinguisherState.searchResults.map((el) => (
                        <ExtinguisherSearchResult key={`ext-${el.serialNumber}`} extinguisher={el}
                            brandTypes={extinguisherBrandsState}
                            capacityTypes={extinguisherCapacitiesState}
                            typeTypes={extinguisherTypesState}
                            onEdit={() => history.push(`/extinguisher/edit/${el.serialNumber}`)} />
                    ))

                }
            </>
        );
    };

    const renderResults = () => {
        if (extinguisherState.loading) return <Loader />;
        if (extinguisherState.error) return <ErrorNotification error={extinguisherState.error} />
        return (
            <>
                <h3>Resultados de Busqueda ({extinguisherState.searchResults.length})</h3>
                {renderSearchResult()}
            </>
        )
    }

    type extinguisherT = extinguisherBrand.UIModel | extinguisherCapacity.UIModel | extinguisherType.UIModel;
    const sortByDesc = (a: extinguisherT, b: extinguisherT) => a.desc.toUpperCase() < b.desc.toUpperCase() ? -1 : 1;
    const sortedBrands = Object.values(extinguisherBrandsState.all).sort(sortByDesc);
    const sortedCapacities = Object.values(extinguisherCapacitiesState.all).sort(sortByDesc);
    const sortedTypes = Object.values(extinguisherTypesState.all).sort(sortByDesc);

    return (
        <>
            <PageTitle title={"Buscar Extintores"}></PageTitle>

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>

                <GenericBoxItem>
                    <h3>Buscar Extintores</h3>
                </GenericBoxItem>

                <GenericBoxItem>
                    <SearchForm
                        brandTypes={sortedBrands}
                        capacityTypes={sortedCapacities}
                        typeTypes={sortedTypes}
                        statusTypes={statusState}
                        dispatchFn={extinguisherState.dispatch}
                        onSuccess={() => setShouldRender(true)}
                    />
                </GenericBoxItem>

                {shouldRender && <GenericBoxItem>{renderResults()}</GenericBoxItem>}

            </Box>
            <ScrollToTop />
        </>
    );
};