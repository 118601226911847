import {
  ExtinguisherCapacityActionType,
  ExtinguisherCapacityAction
} from "./types";
import { doCall } from "../../../../utils/apiCall";

const extinguisherCapacityLoadSuccess = (
  data: backend.extCapacityNS.LoadAllResponseSuccess
): ExtinguisherCapacityAction => ({
  type: ExtinguisherCapacityActionType.LOAD_SUCCESS,
  payload: data
});
const extinguisherCapacityLoadFailure = (): ExtinguisherCapacityAction => ({
  type: ExtinguisherCapacityActionType.LOAD_FAILURE
});
const extinguisherCapacityLoadInit = (): ExtinguisherCapacityAction => ({
  type: ExtinguisherCapacityActionType.LOAD_INIT
});

export const loadExtinguisherCapacities = () => (
  dispatch: React.Dispatch<any>
) => {
  dispatch(extinguisherCapacityLoadInit());
  return doCall({
    url: "/api/extinguisher/capacity"
  })
    .then((data) => {
      dispatch(
        extinguisherCapacityLoadSuccess(
          data as backend.extCapacityNS.LoadAllResponseSuccess
        )
      );
    })
    .catch((_: backend.common.ErrorResponse) => {
      dispatch(extinguisherCapacityLoadFailure());
    });
};
