import React, { Fragment, useContext } from "react";
import styled from "@emotion/styled";
import { Formik, FieldArray, Form } from "formik";
import { Alert, Box, Button, Checkbox, FormControlLabel, MenuItem, StandardTextFieldProps, TextField } from "@mui/material";

import { formValidationSchema, initialValues } from "./clientQuoteFormSchema";
import { isInactive } from "../../../utils/client.utils";
import { ClientQuoteFormProps } from "./types";
import { FormFieldBoxItem, getErrorText, hasError } from "../../../components/shared/form-utils";
import { AppContext } from "../../../store";
import { doQuote } from "../../../domain/client/actions/doQuote";
import { GenericBoxItem } from "../../../components/shared/card-box-container";

const calculateTotal = (
  qty: string | number | undefined,
  amount: string | number | undefined,
  discount: string | number | undefined
): string => {
  if (
    typeof qty === "undefined" ||
    typeof amount === "undefined" ||
    typeof discount === "undefined"
  ) {
    return "0";
  }
  const _qty = typeof qty === "number" ? qty : parseInt(qty);
  const _amount = typeof amount === "number" ? amount : parseFloat(amount);
  const _discount = typeof discount === "number" ? discount : parseInt(discount);

  if (isNaN(_qty) || isNaN(_amount) || isNaN(_discount)) return "0";
  const discountInNumber = (_qty * _amount * _discount) / 100;
  return (_qty * _amount - discountInNumber).toFixed(2);
};

const optionsPreselectedText: string[] = [
  "tarjetas g.c.b.a. incluidas",
  "obleas con sello iram incluidas",
  "repuesto y/o cambio de polvo quimico no incluido",
  "recargas realizadas bajo norma iram 3517-II",
  "marbetes anuales incluidos",
  "extintores en reemplazo incluidos",
  "precios no incluyen IVA"
];

const optionsPreselected: { value: string, label: string }[] = optionsPreselectedText.map((text) => ({
  value: text, label: text
}));

export const ClientQuoteForm: React.FunctionComponent<ClientQuoteFormProps> = ({ isError, products, client, onSubmit, onError }) => {
  const {
    client: clientState } = useContext(AppContext);
  return (
    <Formik
      initialValues={initialValues}
      // enableReinitialize={true}
      validationSchema={formValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        doQuote({
          clientId: client.id,
          quoteDetails: values
        })(clientState.dispatch)
          .then(onSubmit)
          .catch(onError)
          .finally(() => setSubmitting(false));
      }}
      render={({ values, setFieldValue, errors, touched, isSubmitting, handleChange, handleBlur }) => {
        const hasProducts = values.products && values.products.length > 0;

        const formikPropsSingleField = (name: string): StandardTextFieldProps => ({
          variant: "standard",
          name,
          //@ts-ignore
          value: values[name],
          error: hasError(touched, errors, name),
          helperText: getErrorText(errors, name),
          onChange: handleChange(name),
          onBlur: handleBlur(name)
        });
        const formikProps = (idx: number, name: string): StandardTextFieldProps => {
          const fieldName = `products[${idx}].${name}`;
          const errorField = (errors.products ?? [])[idx] || {};
          const touchField = (touched.products ?? [])[idx] || {};

          return {
            variant: "standard",
            name: fieldName,
            //@ts-ignore
            value: values?.products[idx][name],
            error: hasError(touchField, errorField, name),
            helperText: getErrorText(errorField, name),
            onChange: handleChange(fieldName),
            onBlur: handleBlur(fieldName)
          }
        };
        console.log({ values }, { errors }, { touched })
        return (
          <Fragment>
            {isError && (
              <Alert severity="error">La cagaste vieja! Revisa lo que hiciste...</Alert>
            )}
            <Form>
              <Box sx={{ display: 'grid', gridTemplateRows: '1fr' }}>

                {typeof errors.products === "string" && (
                  <GenericBoxItem>
                    <Alert severity="error">{errors.products}</Alert>
                  </GenericBoxItem>
                )}

                <GenericBoxItem sx={{ margin: 0, padding: 0 }}>
                  <GenericBoxItem component={"h5"}>Productos ({values.products.length})</GenericBoxItem>
                  <FieldArray
                    name="products"
                    render={(arrayHelpers) => {
                      return (
                        <Fragment>
                          {hasProducts &&
                            values.products.map((_, index) => {
                              return (
                                <FieldRow key={`product-container_${index}`}>
                                  <Box sx={{
                                    border: '1px solid grey',
                                    display: 'grid',
                                    gridTemplateAreas: `
                                  "prod prod qty"
                                  "amount discount prodTotal"
                                  "deleteButton deleteButton deleteButton"
                                  `,
                                    margin: '0.5rem',
                                    padding: '0.5rem'
                                  }}>
                                    <FormFieldBoxItem style={{ gridArea: 'prod', overflow: 'hidden' }}>
                                      <TextField
                                        select
                                        label="producto"
                                        {...formikProps(index, 'id')}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `products[${index}].id`,
                                            e.target.value
                                          );
                                        }}  >
                                        <MenuItem value={"0"}>
                                          Seleccionar producto
                                        </MenuItem>
                                        {products.map((elem) => (
                                          <MenuItem key={`product_${elem.id}`} value={elem.id}>
                                            {elem.desc}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </FormFieldBoxItem>

                                    <FormFieldBoxItem style={{ gridArea: 'qty' }}>
                                      <TextField
                                        label="Cantidad"
                                        type="number"
                                        {...formikProps(index, 'qty')}
                                        onKeyPress={(e: any) => {
                                          if (e.target.value.length >= 4)
                                            e.preventDefault();
                                        }}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `products[${index}].qty`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `products[${index}].prodTotal`,
                                            calculateTotal(
                                              e.target.value,
                                              values.products[index].amount,
                                              values.products[index].discount
                                            )
                                          );
                                          handleChange(e);
                                        }}
                                      />
                                    </FormFieldBoxItem>

                                    <FormFieldBoxItem style={{ gridArea: 'amount' }}>
                                      <TextField
                                        label="Precio Unitario"
                                        type="number"
                                        {...formikProps(index, 'amount')}
                                        onKeyPress={(e: any) => {
                                          if (e.target.value.length >= 7)
                                            e.preventDefault();
                                        }}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `products[${index}].amount`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `products[${index}].prodTotal`,
                                            calculateTotal(
                                              values.products[index].qty,
                                              e.target.value,
                                              values.products[index].discount
                                            )
                                          );
                                          handleChange(e);
                                        }}
                                      />
                                    </FormFieldBoxItem>

                                    <FormFieldBoxItem style={{ gridArea: 'discount' }}>
                                      <TextField
                                        label="Desc (%)"
                                        type="number"
                                        {...formikProps(index, 'discount')}
                                        onKeyPress={(e: any) => {
                                          if (e.target.value.length >= 3)
                                            e.preventDefault();
                                        }}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `products[${index}].discount`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `products[${index}].prodTotal`,
                                            calculateTotal(
                                              values.products[index].qty,
                                              values.products[index].amount,
                                              e.target.value
                                            )
                                          );
                                          handleChange(e);
                                        }}
                                      />
                                    </FormFieldBoxItem>

                                    <FormFieldBoxItem style={{ gridArea: 'prodTotal' }}>
                                      <TextField
                                        label="Subtotal"
                                        type="number"
                                        disabled
                                        {...formikProps(index, 'prodTotal')}
                                      />
                                    </FormFieldBoxItem>

                                    <Button style={{ gridArea: 'deleteButton' }}
                                      onClick={() => arrayHelpers.remove(index)}>
                                      Eliminar Producto
                                    </Button>
                                  </Box>
                                </FieldRow>
                              );
                            })}

                          <Button
                            disabled={isInactive(client)}
                            variant="text"
                            onClick={() =>
                              arrayHelpers.push({
                                id: "0",
                                qty: "",
                                amount: "",
                                prodTotal: 0,
                                discount: 0
                              })
                            }

                          >
                            Agregar Producto
                          </Button>

                        </Fragment>
                      );
                    }}
                  />
                </GenericBoxItem>

                <GenericBoxItem sx={{ margin: 0, padding: 0 }}>
                  <GenericBoxItem component={"h5"}>Notas</GenericBoxItem>
                  <FieldArray
                    name="notes"
                    render={(arrayHelpers) => {
                      return (
                        <Fragment>
                          {optionsPreselected.map((option, index) => {
                            return (
                              <FormFieldBoxItem
                                key={`option-container_${index}`}>
                                <FormControlLabel
                                  style={{ margin: 0, textTransform: 'capitalize' }}
                                  label={option.label}
                                  control={<Checkbox
                                    name={option.label}
                                    value={option.value}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        arrayHelpers.insert(index, option.value);
                                      } else if ((values?.notes || []).indexOf(option.value) > -1) {
                                        const idx = values?.notes?.indexOf(option.value) as number;
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />}
                                />
                              </FormFieldBoxItem>
                            );
                          })}
                        </Fragment>
                      );
                    }}
                  />
                </GenericBoxItem>

                <GenericBoxItem sx={{ margin: 0, padding: 0 }}>
                  <GenericBoxItem component={"h5"}>Otros</GenericBoxItem>
                  <FormFieldBoxItem>
                    <TextField
                      style={{ width: '100%' }}
                      label="Comentarios"
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 500 }}
                      {...formikPropsSingleField('comments')}
                      onKeyPress={(e: any) => {
                        if (e.key === 'Enter') e.preventDefault();
                      }}
                      placeholder="Ingrese comentarios..."
                    />
                  </FormFieldBoxItem>

                  <FormFieldBoxItem>
                    <TextField
                      label="Validez (dias)"
                      type="number"
                      {...formikPropsSingleField('expirationDays')}
                      onKeyPress={(e: any) => {
                        if (e.target.value.length >= 2) e.preventDefault();
                      }}
                      placeholder="Ingrese validez (en dias)..."
                    />
                  </FormFieldBoxItem>

                </GenericBoxItem>

                <GenericBoxItem sx={{ margin: 0 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={
                      isInactive(client) || isSubmitting || !hasProducts
                    }>
                    PRESUPUESTAR
                  </Button>
                </GenericBoxItem>
              </Box>
            </Form>
          </Fragment >
        );
      }
      }
    ></Formik >
  );
};

const FieldRow = styled.div`
  margin-bottom: 1rem;
`;