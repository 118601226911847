import axios from "axios";
import { doCallOptions, doCallResponseType } from "./types";
import {
  getTokenFromStorage,
  clearTokenFromStorage
} from "../../domain/user/user.util";

export const doCall = async ({
  url = "/no-url",
  payload = {},
  method = "GET",
  headers = { "Content-Type": "application/json" },
  responseType = "json",
  queryParams
}: //@ts-ignore
  doCallOptions): doCallResponseType => {
  const serviceURL = `${process.env.REACT_APP_API_BASE_PATH}${url}`;
  return axios({
    url: serviceURL,
    method,
    headers: {
      ...headers,
      Authorization: `Bearer ${getTokenFromStorage()}`
    },
    data: JSON.stringify(payload),
    ...(queryParams && { params: queryParams }),
    responseType
  })
    .then((response) => response.data)
    .catch(async (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 401) {
          clearTokenFromStorage();
        }

        let returnError = {
          code: error.response.status,
          message: error.response.statusText
        };

        if (responseType === 'blob') {
          const blobToBase64 = async (blob: any) => {
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                resolve(reader.result);
              };
            });
          };

          const b64 = await blobToBase64(error.response.data);
          const jsonString = JSON.stringify({ blob: b64 });
          const base64Data = JSON.parse(jsonString).blob;
          const idx = base64Data.indexOf(",");
          const base64String = base64Data.substring(idx + 1, base64Data.length);
          const jsonResponse = JSON.parse(Buffer.from(base64String, 'base64').toString('ascii'));
          returnError = jsonResponse;
        }
        else if (typeof error.response.data === "object") {
          returnError = error.response.data;
        }

        return Promise.reject(returnError);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      // console.log(error.config);
      return Promise.reject({ code: "UNKNOWN", message: error.message });
    });
};
