import React, { useReducer, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ClientSearchLayout } from "./screens/client-search";
import ClientDetails from "./screens/client-details";
import ClientQuote from "./screens/client-quote/quote";
import ClientQuoteList from "./screens/client-quote/list";

import { ClientExtinguishersServiceSearch } from "./screens/client-extinguishers-service/search";
import { ClientExtinguishersServiceSearchResults } from "./screens/client-extinguishers-service/search-results";

import { ClientExtinguishersAuditSearch } from "./screens/extinguishers-audit/search";
import { ClientExtinguishersAuditSearchResults } from "./screens/extinguishers-audit/search-results";

import ClientExtinguishersLink from './screens/client/link-extinguishers';

import { AdministratorClientsSearch } from "./screens/administrator/clients/search";
import { AdministratorClientsSearchResults } from "./screens/administrator/clients/search-results";
import AdministratorCreate from './screens/administrator/create';
import AdministratorEdit from './screens/administrator/edit';

import { ExtinguisherBrandContainer } from "./screens/extinguisher/brand";
import { ExtinguisherCapacityContainer } from "./screens/extinguisher/capacity";
import { ExtinguisherTypeContainer } from "./screens/extinguisher/type";

import { Home } from "./screens/home";
import { AppContext, initialState } from "./store";

import clientReducer from "./domain/client/reducer/client";
import extinguisherReducer from "./domain/extinguisher/reducer";
import sellerReducer from "./domain/seller/reducer";
import industryReducer from "./domain/industry/reducer";
import ivaReducer from "./domain/iva/reducer";
import documentReducer from "./domain/document/reducer";
import productReducer from "./domain/product/reducer";
import administratorReducer from "./domain/administrator/reducer";
import uiReducer from "./domain/ui/reducer";
import userReducer from "./domain/user/reducer";

import { loadSellers } from "./domain/seller/actions";
import { loadIndustries } from "./domain/industry/actions";
import { loadIvas } from "./domain/iva/actions";
import { loadDocuments } from "./domain/document/actions";
import { loadProducts } from "./domain/product/actions";

import { loadExtinguisherBrands } from "./domain/extinguisher/brand/actions";
import extinguisherBrandsReducer from "./domain/extinguisher/brand/reducer";

import { loadExtinguisherCapacities } from "./domain/extinguisher/capacity/actions";
import extinguisherCapacitiesReducer from "./domain/extinguisher/capacity/reducer";

import { loadExtinguisherTypes } from "./domain/extinguisher/type/actions";
import extinguisherTypesReducer from "./domain/extinguisher/type/reducer";

import extinguisherServicesReducer from "./domain/extinguisher/service/reducer";
import extinguisherAuditsReducer from "./domain/extinguisher/audit/reducer";

import "./App.css";

// font awesome setup
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTimes,
  faChevronDown,
  faChevronUp,
  faBars,
  faWindowClose,
  faPhone,
  faExclamationTriangle,
  faExclamationCircle,
  faQuestion,
  faAt,
  faLocationArrow,
  faClock,
  faIdCard,
  faArrowCircleUp,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";
// font awesome setup

import { loadAdministrators } from "./domain/administrator/actions";
import { IndustryContainer } from "./screens/industry";
import { IvaContainer } from "./screens/iva";
import { SellerContainer } from "./screens/seller";
import { DocumentContainer } from "./screens/document";
import { ProductContainer } from "./screens/product";
import ProductCreateContainer from "./screens/product/create";
import ProductEditContainer from "./screens/product/edit";

import SecureRoute from "./SecureRoute";
import LoginContainer from "./screens/login";
import { AdministratorList } from "./screens/administrator/list/components/administratorList";
import { PageTitle } from "./components/shared/page-title";
import ClientCreate from "./screens/client/create";
import ClientEdit from "./screens/client/edit";

import ExtinguisherCreateScreen from "./screens/extinguisher/create";

import SuccessPage from "./screens/success";

import ExtinguisherMassiveChangeExpirationDate from "./screens/extinguisher/expiration-date-change";

import { ExtinguisherSearch } from "./screens/extinguisher/search";
import { ExtinguisherEdit } from './screens/extinguisher/edit'

import Drawer from './components/shared/header/drawer-menu';
import { Alert } from "@mui/material";

library.add(
  //@ts-ignore
  faTimes,
  faChevronDown,
  faChevronUp,
  faBars,
  faWindowClose,
  faPhone,
  faExclamationTriangle,
  faExclamationCircle,
  faQuestion,
  faAt,
  faLocationArrow,
  faClock,
  faIdCard,
  faArrowCircleUp,
  faFilePdf
);
// font awesome setup

const App = () => {
  const [clientState, dispatch] = useReducer(
    clientReducer,
    initialState.client
  );
  const [extinguisherState, dispatchExtinguisher] = useReducer(
    extinguisherReducer,
    initialState.extinguisher
  );
  const [sellerState, dispatchSeller] = useReducer(
    sellerReducer,
    initialState.seller
  );
  const [industryState, dispatchIndustry] = useReducer(
    industryReducer,
    initialState.industry
  );
  const [ivaState, dispatchIva] = useReducer(ivaReducer, initialState.iva);
  const [documentState, dispatchDocument] = useReducer(
    documentReducer,
    initialState.document
  );
  const [productState, dispatchProduct] = useReducer(
    productReducer,
    initialState.product
  );
  const [administratorState, dispatchAdministrator] = useReducer(
    administratorReducer,
    initialState.administrator
  );

  const [extinguisherBrandsState, dispatchExtinguisherBrands] = useReducer(
    extinguisherBrandsReducer,
    initialState.extinguisherBrands
  );
  const [
    extinguisherCapacitiesState,
    dispatchExtinguisherCapacities
  ] = useReducer(
    extinguisherCapacitiesReducer,
    initialState.extinguisherCapacities
  );
  const [extinguisherTypesState, dispatchExtinguisherTypes] = useReducer(
    extinguisherTypesReducer,
    initialState.extinguisherTypes
  );

  const [extinguisherServicesState, dispatchExtinguisherServices] = useReducer(
    extinguisherServicesReducer,
    initialState.extinguisherServices
  );

  const [extinguisherAuditsState, dispatchExtinguisherAudits] = useReducer(
    extinguisherAuditsReducer,
    initialState.extinguisherAudits
  );

  const [uiState, dispatchUI] = useReducer(uiReducer, initialState.ui);

  const [userState, dispatchUser] = useReducer(userReducer, initialState.user);

  const globalClientState = { ...clientState, dispatch };
  const globalState = {
    ...initialState,
    client: globalClientState,
    seller: {
      ...sellerState,
      dispatchSeller
    },
    industry: {
      ...industryState,
      dispatchIndustry
    },
    iva: {
      ...ivaState,
      dispatchIva
    },
    document: {
      ...documentState,
      dispatchDocument
    },
    extinguisherBrands: {
      ...extinguisherBrandsState,
      dispatchExtinguisherBrands
    },
    extinguisherCapacities: {
      ...extinguisherCapacitiesState,
      dispatchExtinguisherCapacities
    },
    extinguisherTypes: {
      ...extinguisherTypesState,
      dispatchExtinguisherTypes
    },
    extinguisherServices: {
      ...extinguisherServicesState,
      dispatch: dispatchExtinguisherServices
    },
    extinguisherAudits: {
      ...extinguisherAuditsState,
      dispatch: dispatchExtinguisherAudits
    },
    extinguisher: {
      ...extinguisherState,
      dispatch: dispatchExtinguisher
    },
    product: {
      ...productState,
      dispatch: dispatchProduct
    },
    administrator: {
      ...administratorState,
      dispatch: dispatchAdministrator
    },
    ui: {
      ...uiState,
      dispatch: dispatchUI
    },
    user: {
      ...userState,
      dispatch: dispatchUser
    },
  };

  useEffect(() => {
    // console.log("useEffect HOOK!, APP START...", globalState);
    // const client = clientState.searchResults[clientId] || undefined;
    // console.log('useEffect!: clients ', clientState);
    // if (typeof client === 'undefined') {
    //   getClientDetails({ id: clientId })(clientState.dispatch);
    // }
    // FETCH ALL SINGLE CALLS...
    loadSellers()(dispatchSeller);
    loadIndustries()(dispatchIndustry);
    loadIvas()(dispatchIva);
    loadDocuments()(dispatchDocument);
    loadExtinguisherBrands()(dispatchExtinguisherBrands);
    loadExtinguisherCapacities()(dispatchExtinguisherCapacities);
    loadExtinguisherTypes()(dispatchExtinguisherTypes);
    loadAdministrators()(dispatchAdministrator);
    loadProducts()(dispatchProduct);
  }, []);

  return (
    <AppContext.Provider value={globalState}>
      <Router>
        <Drawer />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={LoginContainer} />

          <SecureRoute
            exact
            path="/document"
            component={DocumentContainer}
          />
          <SecureRoute
            exact
            path="/industry"
            component={IndustryContainer}
          />
          <SecureRoute exact path="/seller" component={SellerContainer} />
          <SecureRoute exact path="/iva" component={IvaContainer} />

          <SecureRoute exact path="/product" component={ProductContainer} />
          <SecureRoute exact path="/product/create" component={ProductCreateContainer} />
          <SecureRoute exact path="/product/:id/edit" component={ProductEditContainer} />

          <SecureRoute path="/client/create" component={ClientCreate} />
          <SecureRoute path="/client/:id/edit" component={ClientEdit} />

          <SecureRoute
            path="/client/search"
            component={ClientSearchLayout}
          />
          <SecureRoute
            path="/client/details/:id"
            component={ClientDetails}
          />
          <SecureRoute
            path="/client/extinguishers/audit/:month"
            component={ClientExtinguishersAuditSearchResults}
          />
          <SecureRoute
            path="/client/extinguishers/audit"
            component={ClientExtinguishersAuditSearch}
          />
          <SecureRoute
            path="/client/extinguishers/service/:month/:year"
            component={ClientExtinguishersServiceSearchResults}
          />
          <SecureRoute
            path="/success"
            component={SuccessPage}
          />
          <SecureRoute
            path="/extinguisher/create"
            component={ExtinguisherCreateScreen}
          />
          <SecureRoute
            path="/extinguisher/expiration-date-change"
            component={ExtinguisherMassiveChangeExpirationDate}
          />
          <SecureRoute
            path="/extinguisher/search"
            component={ExtinguisherSearch}
          />
          <SecureRoute
            path="/extinguisher/edit/:id"
            component={ExtinguisherEdit}
          />
          <SecureRoute
            path="/client/extinguishers/service"
            component={ClientExtinguishersServiceSearch}
          />
          <SecureRoute
            path="/extinguisher/brand"
            component={ExtinguisherBrandContainer}
          />
          <SecureRoute
            path="/extinguisher/capacity"
            component={ExtinguisherCapacityContainer}
          />
          <SecureRoute
            path="/extinguisher/type"
            component={ExtinguisherTypeContainer}
          />
          <SecureRoute path="/client/:clientId/quote"
            component={ClientQuote} />

          <SecureRoute
            path="/client/:clientId/quotes"
            component={ClientQuoteList}
          />
          <SecureRoute
            path="/client/:clientId/extinguishers/link"
            component={ClientExtinguishersLink}
          />

          <SecureRoute
            path="/administrator/:administratorId/clients"
            component={AdministratorClientsSearchResults}
          />
          <SecureRoute
            path="/administrator/clients"
            component={AdministratorClientsSearch}
          />

          <SecureRoute path="/administrator/create" component={AdministratorCreate} />
          <SecureRoute
            path="/administrator/edit/:id"
            component={AdministratorEdit}
          />

          <SecureRoute
            path="/administrator"
            component={AdministratorList}
          />

          <Route component={NoMatch} />
        </Switch>
      </Router>
    </AppContext.Provider>
  );
};

const NoMatch = () => (
  <Fragment>
    <PageTitle title="Página no encontrada"></PageTitle>
    <Alert severity="error"><strong>P&aacute;gina no encontrada!</strong></Alert>
  </Fragment>
);

export default App;
