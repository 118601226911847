export enum IvaActionType {
  LOAD_INIT = "@iva/LOAD_INIT",
  LOAD_SUCCESS = "@iva/LOAD_SUCCESS",
  LOAD_FAILURE = "@iva/LOAD_FAILURE"
}

export type IvaAction =
  | { type: IvaActionType.LOAD_INIT }
  | {
      type: IvaActionType.LOAD_SUCCESS;
      payload: iva.UIModel[];
    }
  | { type: IvaActionType.LOAD_FAILURE; payload: backend.common.ErrorResponse };
