import { UserActionType, UserAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import { LoginUserRequest } from "./index.d";
import { setTokenInStorage, clearTokenFromStorage } from "../user.util";

const loginUserSuccess = (
  data: backend.userNS.LoginUserResponseSuccess
): UserAction => ({
  type: UserActionType.LOAD_SUCCESS,
  payload: data
});
const loginUserFailure = (error: backend.common.ErrorResponse): UserAction => ({
  type: UserActionType.LOAD_FAILURE,
  payload: error
});
const loginUserInit = (): UserAction => ({
  type: UserActionType.LOAD_INIT
});

export const loginUser = ({ username, password }: LoginUserRequest) => (
  dispatch: React.Dispatch<any>
): Promise<void> => {
  dispatch(loginUserInit());
  clearTokenFromStorage();
  return doCall({
    url: "/api/user/login",
    method: "POST",
    payload: {
      username,
      password
    }
  })
    .then((data) => {
      dispatch(
        loginUserSuccess(data as backend.userNS.LoginUserResponseSuccess)
      );
      setTokenInStorage(data.token, data.expiresIn);
    })
    .catch((error: backend.common.ErrorResponse) => {
      dispatch(loginUserFailure(error));
      return Promise.reject();
    });
};
