export enum IndustryActionType {
  LOAD_INIT = "@industry/LOAD_INIT",
  LOAD_SUCCESS = "@industry/LOAD_SUCCESS",
  LOAD_FAILURE = "@industry/LOAD_FAILURE"
}

export type IndustryAction =
  | { type: IndustryActionType.LOAD_INIT }
  | {
      type: IndustryActionType.LOAD_SUCCESS;
      payload: industry.UIModel[];
    }
  | {
      type: IndustryActionType.LOAD_FAILURE;
      payload: backend.common.ErrorResponse;
    };
