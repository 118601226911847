import React, { Fragment } from "react";
import { GenericBoxItem } from "../../components/shared/card-box-container";
import { PageTitle } from "../../components/shared/page-title";

export const Home = () => (
  <Fragment>
    <PageTitle title="Home"></PageTitle>
    <GenericBoxItem sx={{ margin: '1rem', textAlign: 'center' }}>
      <img alt="" src="/logo.png" />
    </GenericBoxItem>
    <GenericBoxItem sx={{ margin: '1rem', textAlign: 'center' }}>
      fmatextinguidores@gmail.com
    </GenericBoxItem>
    <GenericBoxItem sx={{ margin: '1rem', textAlign: 'center' }}>
      <a href={"tel:1131750335"}>(+54 11) 3175.0335</a> / <a href={"tel:1141610337"}>(+54 11) 4161.0337</a>
    </GenericBoxItem>
  </Fragment>
);
