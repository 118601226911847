import { ProductAction, ProductActionType } from "../actions/types";

export interface ProductState {
  all: Array<product.UIModel>;
  loading: boolean;

  error?: backend.common.ErrorResponse;
  dispatch?: any;
}
export default (state: ProductState, action: ProductAction): ProductState => {
  switch (action.type) {
    case ProductActionType.LOAD_SUCCESS: {
      return {
        ...state,
        all: action.payload,
        loading: false,
        error: undefined
      };
    }
    case ProductActionType.CREATE_SUCCESS:
    case ProductActionType.EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined
      };
    }

    case ProductActionType.LOAD_INIT:
    case ProductActionType.CREATE_INIT:
    case ProductActionType.EDIT_INIT:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case ProductActionType.LOAD_FAILURE:
    case ProductActionType.CREATE_FAILURE:
    case ProductActionType.EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
};
