import React from "react";
import { RouteComponentProps } from "react-router-dom";
interface GoBackButtonType {
  history: RouteComponentProps["history"];
}
export const GoBackButton: React.FunctionComponent<GoBackButtonType> = ({ history }) => (
  <button
    type="button"
    className="btn btn-info btn-lg btn-block"
    onClick={history.goBack}
  >
    VOLVER
  </button>
);
