import {
  ExtinguisherServiceNeededActionType,
  SearchExtinguisherServiceNeededRequest,
  ExtinguisherServiceNeededAction
} from "./index.d";
import { doCall } from "../../../../utils/apiCall";

const extinguisherServiceNeededSearchSuccess = (
  data: backend.client.SearchExtinguishersServiceNeededResponseSuccess
): ExtinguisherServiceNeededAction => ({
  type: ExtinguisherServiceNeededActionType.SEARCH_SUCCESS,
  payload: data
});
const extinguisherServiceNeededSearchFailure = (
  error: backend.common.ErrorResponse
): ExtinguisherServiceNeededAction => ({
  type: ExtinguisherServiceNeededActionType.SEARCH_FAILURE,
  payload: error
});
const extinguisherServiceNeededSearchInit = (
  month: string,
  year: string
): ExtinguisherServiceNeededAction => ({
  type: ExtinguisherServiceNeededActionType.SEARCH_INIT,
  payload: { month, year }
});

export const searchExtinguisherServiceNeeded = (
  request: SearchExtinguisherServiceNeededRequest
) => (dispatch: React.Dispatch<any>) => {
  dispatch(extinguisherServiceNeededSearchInit(request.month, request.year));
  return doCall({
    url: `/api/extinguisher/service/${request.month}/${request.year}`
  })
    .then((data) => {
      dispatch(
        extinguisherServiceNeededSearchSuccess(
          data as backend.client.SearchExtinguishersServiceNeededResponseSuccess
        )
      );
    })
    .catch((error: backend.common.ErrorResponse) => {
      dispatch(extinguisherServiceNeededSearchFailure(error));
    });
};
