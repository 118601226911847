/** @jsx jsx */

import { useContext, useEffect, Fragment } from "react";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import { Box } from "@mui/material";
import { css, jsx } from "@emotion/react";

import { AppContext } from "../../store";
import Loader from "../../components/shared/loader";

import { searchExtinguisherServiceNeeded } from "../../domain/extinguisher/service/actions/searchExtinguisherServiceNeeded";
import { ClientExtinguishersServiceSearchResultsList } from "./search-results-list";
import { ClientExtinguishersServiceSearchResultsMap } from "./search-results-map";
import { ExtinguisherServiceNeeded } from "../../domain/extinguisher/extinguisher";

import { months } from "../../utils/months";

import { ErrorNotification } from "../../components/shared/error";
import { PageTitle } from "../../components/shared/page-title";
import { ScrollToTop } from "../../components/shared/scroll-to-top";
import { GoBackButton } from "../../components/shared/go-back";
import { PrintButton } from "../../components/shared/print-button";
import { Notification } from "../../components/shared/notification";
import { GenericBoxItem } from "../../components/shared/card-box-container";

interface ClientExtinguishersServiceSearchResultsProps {
  month: string;
  year: string;
}

const renderResults = (fromStore: ExtinguisherServiceNeeded) =>
  fromStore.clients.length > 0 ? (
    <Fragment>
      <ClientExtinguishersServiceSearchResultsList
        clients={fromStore.clients}
      />

      <div className="mt-3">
        <ClientExtinguishersServiceSearchResultsMap
          clients={fromStore.clients}
          map={fromStore.map}
        />
      </div>
    </Fragment>
  ) : (
    <Notification warn title="Sin resultados" msg="No se encontraron vencimientos para el mes solicitado" />
  );
export const ClientExtinguishersServiceSearchResults = ({
  match,
  history
}: RouteComponentProps<ClientExtinguishersServiceSearchResultsProps>) => {
  const { extinguisherServices: extinguisherServicesState } = useContext(
    AppContext
  );

  useEffect(() => {
    searchExtinguisherServiceNeeded({
      month: match.params.month || String(moment().get("month") + 1),
      year: match.params.year || String(moment().get("year"))
    })(extinguisherServicesState.dispatch);
  }, [match.params.month, match.params.year]);

  if (extinguisherServicesState.loading) return <Loader />;
  const periodDesc = extinguisherServicesState.month
    ? `${months[extinguisherServicesState.month - 1]} /
        ${extinguisherServicesState.year}`
    : "";
  return (
    <Fragment>
      <PageTitle title={`Recargas ${periodDesc}`}></PageTitle>

      <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}
        data-name="extinguishers-service"
        css={css`
        @media print {
          & > div {
            padding: 0;
            margin: 0;
          }
        }
        `}>

        <GenericBoxItem>
          <h3>Vencimiento Recargas/Hidrantes</h3>
          <h4>{periodDesc}</h4>
          <PrintButton />
        </GenericBoxItem>

        {extinguisherServicesState.error && (
          <GenericBoxItem>
            <ErrorNotification error={extinguisherServicesState.error} />
          </GenericBoxItem>
        )}
        {!extinguisherServicesState.error && (
          <GenericBoxItem>
            {renderResults(extinguisherServicesState.all)}
          </GenericBoxItem>
        )}
        <GenericBoxItem>
          <GoBackButton history={history} />
        </GenericBoxItem>
      </Box>
      <ScrollToTop />
    </Fragment>
  );
};