import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ClientPhoneNumbersComponent {
  tel1_area: backend.client.GetClientDetailsResponseSuccess["tel1_area"];
  tel1_num: backend.client.GetClientDetailsResponseSuccess["tel1_num"];
  tel2_area: backend.client.GetClientDetailsResponseSuccess["tel2_area"];
  tel2_num: backend.client.GetClientDetailsResponseSuccess["tel2_num"];
}
export const ClientPhoneNumbers: React.FunctionComponent<ClientPhoneNumbersComponent> = ({
  tel1_area, tel1_num, tel2_area, tel2_num
}) => (
  <div className="row">
    {tel1_area && tel1_num &&
      <p className="col-6 col-md-4">
        <FontAwesomeIcon icon="phone" /> {"  "}
        <a href={`tel:${tel1_area}${tel1_num}`}>{`(${tel1_area}) ${tel1_num}`}</a>
      </p>
    }
    {tel2_area && tel2_num &&
      <p className="col-6 col-md-4">
        <FontAwesomeIcon icon="phone" /> {"  "}
        <a href={`tel:${tel2_area}${tel2_num}`}>{`(${tel2_area}) ${tel2_num}`}</a>
      </p>
    }
  </div>
);
