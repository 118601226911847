import React, { useContext, useEffect, Fragment } from "react";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import { Box } from "@mui/material";

import { AppContext } from "../../store";
import Loader from "../../components/shared/loader";

import { getExtinguishersToAudit } from "../../domain/extinguisher/audit/actions/getExtinguishersToAudit";

import { months } from "../../utils/months";
import { ExtinguishersToAuditSearchResultsList } from "./search-results-list";
import { ErrorNotification } from "../../components/shared/error";
import { PageTitle } from "../../components/shared/page-title";
import { ScrollToTop } from "../../components/shared/scroll-to-top";
import { GoBackButton } from "../../components/shared/go-back";
import { PrintButton } from "../../components/shared/print-button";
import { Notification } from "../../components/shared/notification";
import { GenericBoxItem } from "../../components/shared/card-box-container";

interface ClientExtinguishersAuditSearchResultsProps {
  month: string;
}

const renderResults = (results: extinguisher.ClientToAuditUIModel[]) =>
  results.length > 0 ? (
    <ExtinguishersToAuditSearchResultsList results={results} />
  ) : (
    <Notification warn title="Sin resultados!" msg="No se encontraron vencimientos para el mes solicitado" />
  );

export const ClientExtinguishersAuditSearchResults = ({
  match,
  history
}: RouteComponentProps<ClientExtinguishersAuditSearchResultsProps>) => {
  const { extinguisherAudits: extinguisherAuditsState } = useContext(
    AppContext
  );

  useEffect(() => {
    getExtinguishersToAudit({
      month: match.params.month || String(moment().get("month") + 1)
    })(extinguisherAuditsState.dispatch);
  }, [match.params.month, extinguisherAuditsState.dispatch]);

  if (extinguisherAuditsState.loading) return <Loader />;
  const monthName = extinguisherAuditsState.month
    ? months[extinguisherAuditsState.month - 1]
    : "";

  return (
    <Fragment>
      <PageTitle title={`Auditorías ${monthName}`}></PageTitle>

      <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>

        <GenericBoxItem>
          <h3>Vencimiento Auditor&iacute;as</h3>
          <h4>{monthName}</h4>
          <PrintButton />
        </GenericBoxItem>

        {extinguisherAuditsState.error && (
          <GenericBoxItem>
            <ErrorNotification error={extinguisherAuditsState.error} />
          </GenericBoxItem>
        )}

        {!extinguisherAuditsState.error && (
          <GenericBoxItem>
            {renderResults(extinguisherAuditsState.all)}
          </GenericBoxItem>
        )}

        <GenericBoxItem>
          <GoBackButton history={history} />
        </GenericBoxItem>
      </Box>
      <ScrollToTop />
    </Fragment>
  );
};