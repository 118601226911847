import React, { useContext, Fragment, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@mui/material";

import { AppContext } from "../../../store";
import Loader from "../../../components/shared/loader";
import { PageTitle } from "../../../components/shared/page-title";
import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { GoBackButton } from "../../../components/shared/go-back";
import { AdministratorCreateEditFormValues, AdministratorCreateEditForm } from "../create/create-form";
import { ErrorNotification } from "../../../components/shared/error";
import { GenericBoxItem } from "../../../components/shared/card-box-container";
interface MatchParams {
    id: string;
}

export default function ({ history, location }: RouteComponentProps<MatchParams>) {
    const {
        administrator: adminState,
        iva: ivaState,
        document: documentState
    } = useContext(AppContext);

    const [model, setModel] = useState<undefined | AdministratorCreateEditFormValues>(undefined);

    useEffect(() => {
        //@ts-ignore
        const { admin } = location.state || {};
        if (!admin) {
            history.push('/administrator');
            return;
        }

        const { id, name,
            ivaId, documentId, document,
            address,
            email1, email2,
            tel1_area, tel1_num, tel2_area, tel2_num,
            web, contact, comments,
            status } = admin as administrator.UIModel;
        const transformedModel: AdministratorCreateEditFormValues = {
            id: String(id),
            name,
            document,
            document_id: documentId,
            iva_id: ivaId,
            street1_name: address.street,
            door1: address.door,
            city1_name: address.city,
            state1_name: address.state,
            // neighborhood1_name: address.neig
            zip1: address.zip,
            unit1: address.unit,
            floor1: address.floor,
            lat1: address.lat as number,
            lng1: address.lng as number,
            email1,
            email2,
            web,
            contact,
            comments,
            tel1_area, tel1_num, tel2_area, tel2_num,
            statusId: status
        };
        setModel(transformedModel)
    }, [location.state, history])

    return (
        <Fragment>
            <PageTitle title={"Editar Administracion"}></PageTitle>

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>
                <GenericBoxItem>
                    <h3>Editar Administracion</h3>
                </GenericBoxItem>

                {adminState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
                {adminState.error && <GenericBoxItem><ErrorNotification error={adminState.error} /></GenericBoxItem>}

                <GenericBoxItem>
                    <AdministratorCreateEditForm
                        documentTypes={documentState.all}
                        ivaTypes={ivaState.all}
                        onSuccess={() => history.push('/success', { operation: 'ADM_UPDATE', routeTo: '/administrator' })}
                        initialValues={model}
                    />
                </GenericBoxItem>

                <GenericBoxItem>
                    <GoBackButton history={history} />
                </GenericBoxItem>

            </Box>
            <ScrollToTop />
        </Fragment>
    );
};