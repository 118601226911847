export enum UserActionType {
  LOAD_INIT = "@user/LOAD_INIT",
  LOAD_SUCCESS = "@user/LOAD_SUCCESS",
  LOAD_FAILURE = "@user/LOAD_FAILURE"
}

export type UserAction =
  | { type: UserActionType.LOAD_INIT }
  | {
      type: UserActionType.LOAD_SUCCESS;
      payload: backend.userNS.LoginUserResponseSuccess;
    }
  | {
      type: UserActionType.LOAD_FAILURE;
      payload: backend.common.ErrorResponse;
    };
