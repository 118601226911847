export enum ExtinguisherActionType {
    CREATE_INIT = "@extinguisher/CREATE_INIT",
    CREATE_SUCCESS = "@extinguisher/CREATE_SUCCESS",
    CREATE_FAILURE = "@extinguisher/CREATE_FAILURE",

    DELETE_INIT = "@extinguisher/DELETE_INIT",
    DELETE_SUCCESS = "@extinguisher/DELETE_SUCCESS",
    DELETE_FAILURE = "@extinguisher/DELETE_FAILURE",

    SEARCH_INIT = "@extinguisher/SEARCH_INIT",
    SEARCH_SUCCESS = "@extinguisher/SEARCH_SUCCESS",
    SEARCH_FAILURE = "@extinguisher/SEARCH_FAILURE",

    LINK_OR_UNLINK_INIT = "@extinguisher/LINK_OR_UNLINK_INIT",
    LINK_OR_UNLINK_SUCCESS = "@extinguisher/LINK_OR_UNLINK_SUCCESS",
    LINK_OR_UNLINK_FAILURE = "@extinguisher/LINK_OR_UNLINK_FAILURE",

    EDIT_MASSIVE_INIT = "@extinguisher/EDIT_MASSIVE_INIT",
    EDIT_MASSIVE_SUCCESS = "@extinguisher/EDIT_MASSIVE_SUCCESS",
    EDIT_MASSIVE_FAILURE = "@extinguisher/EDIT_MASSIVE_FAILURE"
}

export type ExtinguisherAction =
    | { type: ExtinguisherActionType.CREATE_INIT }
    | {
        type: ExtinguisherActionType.CREATE_SUCCESS
    }
    | {
        type: ExtinguisherActionType.CREATE_FAILURE,
        payload: backend.common.ErrorResponse
    }
    | { type: ExtinguisherActionType.DELETE_INIT }
    | {
        type: ExtinguisherActionType.DELETE_SUCCESS,
        payload: string
    }
    | {
        type: ExtinguisherActionType.DELETE_FAILURE,
        payload: backend.common.ErrorResponse
    }
    | { type: ExtinguisherActionType.SEARCH_INIT }
    | {
        type: ExtinguisherActionType.SEARCH_SUCCESS,
        payload: Array<extinguisher.UIModel>
    }
    | {
        type: ExtinguisherActionType.SEARCH_FAILURE,
        payload: backend.common.ErrorResponse
    }
    | { type: ExtinguisherActionType.LINK_OR_UNLINK_INIT }
    | {
        type: ExtinguisherActionType.LINK_OR_UNLINK_SUCCESS
    }
    | {
        type: ExtinguisherActionType.LINK_OR_UNLINK_FAILURE,
        payload: backend.common.ErrorResponse
    }
    | { type: ExtinguisherActionType.EDIT_MASSIVE_INIT }
    | {
        type: ExtinguisherActionType.EDIT_MASSIVE_SUCCESS
    }
    | {
        type: ExtinguisherActionType.EDIT_MASSIVE_FAILURE,
        payload: backend.common.ErrorResponse
    }
