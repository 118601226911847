import { ExtinguisherActionType, ExtinguisherAction } from "./types";
import { doCall } from "../../../utils/apiCall";

const saveSuccess = (): ExtinguisherAction => ({
    type: ExtinguisherActionType.CREATE_SUCCESS,
});
const saveFailure = (error: backend.common.ErrorResponse): ExtinguisherAction => ({
    type: ExtinguisherActionType.CREATE_FAILURE,
    payload: error
});
const saveInit = (): ExtinguisherAction => ({
    type: ExtinguisherActionType.CREATE_INIT
});

export const save = (request: backend.extinguisherNS.CreateExtinguisherRequest) => (dispatch: React.Dispatch<any>) => {
    dispatch(saveInit());
    return doCall({
        url: "/api/extinguisher",
        method: "POST",
        payload: removeAllEmptyFields(request)
    })
        .then((_) => {
            dispatch(saveSuccess());
        })
        .catch((error: backend.common.ErrorResponse) => {
            dispatch(saveFailure(error));
            return Promise.reject(error)
        });
};

export const edit = (request: backend.extinguisherNS.EditExtinguisherRequest) => (dispatch: React.Dispatch<any>) => {
    dispatch(saveInit());
    return doCall({
        url: `/api/extinguisher/${request.serie}`,
        method: "PUT",
        payload: removeAllEmptyFields(request)
    })
        .then((_) => {
            dispatch(saveSuccess());
        })
        .catch((error: backend.common.ErrorResponse) => {
            dispatch(saveFailure(error));
            return Promise.reject(error)
        });
};

const removeAllEmptyFields = (request: object) => Object.fromEntries(Object.entries(request).filter(([_, v]) => String(v).length > 0))