import { IvaActionType, IvaAction } from "./types";
import { doCall } from "../../../utils/apiCall";

const ivasLoadSuccess = (
  data: backend.ivaNS.LoadAllResponseSuccess
): IvaAction => ({
  type: IvaActionType.LOAD_SUCCESS,
  payload: data
});
const ivasLoadFailure = (error: backend.common.ErrorResponse): IvaAction => ({
  type: IvaActionType.LOAD_FAILURE,
  payload: error
});
const ivasLoadInit = (): IvaAction => ({
  type: IvaActionType.LOAD_INIT
});

export const loadIvas = () => (dispatch: React.Dispatch<any>) => {
  dispatch(ivasLoadInit());
  return doCall({
    url: "/api/iva"
  })
    .then((data) => {
      dispatch(ivasLoadSuccess(data as backend.ivaNS.LoadAllResponseSuccess));
    })
    .catch((error: backend.common.ErrorResponse) => {
      dispatch(ivasLoadFailure(error));
    });
};
