import React, { Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Box, Button } from "@mui/material";

import Loader from "../loader";

import { IndustryState } from "../../../domain/industry/reducer";
import { DocumentState } from "../../../domain/document/reducer";
import { IvaState } from "../../../domain/iva/reducer";
import { SellerState } from "../../../domain/seller/reducer";
import { ConfigGenericItem } from "./config-generic-item";
import { ProductState } from "../../../domain/product/reducer";
import { PageTitle } from "../page-title";
import { ScrollToTop } from "../scroll-to-top";
import { GoBackButton } from "../go-back";
import { Notification } from "../notification";
import { GenericBoxItem } from "../card-box-container";
interface ConfigGenericContainerType extends RouteComponentProps {
  configItemSpecificState:
  | IndustryState
  | IvaState
  | DocumentState
  | SellerState
  | ProductState;
}

const renderItems = (items: Array<industry.UIModel |
  iva.UIModel |
  documento.UIModel |
  seller.UIModel |
  product.UIModel>, history: RouteComponentProps["history"]) => {
  return (
    items.map((el) => (
      <ConfigGenericItem
        key={el.id}
        item={el}
        history={history}
      />
    )))
}

const container: React.FunctionComponent<ConfigGenericContainerType> = ({
  configItemSpecificState,
  history,
  location: { pathname }
}) => {
  const isProductsRelated = pathname === "/product"
  return (
    <Fragment>
      <PageTitle title="Listado de Elementos" />

      <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>

        {configItemSpecificState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
        {!configItemSpecificState.loading && !configItemSpecificState && (
          <GenericBoxItem>
            <Notification warn title="Sin resultados" msg="No se encontraron resultados!" />
          </GenericBoxItem>
        )}
        {!configItemSpecificState.loading &&
          configItemSpecificState && (
            <>
              {isProductsRelated &&
                <GenericBoxItem>
                  <Button
                    // variant="contained"
                    onClick={() => history.push('/product/create')}>
                    Agregar nuevo producto
                  </Button>
                </GenericBoxItem>
              }
              <GenericBoxItem>
                {renderItems(configItemSpecificState.all, history)}
              </GenericBoxItem>
            </>
          )
        }
        <GenericBoxItem>
          <GoBackButton history={history} />
        </GenericBoxItem>
      </Box>
      <ScrollToTop />
    </Fragment >
  );
};

export const ConfigGenericContainer = withRouter(container);