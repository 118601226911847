import React, { Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { Box, Button } from "@mui/material";

import { PageTitle } from "../../components/shared/page-title";
import { GoBackButton } from "../../components/shared/go-back";
import { Notification } from "../../components/shared/notification";
import { GenericBoxItem } from "../../components/shared/card-box-container";

interface MatchParams {
    edit?: string
}
export default function ({ history, location }: RouteComponentProps<MatchParams>) {
    const { clientId, operation, routeTo }: any = location?.state || {};
    const hasClient = typeof clientId !== "undefined";

    let pageTitle = 'Success!';
    let notificationTitle = 'Success!';
    let notificationMessage = 'La operacion se ha realizado con exito!'

    switch (operation) {
        case 'EXT_CREATE': {
            pageTitle = "Nuevo Matafuego Agregado!";
            notificationTitle = "Matafuego Registrado";
            notificationMessage = "El extintor se ha agregrado satisfactoriamente!"
            break;
        }
        case 'EXT_UPDATE': {
            pageTitle = "Matafuego Actualizado!";
            notificationTitle = 'Matafuego Actualizado';
            notificationMessage = "El extintor se ha modificado satisfactoriamente!";
            break;
        }
        case 'EXT_UPDATE_EXP_DATE': {
            pageTitle = "Matafuegos Actualizados!";
            notificationTitle = 'Fecha de Vencimiento actualizada'
            notificationMessage = 'La fecha de vencimiento ha sido modificada con exito para los extintores solicitados.'
            break;
        }
        case 'EXT_LINK': {
            pageTitle = "Matafuegos Asociados!";
            notificationTitle = 'Matafuegos Asociados'
            notificationMessage = 'Los extintores se han asociado al cliente solicitado.'
            break;
        }
        case 'CLI_CREATE': {
            pageTitle = "Nuevo Cliente Agregado!";
            notificationTitle = `Cliente Registrado (ID: ${clientId})`;
            notificationMessage = "El Cliente se ha agregrado satisfactoriamente!"
            break;
        }
        case 'CLI_UPDATE': {
            pageTitle = "Cliente Actualizado!";
            notificationTitle = "Cliente Actualizado";
            notificationMessage = "El Cliente se ha modificado satisfactoriamente!"
            break;
        }
        case 'ADM_CREATE': {
            pageTitle = "Nueva Administracion Agregada!";
            notificationTitle = "Administracion Registrada";
            notificationMessage = "La Administracion se ha agregrado satisfactoriamente!"
            break;
        }
        case 'ADM_UPDATE': {
            pageTitle = "Administracion Actualizada!";
            notificationTitle = "Administracion Actualizada";
            notificationMessage = "La Administracion se ha modificado satisfactoriamente!"
            break;
        }

        case 'PRO_CREATE': {
            pageTitle = "Nuevo Producto Agregado!";
            notificationTitle = "Producto Registrado";
            notificationMessage = "El Producto se ha registrado satisfactoriamente!"
            break;
        }
        case 'PRO_UPDATE': {
            pageTitle = "Producto Actualizado!";
            notificationTitle = "Producto Actualizado";
            notificationMessage = "El Producto se ha modificado satisfactoriamente!"
            break;
        }
        default: {
            console.warn('no operation defined', { operation });
            pageTitle = "Actualizacion exitosa!";
            notificationTitle = "Elemento Actualizado";
            notificationMessage = "El elemento se ha modificado satisfactoriamente!"
        }
    }

    return (
        <Fragment>
            <PageTitle title={pageTitle}></PageTitle>
            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(2, 1fr)' }}>

                <GenericBoxItem>
                    <Notification title={notificationTitle} msg={notificationMessage} />
                </GenericBoxItem>

                {hasClient && (
                    <>
                        <GenericBoxItem>
                            <Button variant="contained"
                                onClick={() => history.push(`/client/${clientId}/quote`)}
                            >PRESUPUESTAR
                            </Button>
                            <br /><br />
                            <Button variant="contained"
                                onClick={() => history.push(`/client/details/${clientId}`)}
                            >DETALLES
                            </Button>
                        </GenericBoxItem>
                    </>)}

                {!routeTo && <GenericBoxItem><GoBackButton history={history} /></GenericBoxItem>}
                {routeTo && <GenericBoxItem>
                    <Button variant="contained" onClick={() => history.push(routeTo)}>
                        Volver
                    </Button>
                </GenericBoxItem>}
            </Box>
        </Fragment>
    );
};