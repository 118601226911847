/** @jsx jsx */
import { Fragment, useState, FunctionComponent } from "react";
import GoogleMapReact, { ChildComponentProps } from "google-map-react";
import { css, jsx } from "@emotion/react";

import { ClientAddress as ClientAddressType } from "../../domain/client/client.d";
import { ExtinguisherServiceNeeded } from "../../domain/extinguisher/extinguisher";
import { ExpandPanel } from "../../components/shared/expand-panel";
import { CardBoxContainer } from "../../components/shared/card-box-container";

interface ClientExtinguishersServiceSearchResultsMapProps {
  clients: ExtinguisherServiceNeeded["clients"];
  map: ExtinguisherServiceNeeded["map"];
}
export const ClientExtinguishersServiceSearchResultsMap: FunctionComponent<ClientExtinguishersServiceSearchResultsMapProps> = ({
  clients,
  map
}) => {
  return (
    <CardBoxContainer css={css`
    @media print {
      & {
        display: none;
      }
    }
  `}>
      <ExpandPanel title={"Mapa"}>
        {map && (
          <section>
            <div style={{ height: "100vh", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string
                }}
                defaultCenter={{
                  lat: map.lat,
                  lng: map.lng
                }}
                defaultZoom={10}
              >
                {clients.map((el, idx) => {
                  if (el.address.lat && el.address.lng) {
                    return (
                      <Marker
                        key={idx}
                        lat={el.address.lat}
                        lng={el.address.lng}
                        show={true}
                        address={el.address}
                        name={el.name}
                      />
                    );
                  } else return null;
                })}
              </GoogleMapReact>
            </div>
          </section>
        )}
      </ExpandPanel>
    </CardBoxContainer>
  );
};

interface MarkerProps {
  key: number;
  show: boolean;
  address: ClientAddressType;
  name: string;
}
const Marker = (props: MarkerProps & ChildComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const markerStyle = {
    border: "1px solid white",
    borderRadius: "50%",
    height: 20,
    width: 20,
    backgroundColor: isOpen ? "red" : "blue",
    cursor: "pointer",
    zIndex: 10
  };

  const toggleMarker = () => setIsOpen(!isOpen);
  return (
    <Fragment>
      <div style={markerStyle} onClick={toggleMarker} />
      {isOpen && <InfoWindow place={{ name: props.name, ...props.address }} />}
    </Fragment>
  );
};

interface PropsPlace {
  name: string;
}

interface InfoWindowProps {
  place: PropsPlace & ClientAddressType;
}
const InfoWindow: FunctionComponent<InfoWindowProps> = (props) => {
  const { place } = props;
  const infoWindowStyle = {
    position: "relative" as "relative",
    bottom: "6rem",
    left: "1.5rem",
    width: "20rem",
    backgroundColor: "white",
    boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
    padding: 10,
    fontSize: 12,
    zIndex: 100,
    fontWeight: "bold" as "bold"
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 14 }}>{place.name}</div>
      <div>
        {place.street} N&deg; {place.door}
        <br />
        {place.city} {place.state}
      </div>
    </div>
  );
};
