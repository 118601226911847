/** @jsx jsx */
import { useContext, useState } from "react";
import { css, jsx } from "@emotion/react";

import { LoadingButton } from "@mui/lab";
import { Button, MenuItem, Stack, StandardTextFieldProps, TextField } from "@mui/material";
import { Formik, Form } from "formik";

import { AppContext } from "../../../store";
import { save as saveAdministrator } from "../../../domain/administrator/actions/save";
import { edit as editAdministrator } from "../../../domain/administrator/actions/edit";
import { AddressAutocomplete } from "../../../components/shared/google-autocomplete";
import { createFormSchema, formInitialValues } from './create-form-schema';
import { FormFieldBoxItem, getErrorText, hasError } from "../../../components/shared/form-utils";

import {
    FIELD_LENGTH_STREET,
    FIELD_LENGTH_DOOR,
    FIELD_LENGTH_NEIGHBORHOOD,
    FIELD_LENGTH_CITY,
    FIELD_LENGTH_STATE,
    FIELD_LENGTH_ZIP,
    FIELD_FLOOR_LENGTH, FIELD_UNIT_LENGTH,
    FIELD_LENGTH_COMMENTS,
    FIELD_LENGTH_CONTACT,
    FIELD_LENGTH_DOCUMENT,
    FIELD_LENGTH_NAME,
    FIELD_LENGTH_PHONE, FIELD_LENGTH_PHONE_AREA,
    FIELD_LENGTH_EMAIL, FIELD_LENGTH_WEB, restrictNumberOnly,
} from "../../../utils/form.utils";

interface AdministratorCreateEditForm {
    documentTypes: documento.UIModel[];
    ivaTypes: iva.UIModel[];
    onSuccess: Function;
    initialValues?: AdministratorCreateEditFormValues;
}
export type AdministratorCreateEditFormValues = backend.administratorNS.EditAdministratorRequest & { id?: string };
export const AdministratorCreateEditForm = ({
    documentTypes,
    ivaTypes,
    onSuccess,
    initialValues
}: AdministratorCreateEditForm) => {
    const {
        administrator: administratorState,
        status: statusTypes
    } = useContext(AppContext);
    const [autocompleteInitialValue, setAutocompleteInitialValue] = useState("");

    const isEditing = typeof initialValues !== "undefined";
    const actionFn = isEditing ? editAdministrator : saveAdministrator;
    const _formInitialValues = isEditing ? initialValues : formInitialValues;

    return (
        <Formik
            initialValues={_formInitialValues}
            validationSchema={createFormSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                //@ts-ignore
                actionFn(values)(administratorState.dispatch)
                    .then((_: any) => onSuccess())
                    .finally(() => setSubmitting(false));
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
                setFieldValue,
                setFieldTouched,
                dirty
                /* and other goodies */
            }) => {
                const formikProps = (name: string): StandardTextFieldProps => ({
                    variant: "standard",
                    name,
                    //@ts-ignore
                    value: values[name],
                    error: hasError(touched, errors, name),
                    helperText: getErrorText(errors, name),
                    onChange: handleChange(name),
                    onBlur: handleBlur(name),
                    autoComplete: "off"
                });
                return (
                    <Form css={css`
          display: grid;
          grid-template-areas:
              'name name name'
              'iva doc-id doc'
              'autocomplete autocomplete autocomplete'
              'street1 street1 door1'
              'floor1 unit1 zip1'
              'neighborhood1 neighborhood1 neighborhood1'
              'city1 city1 city1'
              'state1 state1 state1'
              'tel1area tel1num tel1num'
              'tel2area tel2num tel2num'
              'tel3 tel3 tel3'
              'email1 email1 email2'
              'web contact contact'
              'comments comments comments'
              'status status status'
              'buttons buttons buttons'
          `}>
                        <FormFieldBoxItem style={{ gridArea: 'name' }}>
                            <TextField
                                type="text"
                                inputProps={{ maxLength: FIELD_LENGTH_NAME }}
                                placeholder="Ingrese Razon Social"
                                label="Razon Social"
                                {...formikProps("name")}
                                autoFocus
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'iva' }}>
                            <TextField
                                select
                                label="IVA"
                                {...formikProps("iva_id")}
                            >
                                {ivaTypes.map((e) => (
                                    <MenuItem key={`iva_${e.id}`} value={e.id}>
                                        {e.desc}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'doc-id' }}>
                            <TextField
                                select
                                label="Tipo Documento"
                                {...formikProps("document_id")}
                            >
                                {documentTypes.map((e) => (
                                    <MenuItem key={`doc_${e.id}`} value={e.id}>
                                        {e.desc}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'doc' }}>
                            <TextField
                                type="tel"
                                inputProps={{ maxLength: FIELD_LENGTH_DOCUMENT }}
                                label="Documento"
                                {...formikProps("document")}
                                onKeyPress={restrictNumberOnly}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'autocomplete' }}>
                            <div style={{
                                margin: '1rem 1rem 1rem 0',
                                border: '1px solid black',
                                height: '4rem',
                                padding: '1rem'
                            }}>
                                <AddressAutocomplete
                                    initialValue={autocompleteInitialValue}
                                    onChangeEvent={(value: string) => setAutocompleteInitialValue(value)}
                                    onPlaceSelect={(values: any) => {
                                        console.info('AddressAutocomplete values:', { values })
                                        setFieldValue('state1_name', values.stateName)
                                        setFieldValue('city1_name', values.cityName)
                                        setFieldValue('street1_name', values.streetName)
                                        setFieldValue('neighborhood1_name', values.neighborhoodName)
                                        setFieldValue('door1', values.streetNumber)
                                        setFieldValue('zip1', values.zipCode)
                                        setFieldValue('lat1', values.lat)
                                        setFieldValue('lng1', values.lng)
                                    }}
                                    {...formikProps("google_autocomplete")} />
                            </div>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'street1' }}>
                            <TextField
                                type="text"
                                disabled
                                inputProps={{ maxLength: FIELD_LENGTH_STREET }}
                                label="Calle"
                                {...formikProps("street1_name")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'door1' }}>
                            <TextField
                                type="text"
                                disabled
                                inputProps={{ maxLength: FIELD_LENGTH_DOOR }}
                                label="Puerta"
                                {...formikProps("door1")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'floor1' }}>
                            <TextField
                                type="text"
                                inputProps={{ maxLength: FIELD_UNIT_LENGTH }}
                                label="Piso"
                                {...formikProps("floor1")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'unit1' }}>
                            <TextField
                                type="text"
                                inputProps={{ maxLength: FIELD_FLOOR_LENGTH }}
                                label="Dpto"
                                {...formikProps("unit1")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'neighborhood1' }}>
                            <TextField
                                type="text"
                                inputProps={{ maxLength: FIELD_LENGTH_NEIGHBORHOOD }}
                                label="Barrio"
                                {...formikProps("neighborhood1_name")} />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'city1' }}>
                            <TextField
                                type="text"
                                disabled
                                inputProps={{ maxLength: FIELD_LENGTH_CITY }}
                                label="Localidad"
                                {...formikProps("city1_name")} />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'state1' }}>
                            <TextField
                                type="text"
                                disabled
                                inputProps={{ maxLength: FIELD_LENGTH_STATE }}
                                label="Provincia"
                                {...formikProps("state1_name")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'zip1' }}>
                            <TextField
                                type="text"
                                inputProps={{ maxLength: FIELD_LENGTH_ZIP }}
                                label="Codigo Postal"
                                {...formikProps("zip1")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ display: 'none' }}>
                            <TextField
                                type="number"
                                disabled
                                label="Lat"
                                {...formikProps("lat1")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ display: 'none' }}>
                            <TextField
                                type="number"
                                disabled
                                label="Long"
                                {...formikProps("lng1")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'email1' }}>
                            <TextField
                                type="email"
                                inputProps={{ maxLength: FIELD_LENGTH_EMAIL }}
                                label="Email 1"
                                placeholder="Ingrese email"
                                {...formikProps("email1")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'email2' }}>
                            <TextField
                                type="email"
                                inputProps={{ maxLength: FIELD_LENGTH_EMAIL }}
                                label="Email 2"
                                placeholder="Ingrese email"
                                {...formikProps("email2")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'web' }}>
                            <TextField
                                type="url"
                                inputProps={{ maxLength: FIELD_LENGTH_WEB }}
                                label="Pagina web"
                                placeholder="http://www..."
                                {...formikProps("web")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'contact' }}>
                            <TextField
                                type="text"
                                inputProps={{ maxLength: FIELD_LENGTH_CONTACT }}
                                label="Contacto"
                                placeholder="Ingrese contacto"
                                {...formikProps("contact")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'comments' }}>
                            <TextField
                                type="text"
                                multiline
                                rows={5}
                                inputProps={{ maxLength: FIELD_LENGTH_COMMENTS }}
                                placeholder="Ingrese comentarios"
                                label="Comentarios"
                                {...formikProps("comments")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'tel1area' }}>
                            <TextField
                                type="tel"
                                inputProps={{ maxLength: FIELD_LENGTH_PHONE_AREA }}
                                placeholder="Ingrese codigo de area. Ejemplo: 11"
                                label="Tel1 area"
                                {...formikProps("tel1_area")}
                                onKeyPress={restrictNumberOnly}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'tel1num' }}>
                            <TextField
                                type="tel"
                                inputProps={{ maxLength: FIELD_LENGTH_PHONE }}
                                placeholder="Ingrese numero"
                                label="Tel1 numero"
                                {...formikProps("tel1_num")}
                                onKeyPress={restrictNumberOnly}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'tel2area' }}>
                            <TextField
                                type="tel"
                                inputProps={{ maxLength: FIELD_LENGTH_PHONE_AREA }}
                                placeholder="Ingrese codigo de area. Ejemplo: 223"
                                label="Tel2 area"
                                {...formikProps("tel2_area")}
                                onKeyPress={restrictNumberOnly}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'tel2num' }}>
                            <TextField
                                type="tel"
                                inputProps={{ maxLength: FIELD_LENGTH_PHONE }}
                                placeholder="Ingrese numero"
                                label="Tel2 numero"
                                {...formikProps("tel2_num")}
                                onKeyPress={restrictNumberOnly}
                            />
                        </FormFieldBoxItem>

                        {isEditing && (<FormFieldBoxItem style={{ gridArea: 'status' }}>
                            <TextField
                                select
                                label="Estado"
                                {...formikProps("statusId")}
                            >
                                {Object.entries(statusTypes).map((e) => (
                                    <MenuItem key={`status_${e[0]}`} value={e[0]}>
                                        {e[1]}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>)}

                        <FormFieldBoxItem style={{ gridArea: 'buttons' }}>
                            <Stack direction="row" spacing={2}>
                                <LoadingButton variant="contained"
                                    loading={isSubmitting}
                                    type="submit"
                                    disabled={isEditing && !dirty}
                                >
                                    {isEditing ? "Modificar" : "Agregar"}
                                </LoadingButton>
                                <Button variant="contained" color="secondary"
                                    onClick={() => {
                                        resetForm(_formInitialValues);
                                        setAutocompleteInitialValue("");
                                    }}
                                    type="button"
                                >Reset</Button>
                            </Stack>
                        </FormFieldBoxItem>

                    </Form >
                );
            }}
        </Formik >
    );
};