import { AdministratorActionType, AdministratorAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import { saveActionRequest, saveActionResponse } from "./index.d";

const saveSuccess = (): AdministratorAction => ({
  type: AdministratorActionType.CREATE_SUCCESS
});
const saveFailure = (
  error: backend.quote.QuoteResponseError
): AdministratorAction => ({
  type: AdministratorActionType.CREATE_FAILURE,
  payload: error
});
const saveInit = (): AdministratorAction => ({
  type: AdministratorActionType.CREATE_INIT
});

export const save = (request: saveActionRequest) => (
  dispatch: React.Dispatch<any>
): saveActionResponse => {
  dispatch(saveInit());
  return doCall({
    url: `/api/administrator`,
    method: "POST",
    payload: removeAllEmptyFields(request)
  })
    .then((_) => dispatch(saveSuccess()))
    //@ts-ignore
    .catch((error: backend.quote) => {
      dispatch(saveFailure(error));
      return Promise.reject(error);
    });
};

const removeAllEmptyFields = (request: object) => Object.fromEntries(Object.entries(request).filter(([_, v]) => String(v).length > 0))