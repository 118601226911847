import React, { useContext } from "react";

import { Formik, Form } from "formik";
import * as yup from "yup";
import { loginUser } from "../../../domain/user/actions";
import { AppContext } from "../../../store";
import { FormFieldBoxItem, getErrorText, hasError } from "../../../components/shared/form-utils";
import { Button, Stack, StandardTextFieldProps, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const usernameMaxLength = 20;
const passwordMaxLength = 20;
const requiredFieldMessage = "Debe ingresar un valor!";

const loginFormSchema = yup.object().shape({
  username: yup
    .string()
    .required(requiredFieldMessage)
    .max(usernameMaxLength)
    .ensure(),
  password: yup
    .string()
    .required(requiredFieldMessage)
    .max(passwordMaxLength)
    .ensure()
});

interface LoginFormType {
  onSuccess: () => void;
}
export const LoginForm: React.FunctionComponent<LoginFormType> = ({ onSuccess }) => {
  const formInitialValues = {
    username: undefined,
    password: undefined
  };
  const { user: userState } = useContext(AppContext);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={loginFormSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        loginUser({
          //@ts-ignore
          username: values.username,
          //@ts-ignore
          password: values.password
        })(userState.dispatch).then(onSuccess)
          .finally(() => setSubmitting(false));
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm
        /* and other goodies */
      }) => {
        const formikProps = (name: string): StandardTextFieldProps => ({
          variant: "standard",
          name,
          //@ts-ignore
          value: values[name],
          error: hasError(touched, errors, name),
          helperText: getErrorText(errors, name),
          onChange: handleChange(name),
          onBlur: handleBlur(name)
        });
        return (
          <Form>
            <FormFieldBoxItem>
              <TextField
                type="text"
                inputProps={{ maxLength: usernameMaxLength }}
                placeholder="Ingrese el usuario"
                label="Usuario"
                {...formikProps('username')}
                autoFocus />
            </FormFieldBoxItem>

            <FormFieldBoxItem>
              <label htmlFor="password"></label>
              <TextField
                type="password"
                inputProps={{ maxLength: passwordMaxLength }}
                placeholder="Ingrese la contrase&ntilde;a"
                label="Contrase&ntilde;a"
                {...formikProps('password')} />
            </FormFieldBoxItem>

            <FormFieldBoxItem>
              <Stack direction="row" spacing={2}>
                <LoadingButton variant="contained"
                  loading={isSubmitting}
                  type="submit"
                >
                  Login
                </LoadingButton>
                <Button variant="contained" color="secondary"
                  onClick={() => resetForm(formInitialValues)}
                  type="button"
                >Reset</Button>
              </Stack>
            </FormFieldBoxItem>
          </Form>
        );
      }}
    </Formik>
  );
};