import { FilterClientRequest, UnlinkExtinguisherRequest } from "./index.d";

export type ClientAction =
  | { type: ClientActionType.SEARCH_INIT }
  | {
    type: ClientActionType.SEARCH_SUCCESS;
    payload: backend.client.SearchClientResponseSuccess;
  }
  | {
    type: ClientActionType.SEARCH_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  | { type: ClientActionType.GET_DETAILS_INIT }
  | {
    type: ClientActionType.GET_DETAILS_SUCCESS;
    payload: backend.client.GetClientDetailsResponseSuccess;
  }
  | {
    type: ClientActionType.GET_DETAILS_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  | { type: ClientActionType.CREATE_INIT }
  | {
    type: ClientActionType.CREATE_SUCCESS
  }
  | {
    type: ClientActionType.CREATE_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  | { type: ClientActionType.EDIT_INIT }
  | { type: ClientActionType.EDIT_SUCCESS }
  | {
    type: ClientActionType.EDIT_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  | { type: ClientActionType.DO_FILTER; payload: FilterClientRequest }
  | { type: ClientActionType.UNLINK_EXTINGUISHER; payload: UnlinkExtinguisherRequest }

  | { type: ClientActionType.RESET_FILTER }
  | { type: ClientActionType.GET_EXTINGUISHERS_INIT }
  | {
    type: ClientActionType.GET_EXTINGUISHERS_SUCCESS;
    payload: backend.extinguisherNS.GetExtinguishersByClientResponseSuccess;
  }
  | {
    type: ClientActionType.GET_EXTINGUISHERS_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  | { type: ClientActionType.DO_QUOTE_INIT }
  | {
    type: ClientActionType.DO_QUOTE_SUCCESS;
    payload: backend.quote.QuoteResponseSuccess;
  }
  | {
    type: ClientActionType.DO_QUOTE_FAILURE;
    payload: backend.quote.QuoteResponseError;
  }
  | { type: ClientActionType.LIST_QUOTES_INIT }
  | {
    type: ClientActionType.LIST_QUOTES_SUCCESS;
    payload: backend.quote.ListQuotesResponseSuccess;
  }
  | {
    type: ClientActionType.LIST_QUOTES_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  | { type: ClientActionType.SEND_QUOTE_EMAIL_INIT }
  | { type: ClientActionType.SEND_QUOTE_EMAIL_SUCCESS }
  | {
    type: ClientActionType.SEND_QUOTE_EMAIL_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  | { type: ClientActionType.SEND_QUOTE_EMAIL_RESET }
  | { type: ClientActionType.DOWNLOAD_QUOTE_INIT }
  | { type: ClientActionType.DOWNLOAD_QUOTE_SUCCESS }
  | {
    type: ClientActionType.DOWNLOAD_QUOTE_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  | { type: ClientActionType.LINK_EXTINGUISHERS_LIST_INIT }
  | { type: ClientActionType.LINK_EXTINGUISHERS_LIST_SUCCESS }
  | { type: ClientActionType.LINK_EXTINGUISHERS_LIST_FAILURE, payload: backend.common.ErrorResponse }

  | { type: ClientActionType.UNLINK_EXTINGUISHER_INIT }
  | { type: ClientActionType.UNLINK_EXTINGUISHER_SUCCESS }
  | { type: ClientActionType.UNLINK_EXTINGUISHER_FAILURE, payload: backend.common.ErrorResponse }


export enum ClientActionType {
  SEARCH_INIT = "@client/SEARCH_INIT",
  SEARCH_SUCCESS = "@client/SEARCH_SUCCESS",
  SEARCH_FAILURE = "@client/SEARCH_FAILURE",

  GET_DETAILS_INIT = "@client/GET_DETAILS_INIT",
  GET_DETAILS_SUCCESS = "@client/GET_DETAILS_SUCCESS",
  GET_DETAILS_FAILURE = "@client/GET_DETAILS_FAILURE",

  DO_FILTER = "@client/DO_FILTER",
  RESET_FILTER = "@client/RESET_FILTER",

  UNLINK_EXTINGUISHER = "@client/UNLINK_EXTINGUISHER",
  UNLINK_EXTINGUISHER_INIT = "@client/UNLINK_EXTINGUISHER_INIT",
  UNLINK_EXTINGUISHER_SUCCESS = "@client/UNLINK_EXTINGUISHER_SUCCESS",
  UNLINK_EXTINGUISHER_FAILURE = "@client/UNLINK_EXTINGUISHER_FAILURE",


  LINK_EXTINGUISHERS_LIST_INIT = "@client/LINK_EXTINGUISHERS_LIST_INIT",
  LINK_EXTINGUISHERS_LIST_SUCCESS = "@client/LINK_EXTINGUISHERS_LIST_SUCCESS",
  LINK_EXTINGUISHERS_LIST_FAILURE = "@client/LINK_EXTINGUISHERS_LIST_FAILURE",

  GET_EXTINGUISHERS_INIT = "@client/GET_EXTINGUISHERS_INIT",
  GET_EXTINGUISHERS_SUCCESS = "@client/GET_EXTINGUISHERS_SUCCESS",
  GET_EXTINGUISHERS_FAILURE = "@client/GET_EXTINGUISHERS_FAILURE",

  DO_QUOTE_INIT = "@client/DO_QUOTE_INIT",
  DO_QUOTE_SUCCESS = "@client/DO_QUOTE_SUCCESS",
  DO_QUOTE_FAILURE = "@client/DO_QUOTE_FAILURE",

  DOWNLOAD_QUOTE_INIT = "@client/DOWNLOAD_QUOTE_INIT",
  DOWNLOAD_QUOTE_SUCCESS = "@client/DOWNLOAD_QUOTE_SUCCESS",
  DOWNLOAD_QUOTE_FAILURE = "@client/DOWNLOAD_QUOTE_FAILURE",

  SEND_QUOTE_EMAIL_INIT = "@client/SEND_QUOTE_EMAIL_INIT",
  SEND_QUOTE_EMAIL_SUCCESS = "@client/SEND_QUOTE_EMAIL_SUCCESS",
  SEND_QUOTE_EMAIL_FAILURE = "@client/SEND_QUOTE_EMAIL_FAILURE",
  SEND_QUOTE_EMAIL_RESET = "@client/SEND_QUOTE_EMAIL_RESET",

  LIST_QUOTES_INIT = "@client/LIST_QUOTES_INIT",
  LIST_QUOTES_SUCCESS = "@client/LIST_QUOTES_SUCCESS",
  LIST_QUOTES_FAILURE = "@client/LIST_QUOTES_FAILURE",

  CREATE_INIT = "@client/CREATE_INIT",
  CREATE_SUCCESS = "@client/CREATE_SUCCESS",
  CREATE_FAILURE = "@client/CREATE_FAILURE",

  EDIT_INIT = "@client/EDIT_INIT",
  EDIT_SUCCESS = "@client/EDIT_SUCCESS",
  EDIT_FAILURE = "@client/EDIT_FAILURE"
}
