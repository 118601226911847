import * as yup from "yup";
import { isEmpty, isNil } from "ramda";
import { FIELD_LENGTH_FANTASY_NAME, FIELD_LENGTH_NAME, FIELD_LENGTH_PHONE, FIELD_LENGTH_STATE, FIELD_LENGTH_STREET, VALIDATION_MSG_NUMBER, VALIDATION_REGEX_NUMBER } from "../../../utils/form.utils";

export const clientIdMaxLength = 4;

const requiredFieldMessage = "Debe ingresar un valor!";
const minLengthFieldMessage = (num: number) =>
    `Debe ingresar al menos ${num} caracteres!`;

const isNilOrEmpty = (val: string | number) => isEmpty(val) || isNil(val);

export const searchFormSchema = yup.object().shape(
    {
        clientId: yup
            .string()
            .when(["clientName", "clientFantasyName", "clientStreet", "clientProvince", "clientPhone"], {
                is: (name: string, fantasy: string, street: string, province: string, phone: string) =>
                    isNilOrEmpty(name) &&
                    isNilOrEmpty(fantasy) &&
                    isNilOrEmpty(street) &&
                    isNilOrEmpty(province) &&
                    isNilOrEmpty(phone),
                then: yup
                    .string()
                    .required(requiredFieldMessage)
                    .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER)
                    .min(1, minLengthFieldMessage(1))
                    .max(clientIdMaxLength)
            }),
        clientName: yup
            .string()
            .when(["clientId", "clientFantasyName", "clientStreet", "clientProvince", "clientPhone"], {
                is: (clientId: string, fantasy: string, street: string, province: string, phone: string) =>
                    isNilOrEmpty(clientId) &&
                    isNilOrEmpty(fantasy) &&
                    isNilOrEmpty(street) &&
                    isNilOrEmpty(province) &&
                    isNilOrEmpty(phone),
                then: yup
                    .string()
                    .required(requiredFieldMessage)
                    .min(4, minLengthFieldMessage(4))
                    .max(FIELD_LENGTH_NAME)
            }),
        clientProvince: yup
            .string()
            .when(["clientId", "clientName", "clientFantasyName", "clientStreet", "clientPhone"], {
                is: (clientId: string, name: string, fantasy: string, street: string, phone: string) =>
                    isNilOrEmpty(clientId) &&
                    isNilOrEmpty(name) &&
                    isNilOrEmpty(fantasy) &&
                    isNilOrEmpty(street) &&
                    isNilOrEmpty(phone),
                then: yup
                    .string()
                    .required(requiredFieldMessage)
                    .min(4, minLengthFieldMessage(4))
                    .max(FIELD_LENGTH_STATE)
            }),
        clientFantasyName: yup
            .string()
            .when(["clientId", "clientName", "clientStreet", "clientProvince", "clientPhone"], {
                is: (clientId: string, name: string, street: string, province: string, phone: string) =>
                    isNilOrEmpty(clientId) &&
                    isNilOrEmpty(name) &&
                    isNilOrEmpty(street) &&
                    isNilOrEmpty(province) &&
                    isNilOrEmpty(phone),
                then: yup
                    .string()
                    .required(requiredFieldMessage)
                    .min(4, minLengthFieldMessage(4))
                    .max(FIELD_LENGTH_FANTASY_NAME)
            }),
        clientStreet: yup
            .string()
            .when(["clientId", "clientFantasyName", "clientName", "clientProvince", "clientPhone"], {
                is: (clientId: string, fantasyName: string, name: string, province: string, phone: string) =>
                    isNilOrEmpty(clientId) &&
                    isNilOrEmpty(fantasyName) &&
                    isNilOrEmpty(name) &&
                    isNilOrEmpty(province) &&
                    isNilOrEmpty(phone),
                then: yup
                    .string()
                    .required(requiredFieldMessage)
                    .min(4, minLengthFieldMessage(4))
                    .max(FIELD_LENGTH_STREET)
            }),
        clientPhone: yup
            .string()
            .when(["clientId", "clientFantasyName", "clientName", "clientStreet", "clientProvince"], {
                is: (clientId: string, fantasyName: string, name: string, street: string, province: string) =>
                    isNilOrEmpty(clientId) &&
                    isNilOrEmpty(fantasyName) &&
                    isNilOrEmpty(name) &&
                    isNilOrEmpty(street) &&
                    isNilOrEmpty(province),
                then: yup.string()
                    .required(requiredFieldMessage)
                    .max(FIELD_LENGTH_PHONE)
                    .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),
            })
    },
    [
        ["clientName", "clientId"],
        ["clientName", "clientFantasyName"],
        ["clientName", "clientPhone"],
        ["clientName", "clientStreet"],
        ["clientName", "clientProvince"],

        ["clientPhone", "clientFantasyName"],
        ["clientPhone", "clientStreet"],
        ["clientPhone", "clientId"],
        ["clientPhone", "clientProvince"],

        ["clientFantasyName", "clientStreet"],
        ["clientFantasyName", "clientId"],
        ["clientFantasyName", "clientProvince"],

        ["clientId", "clientStreet"],
        ["clientId", "clientProvince"],

        ["clientStreet", "clientProvince"],
    ]
);