import React, { useState } from "react";
import classnames from "classnames";
import { Button } from "@mui/material";

import { ClientAddress as ClientAddressType } from "../../../domain/client/client";
import { GenericBoxItem } from "../../../components/shared/card-box-container";
interface ClientAddressComponent {
  address: ClientAddressType;
  showMap?: boolean;
}
export const ClientAddress: React.FunctionComponent<ClientAddressComponent> = ({
  address,
  showMap = true
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [mapText, setMapText] = useState("Ver Mapa");

  const mapClasses = classnames({
    collapse: !isExpanded,
    "collapse.show": isExpanded
  });
  const toggleMapView = () => {
    setIsMapLoaded(true);
    setIsExpanded(!isExpanded);
    setMapText(mapText === "Ver Mapa" ? "Ocultar Mapa" : "Ver Mapa");
  };

  const renderState = (stateDesc: string) =>
    "CAPITAL FEDERAL" !== stateDesc?.toUpperCase() ? ` - ${stateDesc}` : null;

  return (
    <GenericBoxItem
      data-name="address_container"
      style={{
        display: 'grid',
        gridTemplateRows: '1fr',
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0
      }}>
      <GenericBoxItem sx={{ m: 0, p: 0 }}>
        {address.street} N&deg; {address.door}
      </GenericBoxItem>

      {(address.floor || address.unit) && <GenericBoxItem sx={{ m: 0, p: 0 }}>
        {address.floor && "Piso " + address.floor}
        {address.unit && " Dpto " + address.unit}
      </GenericBoxItem>
      }
      <GenericBoxItem sx={{ m: 0, p: 0 }}>
        {address.city} ({address.zip}){renderState(address.state)}
        {showMap && (
          <Button onClick={toggleMapView}>
            {mapText}
          </Button>
        )}
      </GenericBoxItem>

      {showMap && isMapLoaded && (
        <GenericBoxItem className={mapClasses} sx={{ m: 0, p: 0 }}>
          <iframe
            title="googleMap"
            width="100%"
            frameBorder="0"
            style={{ height: "20rem" }}
            src={address.mapURL}
            allowFullScreen
          />
        </GenericBoxItem>
      )}
    </GenericBoxItem>
  );
};
