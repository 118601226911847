import React, { useEffect, useState } from "react";

import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", toggleVisibility);
    return () => document.removeEventListener("scroll", toggleVisibility);
  }, []);

  const toggleVisibility = () => setIsVisible(window.pageYOffset > 200);

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

  return (
    <div className="scroll-to-top" data-test-id="scrollTop">
      {isVisible && (
        <div onClick={scrollToTop}>
          <FontAwesomeIcon icon={"arrow-circle-up"} size={"3x"} />
        </div>
      )}
    </div>
  );
};
