import { UIAction, UIActionType } from "../actions/types";

export interface UIState {
  administrator: {
    searchClients: {
      adminId?: number;
      adminName?: string;
    };
  };
  dispatch?: any;
}
export default (state: UIState, action: UIAction) => {
  switch (action.type) {
    case UIActionType.SET_ADMIN: {
      return {
        ...state,
        administrator: {
          ...state.administrator,
          searchClients: {
            ...state.administrator.searchClients,
            adminId: action.payload.id,
            adminName: action.payload.name
          }
        }
      };
    }
    default:
      return { ...state };
  }
};
