import { ClientAction, ClientActionType } from "../actions/types";
import * as R from "ramda";
import { filterClients } from "./utils";
import { ExtinguishersList } from "../../extinguisher/extinguisher";

export interface ClientState {
  searchResults: Record<number, backend.client.GetClientDetailsResponseSuccess>;
  filterResults: number[];
  extinguishers: {
    list: ExtinguishersList;
    loading: boolean;
    error?: backend.common.ErrorResponse;
  };
  quotes: quote.UIModel[];
  loading: boolean;
  dispatch?: any;
  error?: backend.common.ErrorResponse;
  quote?: {
    id?: number;
    filename?: string;
    emailingError?: backend.common.ErrorResponse;
    emailing?: boolean;
    emailed?: boolean;
  };
}
export default (state: ClientState, action: ClientAction): ClientState => {
  switch (action.type) {
    case ClientActionType.SEARCH_SUCCESS: {
      return {
        ...state,
        searchResults: action.payload,
        filterResults: getClientIdsFormattedFromObject(action.payload),
        loading: false,
        error: undefined
      };
    }

    case ClientActionType.SEARCH_INIT:
    case ClientActionType.GET_DETAILS_INIT:
    case ClientActionType.CREATE_INIT:
    case ClientActionType.EDIT_INIT:
    case ClientActionType.LINK_EXTINGUISHERS_LIST_INIT:
    case ClientActionType.DOWNLOAD_QUOTE_INIT:
      // case ClientActionType.UNLINK_EXTINGUISHER_INIT: on purpose
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case ClientActionType.SEARCH_FAILURE:
    case ClientActionType.GET_DETAILS_FAILURE:
    case ClientActionType.CREATE_FAILURE:
    case ClientActionType.EDIT_FAILURE:
    case ClientActionType.LINK_EXTINGUISHERS_LIST_FAILURE:
    case ClientActionType.DOWNLOAD_QUOTE_FAILURE:
      // case ClientActionType.UNLINK_EXTINGUISHER_FAILURE: //on purpose
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ClientActionType.GET_DETAILS_SUCCESS: {
      const clientFromStore = state.searchResults[action.payload.id];
      // need to merge client just retrieved from backend with existing one on store
      if (typeof clientFromStore === "undefined") {
        return {
          ...state,
          searchResults: {
            ...state.searchResults,
            [action.payload.id]: action.payload
          },
          loading: false,
          error: undefined
        };
      } else {
        const clientsFromStoreWithoutNew = R.omit(
          [String(action.payload.id)],
          state.searchResults
        );

        return {
          ...state,
          searchResults: {
            ...clientsFromStoreWithoutNew,
            [action.payload.id]: action.payload
          },
          loading: false,
          error: undefined
        };
      }
    }
    case ClientActionType.DO_FILTER: {
      const filteredClients = R.isEmpty(action.payload)
        ? state.searchResults
        : filterClients(action.payload, state.searchResults);
      return {
        ...state,
        filterResults: getClientIdsFormattedFromObject(filteredClients)
      };
    }
    case ClientActionType.UNLINK_EXTINGUISHER: {
      const extinguisherRequested = state.extinguishers.list.all[action.payload.serialNumber];
      delete state.extinguishers.list.all[action.payload.serialNumber];
      const byType = state.extinguishers.list.byType[extinguisherRequested.type];
      const idx = byType.indexOf(extinguisherRequested.serialNumber)
      byType.splice(idx, 1);
      return {
        ...state,
      };
    }

    case ClientActionType.RESET_FILTER: {
      return {
        ...state,
        filterResults: getClientIdsFormattedFromObject(state.searchResults),
        error: undefined
      };
    }

    case ClientActionType.GET_EXTINGUISHERS_INIT:
      return {
        ...state,
        extinguishers: { ...state.extinguishers, loading: true }
      };
    case ClientActionType.GET_EXTINGUISHERS_SUCCESS:
      return {
        ...state,
        extinguishers: {
          list: action.payload,
          loading: false
        }
      };
    case ClientActionType.GET_EXTINGUISHERS_FAILURE:
      return {
        ...state,
        extinguishers: {
          ...state.extinguishers,
          loading: false,
          error: action.payload
        }
      };
    case ClientActionType.CREATE_SUCCESS:
    case ClientActionType.EDIT_SUCCESS:
    case ClientActionType.LINK_EXTINGUISHERS_LIST_SUCCESS:
    case ClientActionType.UNLINK_EXTINGUISHER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined
      };
    case ClientActionType.DO_QUOTE_INIT:
      return {
        ...state,
        loading: true,
        quote: undefined,
        error: undefined
      };
    case ClientActionType.DO_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        quote: {
          id: action.payload.quoteNumber,
          filename: action.payload.filename
        },
        error: undefined
      };
    case ClientActionType.DO_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        quote: undefined,
        error: action.payload
      };
    case ClientActionType.LIST_QUOTES_INIT:
      return {
        ...state,
        loading: true,
        quotes: [],
        error: undefined
      };
    case ClientActionType.LIST_QUOTES_SUCCESS:
      return {
        ...state,
        quotes: action.payload, //TODO: tie quotes to specific client
        loading: false,
        error: undefined
      };
    case ClientActionType.LIST_QUOTES_FAILURE:
      return {
        ...state,
        loading: false,
        quotes: [],
        error: action.payload
      };

    case ClientActionType.SEND_QUOTE_EMAIL_RESET:
      return {
        ...state,
        quote: undefined
      };
    case ClientActionType.SEND_QUOTE_EMAIL_INIT:
      return {
        ...state,
        quote: {
          ...state.quote,
          emailing: true,
          emailingError: undefined,
          emailed: undefined
        }
      };
    case ClientActionType.SEND_QUOTE_EMAIL_SUCCESS:
      return {
        ...state,
        quote: {
          ...state.quote,
          emailing: false,
          emailed: true
        }
      };
    case ClientActionType.SEND_QUOTE_EMAIL_FAILURE:
      return {
        ...state,
        quote: {
          ...state.quote,
          emailing: false,
          emailingError: action.payload
        }
      };
    case ClientActionType.DOWNLOAD_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined
      }
    default:
      return { ...state };
  }
};

const getClientIdsFormattedFromObject = (
  clients: object
): ClientState["filterResults"] => R.compose(R.map(parseInt), R.keys)(clients);
