import { ClientActionType, ClientAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import {
  GetClientDetailsActionRequest,
  GetClientDetailsActionResponse
} from "./index.d";

const clientDetailsSuccess = (
  data: backend.client.GetClientDetailsResponseSuccess
): ClientAction => ({
  type: ClientActionType.GET_DETAILS_SUCCESS,
  payload: data
});
const clientDetailsFailure = (
  error: backend.common.ErrorResponse
): ClientAction => ({
  type: ClientActionType.GET_DETAILS_FAILURE,
  payload: error
});
const clientDetailsInit = (): ClientAction => ({
  type: ClientActionType.GET_DETAILS_INIT
});
export const getClientDetails = ({ id }: GetClientDetailsActionRequest) => (
  dispatch: React.Dispatch<any>
): GetClientDetailsActionResponse => {
  dispatch(clientDetailsInit());
  return doCall({
    url: `/api/client/${id}`
  })
    .then((response) => {
      const data = response as backend.client.GetClientDetailsResponseSuccess;
      dispatch(clientDetailsSuccess(data));
      return Promise.resolve(data);
    })
    .catch((error: backend.common.ErrorResponse) =>
      dispatch(clientDetailsFailure(error))
    );
};
