import React from 'react';

import Box from "@mui/material/Box";
import { BoxProps } from "@mui/material";

//@ts-ignore
export const hasError = (touched, errors, field: string): boolean => Boolean(touched[field] && errors[field])
//@ts-ignore
export const getErrorText = (errors, field: string): string => errors[field] as string;

export const FormFieldBoxItem = (props: BoxProps) => {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '95%' },
            }}
            {...other}
        />
    );
}