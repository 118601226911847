import { ExtinguisherTypeActionType, ExtinguisherTypeAction } from "./types";
import { doCall } from "../../../../utils/apiCall";

const extinguisherTypesLoadSuccess = (
  data: backend.extTypeNS.LoadAllResponseSuccess
): ExtinguisherTypeAction => ({
  type: ExtinguisherTypeActionType.LOAD_SUCCESS,
  payload: data
});
const extinguisherTypesLoadFailure = (): ExtinguisherTypeAction => ({
  type: ExtinguisherTypeActionType.LOAD_FAILURE
});
const extinguisherTypesLoadInit = (): ExtinguisherTypeAction => ({
  type: ExtinguisherTypeActionType.LOAD_INIT
});

export const loadExtinguisherTypes = () => (dispatch: React.Dispatch<any>) => {
  dispatch(extinguisherTypesLoadInit());
  return doCall({
    url: "/api/extinguisher/type"
  })
    .then((data) => {
      dispatch(
        extinguisherTypesLoadSuccess(
          data as backend.extTypeNS.LoadAllResponseSuccess
        )
      );
    })
    .catch((_: backend.common.ErrorResponse) => {
      dispatch(extinguisherTypesLoadFailure());
    });
};
