import {
  GetExtinguishersToAuditAction,
  GetExtinguishersToAuditActionType,
  GetExtinguishersToAuditRequest
} from "../actions/index.d";
import { doCall } from "../../../../utils/apiCall";

const getExtinguishersToAuditSuccess = (
  data: extinguisher.ClientToAuditUIModel[]
): GetExtinguishersToAuditAction => ({
  type: GetExtinguishersToAuditActionType.SEARCH_SUCCESS,
  payload: data
});
const getExtinguishersToAuditFailure = (
  error: backend.common.ErrorResponse
): GetExtinguishersToAuditAction => ({
  type: GetExtinguishersToAuditActionType.SEARCH_FAILURE,
  payload: error
});
const getExtinguishersToAuditInit = (
  month: number
): GetExtinguishersToAuditAction => ({
  type: GetExtinguishersToAuditActionType.SEARCH_INIT,
  payload: { month }
});

export const getExtinguishersToAudit = ({
  month
}: GetExtinguishersToAuditRequest) => (dispatch: React.Dispatch<any>) => {
  dispatch(getExtinguishersToAuditInit(Number(month)));
  return doCall({
    url: `/api/extinguisher/audit/${month}`
  })
    .then((data) => {
      dispatch(
        getExtinguishersToAuditSuccess(
          data as extinguisher.ClientToAuditUIModel[]
        )
      );
    })
    .catch((error: backend.common.ErrorResponse) => {
      dispatch(getExtinguishersToAuditFailure(error));
    });
};
