/** @jsx jsx */
import { Formik, Field, Form } from "formik";
import { css, jsx } from "@emotion/react";

import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { Stack, Button, MenuItem } from '@mui/material';
import { LoadingButton } from "@mui/lab";

import { search as searchExtinguisher } from "../../../domain/extinguisher/actions/search";
import { searchFormSchema, formInitialValues } from './search-form-schema';
import { TimePicker } from "../../../components/shared/time-picker";
import { StatusState } from "../../../store/index.d";
import { getErrorText, hasError, FormFieldBoxItem } from "../../../components/shared/form-utils";
import { FIELD_LENGTH_SERIE, restrictNumberOnly } from "../../../utils/form.utils";

interface SearchForm {
    brandTypes: extinguisherBrand.UIModel[];
    capacityTypes: extinguisherCapacity.UIModel[];
    typeTypes: extinguisherType.UIModel[];
    statusTypes: StatusState,
    dispatchFn: Function;
    onSuccess: Function;
}
export const SearchForm = ({
    brandTypes,
    capacityTypes,
    typeTypes,
    statusTypes,
    onSuccess,
    dispatchFn
}: SearchForm) => {
    const dropdownAllElement = { id: 0, desc: 'todos' }

    const brandTypesWithAll = [dropdownAllElement, ...brandTypes];
    const capacityTypesWithAll = [dropdownAllElement, ...capacityTypes];
    const typeTypesWithAll = [dropdownAllElement, ...typeTypes];
    const hasClientTypes = [dropdownAllElement, { id: 1, desc: 'SI' }, { id: 2, desc: 'NO' }]

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={searchFormSchema}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                //@ts-ignore
                searchExtinguisher(values)(dispatchFn)
                    .then(_ => onSuccess())
                    .finally(() => setSubmitting(false));
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
                setFieldValue,
                setFieldTouched,
                /* and other goodies */
            }) => {
                const formikProps = (name: string): StandardTextFieldProps => ({
                    variant: "standard",
                    name,
                    //@ts-ignore
                    value: values[name],
                    error: hasError(touched, errors, name),
                    helperText: getErrorText(errors, name),
                    onChange: handleChange(name),
                    onBlur: handleBlur(name),
                    autoComplete: "off"
                });

                return (
                    <Form css={css`
                    display: grid;
                    grid-template-areas:
                        'serial brand'
                        'type capacity'
                        'start end'
                        'hasCli status'
                        'buttons buttons'
                    `}>
                        <FormFieldBoxItem style={{ gridArea: 'serial' }}>
                            <TextField
                                type="tel"
                                inputProps={{ maxLength: FIELD_LENGTH_SERIE }}
                                placeholder="Ingrese numero de serie"
                                label="Numero Serie"
                                {...formikProps("serie")}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') return handleSubmit();
                                    restrictNumberOnly(e);
                                }}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'brand' }}>
                            <TextField
                                select
                                label="Marca"
                                {...formikProps("brandId")}
                            >
                                {brandTypesWithAll.map((e) => (
                                    <MenuItem key={`brand_${e.id}`} value={e.id}>
                                        {e.desc}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'type' }}>
                            <TextField
                                select
                                label="Tipo"
                                {...formikProps("typeId")}
                            >
                                {typeTypesWithAll.map((e) => (
                                    <MenuItem key={`type_${e.id}`} value={e.id}>
                                        {e.desc}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'capacity' }}>
                            <TextField
                                select
                                label="Capacidad"
                                {...formikProps("capacityId")}
                            >
                                {capacityTypesWithAll.map((e) => (
                                    <MenuItem key={`capacity_${e.id}`} value={e.id}>
                                        {e.desc}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'start' }}>
                            <Field
                                component={TimePicker}
                                label="F Venc Desde"
                                {...formikProps("expirationDateStart")}
                            />

                        </FormFieldBoxItem>
                        <FormFieldBoxItem style={{ gridArea: 'end' }}>

                            <Field
                                component={TimePicker}
                                label="F Venc Hasta"
                                {...formikProps("expirationDateEnd")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'hasCli' }}>
                            <TextField
                                select
                                label="Asociado a cliente?"
                                {...formikProps("hasClient")}
                            >
                                {hasClientTypes.map((e) => (
                                    <MenuItem key={`has-cli-${e.id}`} value={e.id}>
                                        {e.desc}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'status' }}>
                            <TextField
                                select
                                label="Estado"
                                {...formikProps("statusId")}
                            >
                                {Object.entries(statusTypes).map((e) => (
                                    <MenuItem key={`status_${e[0]}`} value={e[0]}>
                                        {e[1]}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormFieldBoxItem>

                        <FormFieldBoxItem style={{ gridArea: 'buttons' }}>
                            <Stack direction="row" spacing={2}>
                                <LoadingButton variant="contained"
                                    loading={isSubmitting}
                                    type="submit"
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Buscar
                                </LoadingButton>
                                <Button variant="contained" color="secondary"
                                    onClick={() => {
                                        resetForm(formInitialValues);
                                    }}
                                    type="button"
                                >Reset</Button>
                            </Stack>
                        </FormFieldBoxItem>
                    </Form>
                );
            }}
        </Formik >
    );
};