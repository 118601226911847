import React from "react";
import { Formik, Form } from "formik";

import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { LoadingButton } from "@mui/lab";

import { save as saveProduct } from "../../../domain/product/actions/save";
import { edit as editProduct } from "../../../domain/product/actions/edit";

import { createFormSchema, formInitialValues, FIELD_LENGTH_DESC, FIELD_LENGTH_DESC_SHORT } from './create-form-schema';

import { getErrorText, hasError, FormFieldBoxItem } from "../../../components/shared/form-utils";

interface CreateForm {
    dispatchFn: Function;
    onSuccess: Function;
    initialValues?: backend.productNS.EditProductRequest;
}
export const CreateForm = ({
    onSuccess,
    dispatchFn,
    initialValues
}: CreateForm) => {
    const isEditing = typeof initialValues !== "undefined";
    const actionFn = isEditing ? editProduct : saveProduct;
    const _formInitialValues = isEditing ? initialValues : formInitialValues;

    return (
        <Formik
            initialValues={_formInitialValues}
            validationSchema={createFormSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                //@ts-ignore
                actionFn(values)(dispatchFn).then(_ => onSuccess())
                    .finally(() => setSubmitting(false));
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
                setFieldValue,
                setFieldTouched,
                dirty
                /* and other goodies */
            }) => {
                console.log("gon, form error", { errors }, { values });
                const formikProps = (name: string): StandardTextFieldProps => ({
                    variant: "standard",
                    name,
                    //@ts-ignore
                    value: values[name],
                    error: hasError(touched, errors, name),
                    helperText: getErrorText(errors, name),
                    onChange: handleChange(name),
                    onBlur: handleBlur(name),
                    autoComplete: "off"
                });
                return (
                    <Form>
                        <FormFieldBoxItem>
                            <TextField
                                inputProps={{ maxLength: FIELD_LENGTH_DESC }}
                                placeholder="Ingrese description"
                                label="Descripcion"
                                {...formikProps("desc")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem>
                            <TextField
                                inputProps={{ maxLength: FIELD_LENGTH_DESC_SHORT }}
                                placeholder="Ingrese abreviacion"
                                label="Abreviacion"
                                {...formikProps("descShort")}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem>
                            <Stack direction="row" spacing={2}>
                                <LoadingButton variant="contained"
                                    loading={isSubmitting}
                                    type="submit"
                                    disabled={isEditing && !dirty}
                                >
                                    {isEditing ? "Modificar" : "Agregar"}
                                </LoadingButton>
                                <Button variant="contained" color="secondary"
                                    onClick={() => resetForm(_formInitialValues)}
                                    type="button"
                                >Reset</Button>
                            </Stack>
                        </FormFieldBoxItem>
                    </Form>
                );
            }}
        </Formik >
    );
};