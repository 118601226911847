export enum DocumentActionType {
  LOAD_INIT = "@document/LOAD_INIT",
  LOAD_SUCCESS = "@document/LOAD_SUCCESS",
  LOAD_FAILURE = "@document/LOAD_FAILURE"
}

export type DocumentAction =
  | { type: DocumentActionType.LOAD_INIT }
  | {
      type: DocumentActionType.LOAD_SUCCESS;
      payload: documento.UIModel[];
    }
  | {
      type: DocumentActionType.LOAD_FAILURE;
      payload: backend.common.ErrorResponse;
    };
