import React, { useContext } from "react";

import { AppContext } from "../../store";
import { ConfigGenericContainer } from "../../components/shared/config-item/config-generic-container";

export const DocumentContainer = () => {
  const { document: documentState } = useContext(AppContext);

  return (
    <ConfigGenericContainer
      configItemSpecificState={documentState}
    ></ConfigGenericContainer>
  );
};
