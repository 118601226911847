import { ClientActionType, ClientAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import { ListQuotesActionRequest, ListQuotesActionResponse } from "./index.d";

const clientListQuotesSuccess = (
  data: backend.quote.ListQuotesResponseSuccess
): ClientAction => ({
  type: ClientActionType.LIST_QUOTES_SUCCESS,
  payload: data
});
const clientListQuotesFailure = (
  error: backend.common.ErrorResponse
): ClientAction => ({
  type: ClientActionType.LIST_QUOTES_FAILURE,
  payload: error
});
const clientListQuotesInit = (): ClientAction => ({
  type: ClientActionType.LIST_QUOTES_INIT
});

export const listQuotes = ({ clientId }: ListQuotesActionRequest) => (
  dispatch: React.Dispatch<any>
): ListQuotesActionResponse => {
  dispatch(clientListQuotesInit());
  return doCall({
    url: `/api/client/${clientId}/quotes`
  })
    .then((response) => {
      const data = response as backend.quote.ListQuotesResponseSuccess;
      dispatch(clientListQuotesSuccess(data));
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(clientListQuotesFailure(error as backend.common.ErrorResponse));
    });
};
