// backend: src/extinguisher/controller/extinguisher.schema.ts

import * as Yup from "yup";

import {
    VALIDATION_MSG_REQUIRED, VALIDATION_MSG_NUMBER, VALIDATION_MSG_POSITIVE, VALIDATION_MSG_INTEGER, VALIDATION_MSG_DATE,
    VALIDATION_REGEX_NUMBER
} from "../../../utils/form.utils";

const FIELD_LENGTH_SERIE = 20;

export const createFormSchema = Yup.object().shape({
    serie: Yup.string()
        .required(VALIDATION_MSG_REQUIRED)
        .max(FIELD_LENGTH_SERIE)
        .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER),

    brandId: Yup.number()
        .typeError(VALIDATION_MSG_NUMBER)
        .required(VALIDATION_MSG_REQUIRED)
        .integer(VALIDATION_MSG_INTEGER)
        .positive(VALIDATION_MSG_POSITIVE),

    capacityId: Yup.number()
        .typeError(VALIDATION_MSG_NUMBER)
        .required(VALIDATION_MSG_REQUIRED)
        .integer(VALIDATION_MSG_INTEGER)
        .positive(VALIDATION_MSG_POSITIVE),

    typeId: Yup.number()
        .typeError(VALIDATION_MSG_NUMBER)
        .required(VALIDATION_MSG_REQUIRED)
        .integer(VALIDATION_MSG_INTEGER)
        .positive(VALIDATION_MSG_POSITIVE),

    manufactureMonth: Yup.number()
        .typeError(VALIDATION_MSG_NUMBER)
        .required(VALIDATION_MSG_REQUIRED)
        .integer(VALIDATION_MSG_INTEGER)
        .positive(VALIDATION_MSG_POSITIVE)
        .min(1)
        .max(12),

    manufactureYear: Yup.number()
        .typeError(VALIDATION_MSG_NUMBER)
        .required(VALIDATION_MSG_REQUIRED)
        .integer(VALIDATION_MSG_INTEGER)
        .positive(VALIDATION_MSG_POSITIVE)
        .min(1900)
        .max(3000),

    expirationDate: Yup.date()
        .typeError(VALIDATION_MSG_DATE)
        .required(VALIDATION_MSG_REQUIRED),

    clientId: Yup.number()
        .typeError(VALIDATION_MSG_NUMBER)
        .integer(VALIDATION_MSG_INTEGER)
        .positive(VALIDATION_MSG_POSITIVE)
});

export const formInitialValues: backend.extinguisherNS.EditExtinguisherRequest = {
    serie: "",
    brandId: 1,
    capacityId: 1,
    typeId: 1,
    manufactureMonth: "" as unknown as number,
    manufactureYear: "" as unknown as number,
    clientId: "" as unknown as number,
    expirationDate: undefined as unknown as Date,
    statusId: 1,
}