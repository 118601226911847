import React, { useContext, Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@mui/material";

import { AppContext } from "../../../store";
import Loader from "../../../components/shared/loader";

import { PageTitle } from "../../../components/shared/page-title";
import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { GoBackButton } from "../../../components/shared/go-back";
import { ClientCreateEditForm } from "./create-form";
import { ErrorNotification } from "../../../components/shared/error";
import { GenericBoxItem } from "../../../components/shared/card-box-container";

export default function ({ match, history }: RouteComponentProps) {
  const {
    client: clientState,
    seller: sellerState,
    industry: industryState,
    iva: ivaState,
    document: documentState
  } = useContext(AppContext);

  return (
    <Fragment>
      <PageTitle title={"Agregar Cliente"}></PageTitle>

      <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>
        <GenericBoxItem>
          <h3>Agregar Cliente</h3>
        </GenericBoxItem>

        {clientState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
        {clientState.error && <GenericBoxItem><ErrorNotification error={clientState.error} /></GenericBoxItem>}

        <GenericBoxItem>
          <ClientCreateEditForm
            documentTypes={documentState.all}
            ivaTypes={ivaState.all}
            sellerTypes={sellerState.all}
            industryTypes={industryState.all}
            onSaveSuccess={(clientId) => history.push('/success', { operation: 'CLI_CREATE', clientId })}
          />
        </GenericBoxItem>

        <GenericBoxItem>
          <GoBackButton history={history} />
        </GenericBoxItem>
      </Box>
      <ScrollToTop />
    </Fragment>
  );
};