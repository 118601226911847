import React from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { GenericBoxItem } from '../card-box-container';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    'cursor': 'pointer'
}));

interface IExtinguisherList {
    elements: Array<Array<{
        value: string | number;
        onClick?: Function;
    }>>,
    columns: Array<string>
    onDelete?: Function,
    onRowClick?: (_: string | number) => void,
    renderNoElements: React.FunctionComponent
}
export const GenericTable = ({ elements, columns, onDelete, onRowClick, renderNoElements }: IExtinguisherList) => {
    if (elements.length === 0) return renderNoElements({});

    return (
        <>
            <GenericBoxItem sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Cantidad de Items: {elements.length}</GenericBoxItem>
            <GenericBoxItem>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((value, idx) => (
                                    <StyledTableCell key={`col-${idx}`}>{value}</StyledTableCell>
                                ))}
                                <StyledTableCell></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {elements.map((row, idx) => {
                                return (
                                    <StyledTableRow key={`row-${idx}`} {...(onRowClick && {
                                        onClick: (_) => onRowClick!(row[0].value)
                                    })}>
                                        <StyledTableCell component="th" scope="row">
                                            {row[0].value}
                                        </StyledTableCell>
                                        <StyledTableCell>{row[1].value}</StyledTableCell>
                                        <StyledTableCell>{row[2].value}</StyledTableCell>
                                        <StyledTableCell>{row[3].value}</StyledTableCell>
                                        <StyledTableCell>
                                            {onDelete && <DeleteIcon color="action" onClick={() => onDelete(row[0].value)} />}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </GenericBoxItem>
        </>
    );
}
