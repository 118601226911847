import { AdministratorActionType, AdministratorAction } from "./types";
import { doCall } from "../../../utils/apiCall";

const administratorsLoadSuccess = (
  data: administrator.UIModel[]
): AdministratorAction => ({
  type: AdministratorActionType.LOAD_SUCCESS,
  payload: data
});
const administratorsLoadFailure = (
  error: backend.common.ErrorResponse
): AdministratorAction => ({
  type: AdministratorActionType.LOAD_FAILURE,
  payload: error
});
const administratorsLoadInit = (): AdministratorAction => ({
  type: AdministratorActionType.LOAD_INIT
});

export const loadAdministrators = () => (dispatch: React.Dispatch<any>) => {
  dispatch(administratorsLoadInit());
  return doCall({
    url: "/api/administrator"
  })
    .then((data) => {
      dispatch(administratorsLoadSuccess(data as administrator.UIModel[]));
    })
    .catch((error: backend.common.ErrorResponse) => {
      dispatch(administratorsLoadFailure(error));
    });
};
