import React, { useState } from 'react';

import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';

import esLocale from "date-fns/locale/es";
import { useEffect } from 'react';

export const TimePicker = (props: any) => {
    const { onChange, field, noDay, error, helperText, ...rest } = props;

    const [selectedDate, setSelectedDate] = useState<null | Date>(null);

    useEffect(() => {
        const hasDate = typeof field.value !== "undefined";
        const desideredDate = hasDate ? new Date(field.value) : null;
        setSelectedDate(desideredDate)
    }, [field.value])

    const properties = {
        variant: "dialog",
        value: selectedDate,
        //@ts-ignore
        onChange: (newValue) => { setSelectedDate(newValue); onChange(newValue) },
        format: "dd/MM/yyyy",
        ...(noDay && {
            format: 'MM/yyyy',
            views: ['month', 'year']
        })
    };

    return (
        <LocalizationProvider dateAdapter={DateAdapter} locale={esLocale}>
            <DatePicker {...rest} {...properties}
                renderInput={(params) => <TextField {...params} error={error} helperText={helperText} />}
            />
        </LocalizationProvider>
    );
}