/** @jsx jsx */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { Box } from "@mui/material";
import { css, jsx } from "@emotion/react";


import { GenericBoxItem } from "../card-box-container";
interface ExpandPanelType {
  title: string;
}

export const ExpandPanel: React.FunctionComponent<ExpandPanelType> = ({
  title = "",
  children
}) => {
  const [isSearchPanelCollapsed, setIsSearchPanelCollapsed] = useState(false);

  const toggleSearchPanelVisibility = () =>
    setIsSearchPanelCollapsed(!isSearchPanelCollapsed);

  const chevronIcon = !isSearchPanelCollapsed ? "chevron-up" : "chevron-down";
  const searchPanelClass = classnames({
    collapse: isSearchPanelCollapsed,
    "collapse.show": !isSearchPanelCollapsed
  });
  return (
    <GenericBoxItem style={{ border: '1px solid' }} sx={{ p: 0, m: 0 }}
      css={css`
    @media print {
      & {
        border: none !important;
      }
    }
  `}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '10fr 1fr', bgcolor: "primary.main" }}
        onClick={toggleSearchPanelVisibility}
        css={css`
        h6 {
          color: white;
        }
    @media print {
      & {
        display: none;
      }
    }
  `}>
        <GenericBoxItem>
          <h6>{title}</h6>
        </GenericBoxItem>
        <GenericBoxItem>
          <FontAwesomeIcon icon={chevronIcon} />
        </GenericBoxItem>
      </Box>
      <GenericBoxItem className={searchPanelClass}
        css={css`
       @media print {
         & {
           margin: 0;
           padding: 0;
         }
       }
     `}>
        {children}
      </GenericBoxItem>
    </GenericBoxItem>
  );
};