import * as Yup from "yup";
export const formValidationSchema = Yup.object().shape({
  products: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number()
          .integer("Y el producto vieja???")
          .positive('Selecciona uno!!!')
        , // these constraints take precedence
        amount: Yup.number()
          .positive("Mas que 0!")
          .min(0, "Mas que 0!")
          .typeError("Formato invalido!")
          .required("Y el precio vieja? Se lo vas a dar gratis?"),
        qty: Yup.number()
          .positive("Mas que 0!")
          .integer("Sin decimales!")
          .min(1, "Mas que 0!")
          .typeError("Formato invalido!")
          .required("Y la cantidad???"), // these constraints take precedence
        discount: Yup.number()
          .positive("Mas que 0!")
          .integer("Sin decimales!")
          .min(0, "Mas que 0!")
          .max(100, "Menos que 100!")
          .typeError("Formato invalido!")
          .required("Y el descuento???")
      })
    )
    .required("Debe agregar, al menos, un producto para hacer el presupuesto!") // these constraints are shown if and only if inner constraints are satisfied
    .min(1, "Minimum of 1 producto"),
  comments: Yup.string().max(500, "Taaaaanto???? Usa menos de 500 caracteres!"),
  notes: Yup.array().of(Yup.string()),
  expirationDays: Yup.number()
    .integer("Sin decimales!")
    .positive("Mayor que 0!")
    .min(1, "Debe ser mayor que 0!")
    .typeError("Formato invalido!")
    .required("Por cuantos dias es valido el presupuesto?")
});

const EXPIRATION_DAYS = 15;

export const initialValues: backend.client.DoQuoteFormSchema = {
  products: [
    {
      id: "0",
      qty: "" as unknown as number,
      amount: "" as unknown as number,
      prodTotal: 0,
      discount: 0
    }
  ],
  notes: [],
  comments: "",
  expirationDays: EXPIRATION_DAYS
};
