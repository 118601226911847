import { ClientActionType, ClientAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import {
  SearchClientActionRequest,
  SearchClientActionResponse
} from "./index.d";

const clientSearchSuccess = (
  data: backend.client.SearchClientResponseSuccess
): ClientAction => ({
  type: ClientActionType.SEARCH_SUCCESS,
  payload: data
});
const clientSearchFailure = (
  error: backend.common.ErrorResponse
): ClientAction => ({
  type: ClientActionType.SEARCH_FAILURE,
  payload: error
});
const clientSearchInit = (): ClientAction => ({
  type: ClientActionType.SEARCH_INIT
});

export const searchClient = ({
  id,
  name,
  fantasyName,
  street,
  province,
  phone
}: SearchClientActionRequest) => (
  dispatch: React.Dispatch<any>
): SearchClientActionResponse => {
    dispatch(clientSearchInit());
    return doCall({
      url: "/api/client",
      payload: { id, name, fantasyName, street, state: province, phone },
      method: "POST"
    })
      .then((data) =>
        dispatch(
          clientSearchSuccess(data as backend.client.SearchClientResponseSuccess)
        )
      )
      .catch((error: backend.common.ErrorResponse) =>
        dispatch(clientSearchFailure(error))
      );
  };
