import React, { useContext, useState, useEffect, Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { Alert, Box, Button } from "@mui/material";

import { AppContext } from "../../../store";
import { ClientQuoteListRouteParams } from "./types";
import { getClientDetails } from "../../../domain/client/actions/getDetails";
import { listQuotes } from "../../../domain/client/actions/listQuotes";

import Loader from "../../../components/shared/loader";

import { isInactive } from "../../../utils/client.utils";
import { PageTitle } from "../../../components/shared/page-title";
import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { GoBackButton } from "../../../components/shared/go-back";
import { ErrorNotification } from "../../../components/shared/error";
import { Notification } from "../../../components/shared/notification";
import { GenericBoxItem } from "../../../components/shared/card-box-container";
import { QuoteListTable } from "./table";

export default function ({
  match,
  history
}: RouteComponentProps<ClientQuoteListRouteParams>) {
  const {
    client: clientState,
  } = useContext(AppContext);
  const [clientId] = useState(parseInt(match.params.clientId));

  useEffect(() => {
    const client = clientState.searchResults[clientId] || undefined;
    console.log("useEffect!: clients ", clientState);
    if (typeof client === "undefined") {
      getClientDetails({ id: clientId })(clientState.dispatch);
    }
    listQuotes({ clientId })(clientState.dispatch);
  }, [clientId]);

  if (clientState.loading) return (<GenericBoxItem><Loader /></GenericBoxItem>)

  const client = clientState.searchResults[clientId];
  if (!client) return null;

  const renderTableResults = () => {
    if (clientState.loading) return null;

    if (clientState.quotes.length === 0) return <Notification title="Sin prespuestos!" msg="El cliente no posee presupuestos aun" warn />

    return <QuoteListTable
      quotes={clientState.quotes}
      state={clientState}
      clientId={client.id} />
  }

  const isClientInactive = isInactive(client);

  return (
    <Fragment>
      <PageTitle title={`[${client.id}] ${client.name}: Presupuestos`}></PageTitle>

      <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>

        <GenericBoxItem>
          <h3>Listado de Presupuestos</h3>
          <h4>[{client.id}] {client.name}</h4>
        </GenericBoxItem>


        {clientState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
        {clientState.error && <GenericBoxItem><ErrorNotification error={clientState.error} /></GenericBoxItem>}

        <GenericBoxItem>
          <Button variant="contained"
            disabled={isClientInactive}
            onClick={() => history.push(`/client/${client.id}/quote`, { client: client })}
          >PRESUPUESTAR
          </Button>
          {isClientInactive && <GenericBoxItem display="inline">
            <Alert severity="warning">El cliente se encuentra inactivo!"</Alert>
          </GenericBoxItem>}
        </GenericBoxItem>

        {renderTableResults()}

        <GenericBoxItem>
          <GoBackButton history={history} />
        </GenericBoxItem>

      </Box>
      <ScrollToTop />
    </Fragment >
  );
};