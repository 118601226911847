import {
  GetExtinguishersToAuditActionType,
  GetExtinguishersToAuditAction
} from "../actions/index.d";
export interface ExtinguisherAuditState {
  all: extinguisher.ClientToAuditUIModel[];
  month?: number;
  loading: boolean;
  error?: backend.common.ErrorResponse;
  dispatch?: any;
}
export default (
  state: ExtinguisherAuditState,
  action: GetExtinguishersToAuditAction
): ExtinguisherAuditState => {
  switch (action.type) {
    case GetExtinguishersToAuditActionType.SEARCH_SUCCESS: {
      return {
        ...state,
        all: action.payload,
        loading: false,
        error: undefined
      };
    }

    case GetExtinguishersToAuditActionType.SEARCH_INIT:
      return {
        ...state,
        loading: true,
        month: action.payload.month,
        error: undefined
      };
    case GetExtinguishersToAuditActionType.SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
};
