import React, { useState } from 'react';
import { AlertDialog } from '../../../components/shared/alert-dialog';

export interface IExtinguisherDialog {
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    message: string;
    title: string;
    label: string;
    actionFunction: Function;
}
export const AlertDialogWrapper: React.FunctionComponent<IExtinguisherDialog> = ({ title, message, label, actionFunction }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [hasError, setHasError] = useState(false);

    const onOkClick = () => {
        setIsLoading(true)
        setHasError(false)

        return actionFunction()
            .then(() => setIsSuccess(true))
            .catch(() => setHasError(true))
            .finally(() => setIsLoading(false))
    }

    return (<AlertDialog hasError={hasError} title={title} message={message} onConfirm={onOkClick} label={label} isLoading={isLoading} isSuccess={isSuccess} />)

}