/** @jsx jsx */
import { FunctionComponent, Fragment } from "react";
import { Link } from "react-router-dom";

import { ClientAddress } from "../client-search/components/client-address";
import { formatDate } from "../../utils/format";
import { CardBoxContainer, GenericBoxItem } from "../../components/shared/card-box-container";
import { Box } from "@mui/material";
import { css, jsx } from "@emotion/react";
interface ExtinguishersToAuditSearchResultsListProps {
  results: extinguisher.ClientToAuditUIModel[];
}
const getAuditDescription = (auditNumber: number): string => auditNumber === 1 ? "1ra" : auditNumber === 2 ? "2da" : "3ra";

export const ExtinguishersToAuditSearchResultsList: FunctionComponent<ExtinguishersToAuditSearchResultsListProps> = ({
  results
}) => {
  return (
    <Fragment>
      {results.map(({ expirationDate, qty, auditNumber, client }, idx) => {
        return (
          <CardBoxContainer className="card" key={`client-${idx}`}
            css={css`
              @media print {
                & {
                  border: 0;
                  border-bottom: 1px solid;
                  margin-bottom: 0 !important;
                }
                & + div {
                  margin: 0
                }
              }
            `}>
            <Box css={css`
              display: grid;
              grid-template-columns: 1fr;
              @media print {
                grid-template-columns: 10fr 2fr;
                font-size: .9rem;
                & div {
                  margin: 0;
                }
                & > div:first-of-type {
                  display: grid;
                  grid-template-columns: 4fr 8fr;
                  padding: 0;
                }
                & > div:first-of-type *:first-of-type {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
                button {
                  display: none
                };
                div[data-name="address_container"] {
                  display: block !important;
                  padding: 0;
                };
                div[data-name="address_container"] > div {
                  display: inline;
                };
                div[data-name="address_container"] > div:not(:last-child):after {
                  content: ' - ';
                };

                div.ext_audit_data {
                  padding: 0 !important;
                  text-align: right;
                };
                div.ext_audit_data > div {
                  padding-top: 0;
                  padding-bottom: 0;
                };
              }
            `}>
              <GenericBoxItem style={{ marginBottom: 0 }}>
                <Link to={`/client/details/${client.id}`}>
                  [{client.id}] {client.name}
                </Link>
                <ClientAddress address={client.address} />
              </GenericBoxItem>
              <GenericBoxItem className="ext_audit_data" style={{ marginTop: 0, paddingTop: 0 }}>
                <GenericBoxItem>{formatDate(expirationDate.toString())}</GenericBoxItem>
                <GenericBoxItem>{qty}</GenericBoxItem>
                <GenericBoxItem>{getAuditDescription(auditNumber)}</GenericBoxItem>
              </GenericBoxItem>
            </Box>
          </CardBoxContainer>
        );
      })}
    </Fragment>
  );
};
