import React, { useContext } from "react";

import { AppContext } from "../../store";
import { ConfigGenericContainer } from "../../components/shared/config-item/config-generic-container";

export const IndustryContainer = () => {
  const { industry: industryState } = useContext(AppContext);

  return (
    <ConfigGenericContainer
      configItemSpecificState={industryState}
    ></ConfigGenericContainer>
  );
};
