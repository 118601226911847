import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../../store';
import { getClientExtinguishers } from '../../../domain/client/actions/getExtinguishers';
import { ClientExtinguishers } from './extinguishers.component';
import { RouteComponentProps } from 'react-router-dom';
import { searchSuccess } from '../../../domain/extinguisher/actions/search';

interface ClientExtinguishersType {
  clientId: number;
  history: RouteComponentProps["history"];
}

export const ClientExtinguishersContainer: React.FunctionComponent<
  ClientExtinguishersType
> = ({ clientId, history }) => {
  const {
    client: clientState,
    extinguisherBrands,
    extinguisherCapacities,
    extinguisherTypes: extinguisherTypesState,
    extinguisher: extinguisherState
  } = useContext(AppContext);

  useEffect(() => {
    getClientExtinguishers({ id: clientId })(clientState.dispatch)
      .then(data => extinguisherState.dispatch(searchSuccess(data as extinguisher.UIModel[])));
  }, [clientId]);

  return (
    <ClientExtinguishers
      extinguishers={clientState.extinguishers}
      extinguisherBrands={extinguisherBrands}
      extinguisherCapacities={extinguisherCapacities}
      extinguisherTypesState={extinguisherTypesState}
      history={history}
      clientState={clientState}
    />
  );
};
