const ACCESS_TOKEN = "accessToken";
const ACCESS_TOKEN_EXPIRATION = "accessTokenExpiration";
export const getTokenFromStorage = () => localStorage.getItem(ACCESS_TOKEN);
export const setTokenInStorage = (token: string, expiresIn: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
  localStorage.setItem(ACCESS_TOKEN_EXPIRATION, expiresIn);
};

export const clearTokenFromStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(ACCESS_TOKEN_EXPIRATION);
};
