import {
    ExtinguisherAction,
    ExtinguisherActionType
} from "../actions/types";

export interface ExtinguisherState {
    loading: boolean;
    dispatch?: any;
    error?: backend.common.ErrorResponse,
    searchResults: Array<extinguisher.UIModel>
}

export default (
    state: ExtinguisherState,
    action: ExtinguisherAction
) => {
    switch (action.type) {
        case ExtinguisherActionType.CREATE_SUCCESS:
        case ExtinguisherActionType.EDIT_MASSIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined
            };

        case ExtinguisherActionType.SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                searchResults: action.payload
            };
        case ExtinguisherActionType.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                searchResults: state.searchResults.filter(e => e.serialNumber !== action.payload)
            };
        // on purpose. This is handle via variable on alert dialog 
        // so that the whole page does not re-render
        // case ExtinguisherActionType.DELETE_INIT: 
        //     return {
        //         ...state,
        //         loading: true
        //     }
        // case ExtinguisherActionType.DELETE_FAILURE:
        //     return {
        //         ...state,
        //         loading: false
        //     }
        case ExtinguisherActionType.CREATE_INIT:
        case ExtinguisherActionType.SEARCH_INIT:
        case ExtinguisherActionType.EDIT_MASSIVE_INIT:
            return {
                ...state,
                loading: true,
                error: undefined,
                searchResults: []
            };
        case ExtinguisherActionType.CREATE_FAILURE:
        case ExtinguisherActionType.EDIT_MASSIVE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case ExtinguisherActionType.SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                searchResults: []
            };
        default:
            return { ...state };
    }
};
