import { SellerActionType, SellerAction } from "./types";
import { doCall } from "../../../utils/apiCall";

const sellersLoadSuccess = (
  data: backend.sellerNS.LoadAllResponseSuccess
): SellerAction => ({
  type: SellerActionType.LOAD_SUCCESS,
  payload: data
});
const sellersLoadFailure = (
  error: backend.common.ErrorResponse
): SellerAction => ({
  type: SellerActionType.LOAD_FAILURE,
  payload: error
});
const sellersLoadInit = (): SellerAction => ({
  type: SellerActionType.LOAD_INIT
});

export const loadSellers = () => (dispatch: React.Dispatch<any>) => {
  dispatch(sellersLoadInit());
  return doCall({
    url: "/api/seller"
  })
    .then((data) => {
      dispatch(
        sellersLoadSuccess(data as backend.sellerNS.LoadAllResponseSuccess)
      );
    })
    .catch((error: backend.common.ErrorResponse) => {
      dispatch(sellersLoadFailure(error));
    });
};
