import React, { useContext, Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from '@mui/material';

import { AppContext } from "../../../store";
import Loader from "../../../components/shared/loader";

import { PageTitle } from "../../../components/shared/page-title";
import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { GoBackButton } from "../../../components/shared/go-back";
import { ExtinguisherCreateEditForm } from "./create-form";
import { ErrorNotification } from "../../../components/shared/error";
import { GenericBoxItem } from "../../../components/shared/card-box-container";

export default function ({ match, history }: RouteComponentProps) {
    const {
        extinguisherBrands: extinguisherBrandsState,
        extinguisherCapacities: extinguisherCapacitiesState,
        extinguisherTypes: extinguisherTypesState,
        extinguisher: extinguisherState
    } = useContext(AppContext);

    type extinguisherT = extinguisherBrand.UIModel | extinguisherCapacity.UIModel | extinguisherType.UIModel;
    const sortByDesc = (a: extinguisherT, b: extinguisherT) => a.desc.toUpperCase() < b.desc.toUpperCase() ? -1 : 1;
    const sortedBrands = Object.values(extinguisherBrandsState.all).sort(sortByDesc);
    const sortedCapacities = Object.values(extinguisherCapacitiesState.all).sort(sortByDesc);
    const sortedTypes = Object.values(extinguisherTypesState.all).sort(sortByDesc);

    return (
        <Fragment>
            <PageTitle title={"Agregar Extintor"}></PageTitle>

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>

                <GenericBoxItem>
                    <h3> Agregar Extintor</h3>
                </GenericBoxItem>

                {extinguisherState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
                {extinguisherState.error && <GenericBoxItem><ErrorNotification error={extinguisherState.error} /></GenericBoxItem>}

                <GenericBoxItem>
                    <ExtinguisherCreateEditForm
                        brandTypes={sortedBrands}
                        capacityTypes={sortedCapacities}
                        typeTypes={sortedTypes}
                        dispatchFn={extinguisherState.dispatch}
                        onSuccess={() => history.push(`/success`, { operation: 'EXT_CREATE' })}
                    />
                </GenericBoxItem>

                <GenericBoxItem>
                    <GoBackButton history={history} />
                </GenericBoxItem>
            </Box>
            <ScrollToTop />
        </Fragment>
    );
};