import React, { Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Box } from "@mui/material";

import Loader from "../../components/shared/loader";

import { ExtinguisherGenericItem } from "../extinguisher/components/extinguisher-generic-item";
import { ExtinguisherTypesState } from "../../domain/extinguisher/type/reducer";
import { ExtinguisherCapacitiesState } from "../../domain/extinguisher/capacity/reducer";
import { ExtinguisherBrandsState } from "../../domain/extinguisher/brand/reducer";
import { PageTitle } from "../../components/shared/page-title";
import { ScrollToTop } from "../../components/shared/scroll-to-top";
import { GoBackButton } from "../../components/shared/go-back";
import { Notification } from "../../components/shared/notification";
import { GenericBoxItem } from "../../components/shared/card-box-container";

const renderItems = (items: Record<number, extinguisherBrand.UIModel |
  extinguisherCapacity.UIModel |
  extinguisherBrand.UIModel>) => (
  Object.values(sortElements(items)).map((el) => (
    <ExtinguisherGenericItem
      key={`ext_${el.id}`}
      item={el}
    />
  )));

const sortElements = (unordered: Record<number, extinguisherBrand.UIModel | extinguisherCapacity.UIModel | extinguisherBrand.UIModel>) => {
  return Object.values({ ...unordered }).sort((a, b) => {
    var nameA = a.desc.toUpperCase();
    var nameB = b.desc.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  })
}
interface ExtinguisherGenericContainerType extends RouteComponentProps {
  extinguisherSpecificState:
  | ExtinguisherTypesState
  | ExtinguisherCapacitiesState
  | ExtinguisherBrandsState;
}
const container: React.FunctionComponent<ExtinguisherGenericContainerType> = ({
  extinguisherSpecificState,
  history
}) => {
  return (
    <Fragment>
      <PageTitle title="Listado de Elementos" />

      <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>

        {extinguisherSpecificState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
        {!extinguisherSpecificState.loading && !extinguisherSpecificState && (
          <GenericBoxItem>
            <Notification warn title="Sin resultados" msg="No se encontraron resultados!" />
          </GenericBoxItem>
        )}
        {!extinguisherSpecificState.loading &&
          extinguisherSpecificState &&
          <GenericBoxItem>
            {renderItems(extinguisherSpecificState.all)}
          </GenericBoxItem>
        }
        <GenericBoxItem>
          <GoBackButton history={history} />
        </GenericBoxItem>
      </Box>
      <ScrollToTop />
    </Fragment>
  );
};

export const ExtinguisherGenericContainer = withRouter(container);