import { ProductActionType, ProductAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import { loadProducts } from ".";

const saveProductSuccess = (): ProductAction => ({
    type: ProductActionType.CREATE_SUCCESS
});
const saveProductFailure = (
    error: backend.common.ErrorResponse
): ProductAction => ({
    type: ProductActionType.CREATE_FAILURE,
    payload: error
});
const saveProductInit = (): ProductAction => ({
    type: ProductActionType.CREATE_INIT
});

export const save = ({ desc, descShort }: backend.productNS.EditProductRequest) => (dispatch: React.Dispatch<any>) => {
    dispatch(saveProductInit());
    return doCall({
        url: `/api/product`,
        method: "POST",
        payload: {
            desc,
            descShort
        }
    })
        .then(() => {
            dispatch(saveProductSuccess());
            loadProducts()(dispatch)
            return Promise.resolve()
        })
        .catch((error: backend.common.ErrorResponse) => {
            dispatch(saveProductFailure(error));
            return Promise.reject(error)
        });
};
