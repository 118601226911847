import React, { useContext, Fragment, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@mui/material";

import { AppContext } from "../../../store";
import Loader from "../../../components/shared/loader";

import { PageTitle } from "../../../components/shared/page-title";
import { ScrollToTop } from "../../../components/shared/scroll-to-top";
import { GoBackButton } from "../../../components/shared/go-back";
import { ClientCreateEditForm, ClientCreateEditFormValues } from "../create/create-form";
import { ErrorNotification } from "../../../components/shared/error";
import { GenericBoxItem } from "../../../components/shared/card-box-container";

interface MatchParams {
    id: string;
}
export default function ({ history, location }: RouteComponentProps<MatchParams>) {
    const {
        client: clientState,
        seller: sellerState,
        industry: industryState,
        iva: ivaState,
        document: documentState
    } = useContext(AppContext);
    const [model, setModel] = useState<undefined | ClientCreateEditFormValues>(undefined);

    useEffect(() => {
        //@ts-ignore
        const { client: clientRequested } = location.state || {};
        const isFound = typeof clientRequested !== "undefined";
        if (!isFound) {
            history.push('/client/search');
            return;
        }

        const { id, name, fantasyName: fantasy,
            iva: iva_id, document, documentType: document_id,
            address1: {
                street: street1_name,
                city: city1_name,
                state: state1_name,
                door: door1,
                zip: zip1,
                floor: floor1,
                unit: unit1,
                lat: lat1,
                lng: lng1
            },
            tel1_area, tel1_num,
            tel2_area, tel2_num,
            tel3,
            hours,
            email, web,
            contact, comments,
            industry: industry_id,
            seller: seller_id,
            administration: administrator_id, auditoria,
            status,
            hydrants_expiration_date,
            hydrants_quantity
        } = clientRequested as backend.client.GetClientDetailsResponseSuccess;
        const transformedModel: ClientCreateEditFormValues = {
            id: String(id),
            administrator_id,
            audit: String(auditoria),

            street1_name,
            zip1,
            ...(unit1 && { unit1 }),
            ...(floor1 && { floor1 }),
            door1,
            state1_name,
            city1_name,
            lat1: lat1 as number,
            lng1: lng1 as number,

            ...(fantasy && { fantasy }),
            name,
            industry_id,
            seller_id,

            ...(web && { web }),
            ...(email && { email }),

            document_id,
            document,
            iva_id,

            ...(tel1_area && { tel1_area }),
            ...(tel1_num && { tel1_num }),

            ...(tel2_area && { tel2_area }),
            ...(tel2_num && { tel2_num }),
            ...(tel3 && { tel3 }),

            ...(comments && { comments }),
            ...(contact && { contact }),
            ...(hours && { hours }),

            ...(hydrants_expiration_date && { hydrants_expiration_date, hydrants_quantity }),

            statusId: status
        };
        setModel(transformedModel)
    }, [location.state, history])

    return (
        <Fragment>
            <PageTitle title={"Modificar Cliente"}></PageTitle>

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>
                <GenericBoxItem>
                    <h3>Modificar Cliente</h3>
                </GenericBoxItem>

                {clientState.loading && <GenericBoxItem><Loader /></GenericBoxItem>}
                {clientState.error && <GenericBoxItem><ErrorNotification error={clientState.error} /></GenericBoxItem>}

                <GenericBoxItem>
                    <ClientCreateEditForm
                        documentTypes={documentState.all}
                        ivaTypes={ivaState.all}
                        sellerTypes={sellerState.all}
                        industryTypes={industryState.all}
                        onSaveSuccess={() => history.push('/success', { operation: 'CLI_UPDATE', routeTo: `/client/details/${model?.id}` })}
                        initialValues={model}
                    />
                </GenericBoxItem>

                <GenericBoxItem>
                    <GoBackButton history={history} />
                </GenericBoxItem>
            </Box>
            <ScrollToTop />
        </Fragment>
    );
};