import { AdministratorState } from "./reducer";

export const getAdministratorClients = (
  state: AdministratorState,
  adminId: number | undefined
): Array<administrator.AdministratorClient> => {
  if (typeof adminId === "undefined") return [];
  return state.all[adminId] ? state.all[adminId].clients : [];
};

export const getAdministratorName = (
  state: AdministratorState,
  adminId: number | undefined
): string | undefined => {
  if (typeof adminId === "undefined") return undefined;
  return state.all[Number(adminId)]
    ? state.all[Number(adminId)].name
    : undefined;
};
