import { UserAction, UserActionType } from "../actions/types";

export interface UserState {
  loading: boolean;
  dispatch?: any;
  error?: backend.common.ErrorResponse;
}
export default (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActionType.LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined
      };
    }

    case UserActionType.LOAD_INIT:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case UserActionType.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return { ...state };
  }
};
