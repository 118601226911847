import React from "react";
import { Button } from "@mui/material";


export const PrintButton: React.FunctionComponent = () => (
    <Button
        variant="contained"
        onClick={() => window.print()}>
        Imprimir
    </Button>
);
