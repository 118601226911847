import React, { useContext } from "react";

import { AppContext } from "../../../store";
import { ExtinguisherGenericContainer } from "../extinguisher-generic-container";

export const ExtinguisherCapacityContainer = () => {
  const { extinguisherCapacities: extinguisherCapacityState } = useContext(
    AppContext
  );

  return (
    <ExtinguisherGenericContainer
      extinguisherSpecificState={extinguisherCapacityState}
    ></ExtinguisherGenericContainer>
  );
};
