import { ClientActionType } from "./types";
import { FilterClientRequest } from "./index.d";

const clientFilter = (data: FilterClientRequest) => ({
  type: ClientActionType.DO_FILTER,
  payload: data
});

const clientFilterReset = () => ({
  type: ClientActionType.RESET_FILTER
});

export const filterClients = (request: FilterClientRequest) => (
  dispatch: React.Dispatch<any>
): void => dispatch(clientFilter(request));

export const resetClientsFilter = () => (dispatch: React.Dispatch<any>): void =>
  dispatch(clientFilterReset());
