import React, { useState, MouseEventHandler } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface ILongMenu {
    itemHeight?: number;
    options: Array<{
        label: string;
        onClick?: MouseEventHandler;
        dialogAlertTitle?: string;
        dialogAlertMessage?: string;
        renderDialogAlert?: React.FunctionComponent<{ label: string, title: string, message: string }>;
        disabled?: boolean
    }>
}
export const LongMenu: React.FunctionComponent<ILongMenu> = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { options, itemHeight = 48 } = props;

    const handleClick = (event: any) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    return (
        <>
            { //@ts-ignore
                <IconButton
                    aria-label="More"
                    aria-owns={open ? 'long-menu' : null}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
            }
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: itemHeight * 4.5,
                        width: 200,
                    },
                }}
            >
                {options.map(option => (
                    <MenuItem
                        key={option.label}
                        disabled={option.disabled}
                        onClick={option.renderDialogAlert ? () => { } : option.onClick}>
                        {option.renderDialogAlert ?
                            option.renderDialogAlert({
                                label: option.label,
                                title: option.dialogAlertTitle as string,
                                message: option.dialogAlertMessage as string
                            })
                            : option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );

}