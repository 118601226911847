import React from "react";

const ERROR_CODE_MAPPINGS: Record<string, string> = {
  'ER_DUP_ENTRY': 'El elemento que intenta agregar ya existe!',
  'QUOTE_DOWNLOAD_ERROR': 'Error al intentar descargar el presupuesto solicitado! (QUOTE_DOWNLOAD_ERROR)',
  'EMAIL_QUOTE_EMAIL_FAILURE': 'Error al intentar enviar el prespuesto solicitado! (EMAIL_QUOTE_EMAIL_FAILURE)',
  'EMAIL_QUOTE_DOWNLOAD': 'Error al intentar enviar el prespuesto solicitado! (EMAIL_QUOTE_DOWNLOAD)'
};

interface ErrorNotification {
  error: backend.common.ErrorResponse
}

export const ErrorNotification: React.FunctionComponent<ErrorNotification> = ({ error }) => (
  <div className="alert alert-danger" role="alert">
    <strong>Se ha producido un error!</strong>
    <div>{ERROR_CODE_MAPPINGS[error.code] || error.message}</div>
  </div>
);
