// same as in backend

import { isEmpty, isNil } from "ramda";
import * as Yup from "yup";
import { FIELD_LENGTH_SERIE, VALIDATION_MSG_DATE, VALIDATION_MSG_INTEGER, VALIDATION_MSG_NUMBER, VALIDATION_MSG_POSITIVE, VALIDATION_MSG_REQUIRED, VALIDATION_REGEX_NUMBER } from "../../../utils/form.utils";

const isNilOrEmpty = (val: string | number | Date) => isEmpty(val) || isNil(val);
const isSpecificElementNotSelected = (val: number) => val === 0;

const SELECTION_NEEDED = 'Seleccione un elemento!'

export const searchFormSchema = Yup.object().shape({
    serie: Yup.string()
        .when(["brandId", "capacityId", "typeId", "hasClient", "expirationDateStart", "expirationDateEnd"], {
            is: (brandId: number, capacityId: number, typeId: number, hasClient: number, expirationDateStart: Date, expirationDateEnd: Date) =>
                isSpecificElementNotSelected(brandId) &&
                isSpecificElementNotSelected(capacityId) &&
                isSpecificElementNotSelected(typeId) &&
                isSpecificElementNotSelected(hasClient) &&
                isNilOrEmpty(expirationDateStart) &&
                isNilOrEmpty(expirationDateEnd),
            then: Yup.string()
                .required(VALIDATION_MSG_REQUIRED)
                .min(1)
                .max(FIELD_LENGTH_SERIE)
                .matches(VALIDATION_REGEX_NUMBER, VALIDATION_MSG_NUMBER)
        }),

    brandId: Yup.number()
        .when(["serie", "capacityId", "typeId", "hasClient", "expirationDateStart", "expirationDateEnd"], {
            is: (serie: string, capacityId: number, typeId: number, hasClient: number, expirationDateStart: Date, expirationDateEnd: Date) =>
                isNilOrEmpty(serie) &&
                isSpecificElementNotSelected(capacityId) &&
                isSpecificElementNotSelected(typeId) &&
                isSpecificElementNotSelected(hasClient) &&
                isNilOrEmpty(expirationDateStart) &&
                isNilOrEmpty(expirationDateEnd),
            then: Yup.number()
                .required(VALIDATION_MSG_REQUIRED)
                .typeError(VALIDATION_MSG_NUMBER)
                .integer(VALIDATION_MSG_INTEGER)
                .min(1, SELECTION_NEEDED)
        }),

    capacityId: Yup.number()
        .when(["serie", "brandId", "typeId", "hasClient", "expirationDateStart", "expirationDateEnd"], {
            is: (serie: string, brandId: number, typeId: number, hasClient: number, expirationDateStart: Date, expirationDateEnd: Date) =>
                isNilOrEmpty(serie) &&
                isSpecificElementNotSelected(brandId) &&
                isSpecificElementNotSelected(typeId) &&
                isSpecificElementNotSelected(hasClient) &&
                isNilOrEmpty(expirationDateStart) &&
                isNilOrEmpty(expirationDateEnd),
            then: Yup.number()
                .required(VALIDATION_MSG_REQUIRED)
                .typeError(VALIDATION_MSG_NUMBER)
                .integer(VALIDATION_MSG_INTEGER)
                .min(1, SELECTION_NEEDED)
        }),

    typeId: Yup.number()
        .when(["serie", "brandId", "capacityId", "hasClient", "expirationDateStart", "expirationDateEnd"], {
            is: (serie: string, brandId: number, capacityId: number, hasClient: number, expirationDateStart: Date, expirationDateEnd: Date) =>
                isNilOrEmpty(serie) &&
                isSpecificElementNotSelected(brandId) &&
                isSpecificElementNotSelected(capacityId) &&
                isSpecificElementNotSelected(hasClient) &&
                isNilOrEmpty(expirationDateStart) &&
                isNilOrEmpty(expirationDateEnd),
            then: Yup.number()
                .required(VALIDATION_MSG_REQUIRED)
                .typeError(VALIDATION_MSG_NUMBER)
                .integer(VALIDATION_MSG_INTEGER)
                .min(1, SELECTION_NEEDED)
        }),

    statusId: Yup.number()
        .typeError(VALIDATION_MSG_NUMBER)
        .required(VALIDATION_MSG_REQUIRED)
        .integer(VALIDATION_MSG_INTEGER)
        .positive(VALIDATION_MSG_POSITIVE),

    hasClient: Yup.number()
        .when(["serie", "brandId", "capacityId", "typeId", "expirationDateStart", "expirationDateEnd"], {
            is: (serie: string, brandId: number, capacityId: number, typeId: number, expirationDateStart: Date, expirationDateEnd: Date) =>
                isNilOrEmpty(serie) &&
                isSpecificElementNotSelected(brandId) &&
                isSpecificElementNotSelected(capacityId) &&
                isSpecificElementNotSelected(typeId) &&
                isNilOrEmpty(expirationDateStart) &&
                isNilOrEmpty(expirationDateEnd),
            then: Yup.number()
                .required(VALIDATION_MSG_REQUIRED)
                .typeError(VALIDATION_MSG_NUMBER)
                .min(1, SELECTION_NEEDED),
        }),


    expirationDateStart: Yup.date()
        .when(["serie", "brandId", "capacityId", "typeId", "hasClient", "expirationDateEnd"], {
            is: (serie: string, brandId: number, capacityId: number, typeId: number, hasClient: number, expirationDateEnd: Date) =>
                isNilOrEmpty(serie) &&
                isSpecificElementNotSelected(brandId) &&
                isSpecificElementNotSelected(capacityId) &&
                isSpecificElementNotSelected(typeId) &&
                isSpecificElementNotSelected(hasClient) &&
                isNilOrEmpty(expirationDateEnd),
            then: Yup.date()
                .required(VALIDATION_MSG_REQUIRED)
                .typeError(VALIDATION_MSG_DATE)
        }),


    expirationDateEnd: Yup.date()
        .when(["serie", "brandId", "capacityId", "typeId", "hasClient", "expirationDateStart"], {
            is: (serie: string, brandId: number, capacityId: number, typeId: number, hasClient: number, expirationDateStart: Date) =>
                isNilOrEmpty(serie) &&
                isSpecificElementNotSelected(brandId) &&
                isSpecificElementNotSelected(capacityId) &&
                isSpecificElementNotSelected(typeId) &&
                isSpecificElementNotSelected(hasClient) &&
                isNilOrEmpty(expirationDateStart),
            then: Yup.date()
                .required(VALIDATION_MSG_REQUIRED)
                .typeError(VALIDATION_MSG_DATE)
        }),

},
    [
        ["serie", "brandId"],
        ["serie", "capacityId"],
        ["serie", "typeId"],
        ["serie", "hasClient"],
        ["serie", "expirationDateStart"],
        ["serie", "expirationDateEnd"],

        ["brandId", "capacityId"],
        ["brandId", "typeId"],
        ["brandId", "hasClient"],
        ["brandId", "expirationDateStart"],
        ["brandId", "expirationDateEnd"],

        ["capacityId", "typeId"],
        ["capacityId", "hasClient"],
        ["capacityId", "expirationDateStart"],
        ["capacityId", "expirationDateEnd"],

        ["typeId", "hasClient"],
        ["typeId", "expirationDateStart"],
        ["typeId", "expirationDateEnd"],

        ["hasClient", "expirationDateStart"],
        ["hasClient", "expirationDateEnd"],

        ["expirationDateStart", "expirationDateEnd"],
    ]
);

export interface ISearchFormInitialValues {
    serie: string;
    brandId: number;
    capacityId: number;
    typeId: number;
    statusId: number;
    hasClient: number;
    expirationDateStart?: Date;
    expirationDateEnd?: Date;
}

export const formInitialValues: ISearchFormInitialValues = {
    serie: "",
    brandId: 0,
    capacityId: 0,
    typeId: 0,
    statusId: 1,
    hasClient: 0,
    expirationDateStart: undefined,
    expirationDateEnd: undefined
}