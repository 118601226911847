import {
  ExtinguisherServiceNeededAction,
  ExtinguisherServiceNeededActionType
} from "../actions/index.d";
import { ExtinguisherServiceNeeded } from "../../extinguisher";

export interface ExtinguisherServicesState {
  all: ExtinguisherServiceNeeded;
  month?: number;
  year?: number;
  loading: boolean;
  error?: backend.common.ErrorResponse;
  dispatch?: any;
}

export default (
  state: ExtinguisherServicesState,
  action: ExtinguisherServiceNeededAction
): ExtinguisherServicesState => {
  switch (action.type) {
    case ExtinguisherServiceNeededActionType.SEARCH_SUCCESS: {
      return {
        ...state,
        all: action.payload,
        loading: false,
        error: undefined
      };
    }

    case ExtinguisherServiceNeededActionType.SEARCH_INIT:
      return {
        ...state,
        month: parseInt(action.payload.month),
        year: parseInt(action.payload.year),
        loading: true,
        error: undefined
      };
    case ExtinguisherServiceNeededActionType.SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
};
