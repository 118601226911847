import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getTokenFromStorage } from "./domain/user/user.util";

//@ts-ignore
const SecureRoute = ({ component, ...rest }) => {
  return getTokenFromStorage() ? (
    <Route {...rest} component={component} />
  ) : (
    <Redirect to="/login" />
  );
};

export default SecureRoute;
