import { ExtinguisherBrandActionType, ExtinguisherBrandAction } from "./types";
import { doCall } from "../../../../utils/apiCall";

const extinguisherBrandLoadSuccess = (
  data: backend.extBrandNS.LoadAllResponseSuccess
): ExtinguisherBrandAction => ({
  type: ExtinguisherBrandActionType.LOAD_SUCCESS,
  payload: data
});
const extinguisherBrandLoadFailure = (): ExtinguisherBrandAction => ({
  type: ExtinguisherBrandActionType.LOAD_FAILURE
});
const extinguisherBrandLoadInit = (): ExtinguisherBrandAction => ({
  type: ExtinguisherBrandActionType.LOAD_INIT
});

export const loadExtinguisherBrands = () => (dispatch: React.Dispatch<any>) => {
  dispatch(extinguisherBrandLoadInit());
  return doCall({
    url: "/api/extinguisher/brand"
  })
    .then((data) => {
      dispatch(
        extinguisherBrandLoadSuccess(
          data as backend.extBrandNS.LoadAllResponseSuccess
        )
      );
    })
    .catch((_: backend.common.ErrorResponse) => {
      dispatch(extinguisherBrandLoadFailure());
    });
};
