export interface GetExtinguishersToAuditRequest {
  month: string;
}

export enum GetExtinguishersToAuditActionType {
  SEARCH_INIT = "@extinguisher-audit/SEARCH_INIT",
  SEARCH_SUCCESS = "@extinguisher-audit/SEARCH_SUCCESS",
  SEARCH_FAILURE = "@extinguisher-audit/SEARCH_FAILURE"
}
export type GetExtinguishersToAuditAction =
  | {
      type: GetExtinguishersToAuditActionType.SEARCH_INIT;
      payload: { month: number };
    }
  | {
      type: GetExtinguishersToAuditActionType.SEARCH_SUCCESS;
      payload: extinguisher.ClientToAuditUIModel[];
    }
  | {
      type: GetExtinguishersToAuditActionType.SEARCH_FAILURE;
      payload: backend.common.ErrorResponse;
    };
