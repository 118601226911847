export enum SellerActionType {
  LOAD_INIT = "@seller/LOAD_INIT",
  LOAD_SUCCESS = "@seller/LOAD_SUCCESS",
  LOAD_FAILURE = "@seller/LOAD_FAILURE"
}

export type SellerAction =
  | { type: SellerActionType.LOAD_INIT }
  | {
      type: SellerActionType.LOAD_SUCCESS;
      payload: seller.UIModel[];
    }
  | {
      type: SellerActionType.LOAD_FAILURE;
      payload: backend.common.ErrorResponse;
    };
