import {
  ExtinguisherCapacityAction,
  ExtinguisherCapacityActionType
} from "../actions/types";

export interface ExtinguisherCapacitiesState {
  all: Record<number, extinguisherCapacity.UIModel>;
  loading: boolean;
  dispatch?: any;
}

export default (
  state: ExtinguisherCapacitiesState,
  action: ExtinguisherCapacityAction
) => {
  switch (action.type) {
    case ExtinguisherCapacityActionType.LOAD_SUCCESS: {
      const resultsObject = action.payload.reduce(
        (acc: ExtinguisherCapacitiesState["all"], elem) => {
          acc[elem.id] = elem;
          return acc;
        },
        {}
      );
      return {
        ...state,
        all: resultsObject,
        loading: false
      };
    }

    case ExtinguisherCapacityActionType.LOAD_INIT:
      return {
        ...state,
        loading: true
      };
    case ExtinguisherCapacityActionType.LOAD_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return { ...state };
  }
};
