/** @jsx jsx */
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { css, jsx } from "@emotion/react";
import { Box } from "@mui/material";

import { ClientAddress } from "../client-search/components/client-address";
import { ExpandPanel } from "../../components/shared/expand-panel";
import { CardBoxContainer, GenericBoxItem } from "../../components/shared/card-box-container";

interface ClientExtinguishersServiceSearchResultsListProps {
  clients: backend.client.ExtinguisherClientServiceNeeded[];
}
export const ClientExtinguishersServiceSearchResultsList: FunctionComponent<ClientExtinguishersServiceSearchResultsListProps> = ({
  clients
}) => {
  return (
    <ExpandPanel title={"Listado"}>
      {clients.map((el, idx) => {
        return (
          <CardBoxContainer className="card" key={`client-${idx}`}
            css={css`
              @media print {
                & {
                  border: 0;
                  border-bottom: 1px solid;
                  margin-bottom: 0 !important;
                }
                & + div {
                  margin: 0
                }
              }
            `}>
            <Box css={css`
              @media print {
                display: grid;
                grid-template-columns: 11fr 1fr;
                font-size: .9rem;
                div {
                  margin: 0;
                }
                & > div:first-of-type {
                  display: grid;
                  grid-template-columns: 4fr 8fr;
                  padding: 0;
                }
                & > div:first-of-type *:first-of-type {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
                button {
                  display: none
                };
                div[data-name="address_container"] {
                  display: block !important;
                  padding: 0;
                };
                div[data-name="address_container"] > div {
                  display: inline;
                };
                div[data-name="address_container"] > div:not(:last-child):after {
                  content: ' - ';
                };
              }
            `}>
              <GenericBoxItem>
                <Link to={`/client/details/${el.id}`}>
                  [{el.id}] {el.name}
                </Link>
                <ClientAddress address={el.address} />
              </GenericBoxItem>
              <GenericBoxItem sx={{
                textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontSize: '2rem',
                display: "grid",
                gridTemplateColumns: '6fr 6fr'
              }}
                css={css`
              @media print {
                & {
                  padding: 0;
                  font-size: 1rem;
                  text-align: right;
                }
              }
              `}>
                <GenericBoxItem sx={{ padding: 0, margin: 0 }}>
                  <GenericBoxItem sx={{ padding: 0, margin: 0, fontSize: '.75rem' }}
                    css={css`
                  @media print {
                    font-size: .5rem;
                    display: none;
                  }
                  `}>
                    RECARGAS
                  </GenericBoxItem>
                  {el.extinguishersQty}
                </GenericBoxItem>
                <GenericBoxItem sx={{ padding: 0, margin: 0 }}>
                  <GenericBoxItem sx={{ padding: 0, margin: 0, fontSize: '.75rem' }}
                    css={css`
                  @media print {
                    font-size: .5rem;
                    display: none;
                  }
                  `}>
                    HIDRANTES
                  </GenericBoxItem>
                  {el.hydrantsQty ? el.hydrantsQty : "-"}
                </GenericBoxItem>
              </GenericBoxItem>
            </Box>
          </CardBoxContainer>
        );
      })}
    </ExpandPanel>
  );
};
