import React, { Fragment, useContext } from "react";
import { RouteComponentProps } from "react-router";

import moment from "moment";

import { Formik, Form } from "formik";
import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { AppContext } from "../../store";
import { months } from "../../utils/months";
import { PageTitle } from "../../components/shared/page-title";
import { FormFieldBoxItem, getErrorText, hasError } from "../../components/shared/form-utils";
import { ScrollToTop } from "../../components/shared/scroll-to-top";
import { GenericBoxItem } from "../../components/shared/card-box-container";

const TODAY = moment();
const CURRENT_MONTH = TODAY.get("month") + 1;
const CURRENT_YEAR = TODAY.get("year");
const years = [TODAY.get("year") - 1, CURRENT_YEAR, TODAY.get("year") + 1];

export const ClientExtinguishersServiceSearch = ({
  history
}: RouteComponentProps) => {
  const { extinguisherServices: extinguisherServicesState } = useContext(
    AppContext
  );
  const formInitialValues = {
    month: extinguisherServicesState.month || CURRENT_MONTH,
    year: extinguisherServicesState.year || CURRENT_YEAR
  };
  return (
    <Fragment>
      <PageTitle title={`Recargas`}></PageTitle>

      <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>
        <GenericBoxItem>
          <h3>Vencimiento Recargas/Hidrantes</h3>
        </GenericBoxItem>

        <GenericBoxItem>
          <Formik
            initialValues={formInitialValues}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              history.push(
                `/client/extinguishers/service/${values.month}/${values.year}`
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              resetForm
              /* and other goodies */
            }) => {
              return (
                <Form>
                  <FormFieldBoxItem>
                    <TextField
                      name="month"
                      select
                      label="Mes"
                      error={hasError(touched, errors, "month")}
                      helperText={getErrorText(errors, "month")}
                      onChange={handleChange("month")}
                      value={values?.month}
                    >
                      {months.map((month, idx) => (
                        <MenuItem key={`month_${idx + 1}`} value={idx + 1}>
                          {month}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormFieldBoxItem>

                  <FormFieldBoxItem>
                    <TextField
                      name="year"
                      select
                      label="A&ntilde;o"
                      error={hasError(touched, errors, "year")}
                      helperText={getErrorText(errors, "year")}
                      onChange={handleChange("year")}
                      value={values?.year}
                    >
                      {years.map((year) => (
                        <MenuItem key={`year_${year}`} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormFieldBoxItem>

                  <FormFieldBoxItem>
                    <Stack direction="row" spacing={2}>
                      <LoadingButton variant="contained"
                        loading={isSubmitting}
                        type="submit"
                      >
                        Buscar
                      </LoadingButton>
                      <Button variant="contained" color="secondary"
                        onClick={() => resetForm(formInitialValues)}
                        type="button"
                      >Reset</Button>
                    </Stack>
                  </FormFieldBoxItem>
                </Form>
              );
            }}
          </Formik>
        </GenericBoxItem>

      </Box>

      <ScrollToTop />
    </Fragment>
  );
};