import { UnlinkExtinguisherRequest, LinkExtinguishersListRequest } from ".";
import { doCall } from "../../../utils/apiCall";
import { ClientActionType } from "./types";

const unlink = (request: UnlinkExtinguisherRequest) => ({
    type: ClientActionType.UNLINK_EXTINGUISHER,
    payload: request
});

const linkExtinguishersListInit = () => ({
    type: ClientActionType.LINK_EXTINGUISHERS_LIST_INIT
})
const linkExtinguishersListSuccess = () => ({
    type: ClientActionType.LINK_EXTINGUISHERS_LIST_SUCCESS
})
const linkExtinguishersListFailure = (error: backend.common.ErrorResponse) => ({
    type: ClientActionType.LINK_EXTINGUISHERS_LIST_FAILURE,
    payload: error
})

const unlinkExtinguisherInit = () => ({
    type: ClientActionType.UNLINK_EXTINGUISHER_INIT
})
const unlinkExtinguisherSuccess = () => ({
    type: ClientActionType.UNLINK_EXTINGUISHER_SUCCESS
})
const unlinkExtinguisherFailure = (error: backend.common.ErrorResponse) => ({
    type: ClientActionType.UNLINK_EXTINGUISHER_FAILURE,
    payload: error
})
export const unlinkExtinguisher = (request: UnlinkExtinguisherRequest) => (
    dispatch: React.Dispatch<any>
) => {
    dispatch(unlinkExtinguisherInit());
    return doCall({
        method: "PATCH",
        url: `/api/extinguisher/${request.serialNumber}`,
        payload: {
            operation: 'unlink'
        }
    })
        .then(_ => {
            dispatch(unlinkExtinguisherSuccess());
            dispatch(unlink(request));
            return Promise.resolve()
        })
        .catch((error: backend.common.ErrorResponse) => {
            dispatch(unlinkExtinguisherFailure(error));
            return Promise.reject(error)
        })
};

export const linkExtinguishersList = (request: LinkExtinguishersListRequest) => (
    dispatch: React.Dispatch<any>): Promise<void | backend.common.ErrorResponse> => {
    dispatch(linkExtinguishersListInit());

    return doCall({
        method: "PATCH",
        url: '/api/extinguisher',
        payload: {
            ...request,
            operation: 'link'
        }
    })
        .then(_ => {
            dispatch(linkExtinguishersListSuccess());
            return Promise.resolve()
        })
        .catch((error: backend.common.ErrorResponse) => {
            dispatch(linkExtinguishersListFailure(error));
            return Promise.reject(error)
        })
}