import { Alert } from "@mui/material";
import React from "react";
import { ExpandPanel } from "../../components/shared/expand-panel";
import { formatDate } from "../../utils/format";

interface ClientHydrantsType {
    quantity?: number;
    expirationDate?: string;
}
export const ClientHydrants: React.FC<ClientHydrantsType> = ({ quantity, expirationDate }) => {
    return (
        <ExpandPanel title={"Hidrantes"}>
            {!expirationDate && <Alert severity="info">No hay hidrantes asociados!</Alert>}
            {expirationDate && (
                <>
                    <div>Cantidad Hidrantes: {quantity}</div>
                    <div>Fecha Vencimiento: {formatDate(expirationDate)}</div>
                </>
            )}
        </ExpandPanel>
    )
}