import { ClientActionType, ClientAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import { DoQuoteActionRequest, DoQuoteActionResponse } from "./index.d";

const clientQuoteSuccess = (
  data: backend.quote.QuoteResponseSuccess
): ClientAction => ({
  type: ClientActionType.DO_QUOTE_SUCCESS,
  payload: data
});
const clientQuoteFailure = (
  _: backend.quote.QuoteResponseError
): ClientAction => ({
  type: ClientActionType.DO_QUOTE_FAILURE,
  payload: { message: "DO_QUOTE_FAILURE", code: "TO_BE_REPLACED" }
});
const clientQuoteInit = (): ClientAction => ({
  type: ClientActionType.DO_QUOTE_INIT
});

export const doQuote = ({ clientId, quoteDetails }: DoQuoteActionRequest) => (
  dispatch: React.Dispatch<any>
): DoQuoteActionResponse => {
  dispatch(clientQuoteInit());
  return doCall({
    url: `/api/client/${clientId}/quote`,
    method: "POST",
    payload: {
      ...removeAllEmptyFields(quoteDetails),
      products: quoteDetails.products.map(prod => ({
        ...prod,
        prodTotal: parseFloat(String(prod.prodTotal))
      })),
    }
  })
    .then((response) =>
      dispatch(
        clientQuoteSuccess(response as backend.quote.QuoteResponseSuccess)
      )
    )
    .catch((_: backend.quote.QuoteResponseError) => {
      dispatch(clientQuoteFailure(_));
      return Promise.reject();
    });
};

const removeAllEmptyFields = (request: object) => Object.fromEntries(Object.entries(request).filter(([_, v]) => String(v).length > 0))