import React, { useContext, useState, useEffect, Fragment } from "react";
import classnames from "classnames";
import styled from "@emotion/styled";
import * as Yup from "yup";

import { AppContext } from "../../../store";
import { sendQuoteByEmail } from "../../../domain/client/actions/sendQuoteByEmail";
import Loader from "../../../components/shared/loader";
import { Formik, Form, Field } from "formik";
import { ErrorNotification } from "../../../components/shared/error";
import { downloadQuote } from "../../../domain/client/actions/downloadQuote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Stack, StandardTextFieldProps, TextField } from "@mui/material";
import { FIELD_LENGTH_EMAIL } from "../../../utils/form.utils";
import { FormFieldBoxItem, getErrorText, hasError } from "../../../components/shared/form-utils";
import { LoadingButton } from "@mui/lab";

interface SendQuoteType {
  clientId: number;
  filename: string;
  clientEmail?: string;
}

export const SendQuoteForm = ({
  clientId,
  clientEmail,
  filename
}: SendQuoteType) => {
  const { client: clientState } = useContext(AppContext);

  const formValidationSchema = Yup.object().shape({
    email: Yup.string()
      .max(100, "Taaaaanto???? Usa menos de 100 caracteres!")
      .typeError("Formato invalido!")
      .required("Ingresa el email!"),
    message: Yup.string()
  });
  const formInitialValues = {
    email: typeof clientEmail === "undefined" ? "" : clientEmail,
    message: ""
  };
  return (
    <Fragment>
      {/* <div className="card">
        <div className="card-body">
          <button
            type="button"
            className="btn btn-link"
            onClick={() =>
              downloadQuote({
                clientId: clientId,
                filename: clientState.quote?.filename as string
              })(clientState.dispatch)
            }
          >
            <FontAwesomeIcon icon={"file-pdf"} /> Ver Presupuesto
          </button>
        </div>
      </div> */}

      <Fragment>
        <div className="card">
          <div className="card-body">
            <h5>Enviar Presupuesto</h5>
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                sendQuoteByEmail({
                  clientId,
                  filename,
                  email: values.email,
                  message: values.message
                })(clientState.dispatch)
                  .finally(() => setSubmitting(false));
              }}
              render={({
                values,
                resetForm,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleBlur,
              }) => {
                const formikProps = (name: string): StandardTextFieldProps => ({
                  variant: "standard",
                  name,
                  //@ts-ignore
                  value: values[name],
                  error: hasError(touched, errors, name),
                  helperText: getErrorText(errors, name),
                  onChange: handleChange(name),
                  onBlur: handleBlur(name),
                  autoComplete: "off"
                });
                return (
                  <Fragment>
                    {clientState.quote?.emailing && <Loader />}
                    {clientState.quote?.emailingError &&
                      <ErrorNotification error={clientState.quote.emailingError} />}

                    <Form>
                      <FormFieldBoxItem>
                        <TextField
                          type="email"
                          inputProps={{ maxLength: FIELD_LENGTH_EMAIL }}
                          placeholder="Ingrese email"
                          label="Email"
                          {...formikProps("email")}
                          autoFocus
                        />
                      </FormFieldBoxItem>

                      <FormFieldBoxItem>
                        <TextField
                          type="text"
                          multiline
                          rows={5}
                          placeholder="Ingrese mensaje"
                          label="Mensaje"
                          {...formikProps("message")}
                        />
                      </FormFieldBoxItem>

                      <FormFieldBoxItem>
                        <Stack direction="row" spacing={2}>
                          <LoadingButton variant="contained"
                            loading={isSubmitting}
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Enviar
                          </LoadingButton>
                          <Button variant="contained" color="secondary"
                            onClick={() => resetForm(formInitialValues)}
                            type="button"
                          >Reset</Button>
                        </Stack>
                      </FormFieldBoxItem>

                    </Form>
                  </Fragment>
                );
              }}
            ></Formik>
          </div>
        </div>
      </Fragment>

      {clientState.quote?.emailed && (
        <div className="alert alert-success" role="alert">
          El email ha sido enviado!
        </div>
      )}
    </Fragment>
  );
};