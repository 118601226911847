import { ClientActionType, ClientAction } from "./types";
import { doCall } from "../../../utils/apiCall";
import {
  sendQuoteByEmailActionRequest,
  sendQuoteByEmailActionResponse
} from "./index.d";

export const sendQuoteByEmailClear = (): ClientAction => ({
  type: ClientActionType.SEND_QUOTE_EMAIL_RESET
});
const sendQuoteByEmailSuccess = (): ClientAction => ({
  type: ClientActionType.SEND_QUOTE_EMAIL_SUCCESS
});
const sendQuoteByEmailFailure = (
  error: backend.quote.QuoteResponseError
): ClientAction => ({
  type: ClientActionType.SEND_QUOTE_EMAIL_FAILURE,
  payload: error
});
const sendQuoteByEmailInit = (): ClientAction => ({
  type: ClientActionType.SEND_QUOTE_EMAIL_INIT
});

export const sendQuoteByEmail = ({
  clientId,
  email,
  filename,
  message
}: sendQuoteByEmailActionRequest) => (
  dispatch: React.Dispatch<any>
): sendQuoteByEmailActionResponse => {
  dispatch(sendQuoteByEmailInit());
  return doCall({
    url: `/api/client/${clientId}/quote/email`,
    method: "POST",
    payload: { email, filename, message }
  })
    .then((_) => dispatch(sendQuoteByEmailSuccess()))
    .catch((error: backend.quote.QuoteResponseError) => {
      dispatch(sendQuoteByEmailFailure(error));
      return Promise.reject();
    });
};
