import React, { useContext } from "react";

import { AppContext } from "../../../../store";
import { linkFormSchema, formInitialValues } from "./form-schema";

import { Formik, Form, Field } from "formik";
import { Button, Stack, TextField } from "@mui/material";

import { search } from "../../../../domain/extinguisher/actions/search";
import { FormFieldBoxItem, getErrorText, hasError } from "../../../../components/shared/form-utils";
import { FIELD_LENGTH_SERIE, restrictNumberOnly } from "../../../../utils/form.utils";
import { LoadingButton } from "@mui/lab";

interface IProps {
    onSuccess: Function;
    onError: Function;
    onReset: Function;
}
export const SearchExtinguisherBySerieForm = (props: IProps) => {
    const {
        extinguisher: extinguisherState
    } = useContext(AppContext);
    const { onSuccess, onError, onReset } = props;

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={linkFormSchema}
            // enableReinitialize={true}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                //@ts-ignore
                search(values)(extinguisherState.dispatch)
                    .then(response => {
                        if (response.length === 1) {
                            resetForm();
                            return onSuccess(response[0])
                        }
                        return onError('NOT_FOUND')
                    })
                    .finally(() => setSubmitting(false));
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
                setFieldValue,
                setFieldTouched,
                /* and other goodies */
            }) => {
                console.log("gon, form error", { errors }, { values });

                return (
                    <Form>
                        <Field name="hasClient" type="hidden" value={0} />
                        <FormFieldBoxItem>
                            <TextField
                                type="tel"
                                name="serie"
                                inputProps={{ maxLength: FIELD_LENGTH_SERIE }}
                                placeholder="Ingrese numero de serie"
                                autoFocus
                                autoComplete="off"
                                variant="standard"
                                error={hasError(touched, errors, "serie")}
                                helperText={getErrorText(errors, "serie")}
                                value={values?.serie}
                                onChange={handleChange("serie")}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') return handleSubmit();
                                    restrictNumberOnly(e);
                                }}
                            />
                        </FormFieldBoxItem>

                        <FormFieldBoxItem>
                            <Stack direction="row" spacing={2}>
                                <LoadingButton variant="contained"
                                    loading={isSubmitting}
                                    disabled={Object.keys(errors).length > 0}
                                    type="submit">Buscar</LoadingButton>
                                <Button variant="contained" color="secondary"
                                    onClick={() => { resetForm(formInitialValues); onReset() }}
                                    type="button"
                                >Reset</Button>
                            </Stack>
                        </FormFieldBoxItem>
                    </Form>
                );
            }}
        </Formik >
    );
}